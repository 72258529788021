<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="选择品牌" prop="brandId">
        <Select v-model="formItems.brandId" >
          <Option v-for="item in brands" :value="item.id" :key="item.brandName">{{ '品牌名:'+item.brandName + '- 型号:' + item.brandModel }}</Option>
        </Select>
      </FormItem>
      <FormItem label="终端号(IMEI)" prop="imei">
        <Input type="textarea" v-model="formItems.imei" placeholder="输入终端号(IMEI),多个换行"  rows="22"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
export default {
  components: {
    FNCModal,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: null,
        brandId:"",
        clientId:"",
      }),
      formItems: {},
      ruleItems: {
        brandId: [
          { required: true,type:"number", message: '品牌不能为空', trigger: 'change' }
        ],
        imei: [
          { required: true, message: '终端号(IMEI)不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      brands:[],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    async loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartsmoke/devicestocks/queryDevicestocksDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.formItems.clientId = this.$core.getPortalConfig("clientId")
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$get({
        url: "/smartsmoke/brandinfo/queryBrandinfo", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.brands = res.dataList
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.formItems.clientId = this.$core.getPortalConfig("client_id")
          this.$post({
            url: "/smartsmoke/devicestocks/addDevice",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    
  }
};
</script>
    
<style scoped lang='less'></style>