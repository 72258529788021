import axios from "axios";
import qs from "qs";
import proxy from "@/api/proxy.js";
import core from "@/utils/core.js";
// 创建取消请求
const CancelToken = axios.CancelToken
const source  = CancelToken.source()
var cancelList = []
var removePending = () =>{
    if(cancelList && cancelList.length){
        cancelList.map(item=>{
            if(item.source){
                item.source.cancel()
            }
        })
    }
}
export function getProxy(target){
    let url=proxy[target]
    if(url) url=url.target
    return url;
}
export async function get(option) {
    if(!option.header) option.header={"Content-Type":"application/json"}
    if(!option.header["Content-Type"]){
        option.header["Content-Type"]= "application/json";
    }
    var config={
            method: "get",
            url: option.url,
            headers: option.header,
            params: option.data,
        };
        return await request(config,option.success,option.complete,option.fail);

}
export async function post(option) {
    if(!option.header) option.header={"Content-Type":"application/x-www-form-urlencoded"}
    if(!option.header["Content-Type"]){
        option.header["Content-Type"]= "application/x-www-form-urlencoded";
    }
    var config={
            method: "post",
            url: option.url,
            headers: option.header,
            data: option.header['Content-Type'] == 'application/x-www-form-urlencoded' ? qs.stringify(option.data) : option.data,
        };
    return await request(config,option.success,option.complete,option.fail);
}

function request(config,success,complete,fail) {
    return new Promise((resolve) => {
        axios(config).then(
            (response) => {
                //success处理
                if(success) success(response.data);
                //complete处理
                if(complete) complete();
                resolve(response.data);
            },
            (err) => {
                let msg={code:"500",desc:"读取接口出错："+err.message}
                //fail处理
                if(fail) fail(msg);
                //complete处理
                if(complete) complete();
                resolve(msg)
            }
        );
    });
}

// axios默认配置
//axios.defaults.timeout = 100000; // 超时时间

axios.interceptors.request.use(
     (config) => {
        if(process.env.VUE_APP_FLAG!="test"){
            var target=config.url.substring(0,config.url.indexOf("/",1));
            if(target.indexOf("/")==0&&target!=null&&target!=""){
                var domain=proxy[target];
                domain=domain!=null?domain.target:null;
                if(domain!=null) config.url=domain+config.url.substring(config.url.indexOf("/",1));
            }
        }
        if(config.headers.other=="noSend"){
            delete config.headers.other;
            delete config.headers.Authorization;
            delete config.headers.branchId;
            delete config.headers.functionId;
        }else{
            let token=core.getTokenInfo();
            if (token.accessToken!=null) {
                config.headers.Authorization ="Bearer " + token.accessToken;
            }
            let branch=core.getBranchInfo();
            if(branch.id){
                config.headers.branchId =branch.id;
            }
            let functionId=core.getFunctionId();
            if(functionId){
                config.headers.functionId =functionId;
            }
        }
        if(config.method=="get") config.data=true;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(response =>{
    // 拦截响应，统一处理
    try{
        if(response && response.data && response.data.code){
            switch(response.data.code){
                // 如果是登录过期或者是被人踢下线
                case 401:
                case "401":
                    // 先中断除了获取验证码的请求
                    removePending()
                    // cancelList = []
                    var loginInfo={login:false,right:false,msg:response.data.desc||"登录过期，请重新登录！",user:{}};
                    top.vue.portalCheckRight(loginInfo);
                break;
                //接口无权限访问
                case 403:
                case "403":
                    // 先中断除了获取验证码的请求
                    removePending()
                    // cancelList = []
                    var loginInfo={login:true,right:false,msg:response.data.desc||"你没有权限访问该模块功能！",user:{}};
                    top.vue.portalCheckRight(loginInfo);
                break;
            }
            return response        
        }else{
            return response
        }
    }catch(err){
        return Promise.reject(err)
    }
    
},err=>{
      if(err.message=="Request failed with status code 401"){
        var loginInfo={login:false,right:false,msg:"未登录或登录过期，请重新登录！",user:{}};
        top.vue.portalCheckRight(loginInfo);
      }else if(err.message=="Request failed with status code 403"){
        var loginInfo={login:false,right:false,msg:"你没有权限访问该模块功能！",user:{}};
        top.vue.portalCheckRight(loginInfo);
      }
   
})
