<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <div style="width:400px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入标题关键字" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="() => { editData() }">
        <Icon type="md-add-circle" size="18" />创建一个邀约
      </Button>
      <Button type="success" class="passedDiv" @click="() => { passedData() }">
        <Icon type="ios-eye-off" size="18" />启禁用
      </Button>
      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }">
        <Icon type="md-trash" size="18" />删除
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" :fullscreen="true">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />关闭
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
      <FNCModal ref="shareModal" title="分享码" :fullscreen="false" width="470px">
        <template v-slot:contentarea>
          <div style="display:flex;width:100%;">
          <div class="qrcode" id="qrcode"></div>
          <div style="margin-left:50px;"><img :src="shareUrl" style="width:200px;height:200px;" @error="errorImg"/></div>
        </div>
        <div style="display:flex;width:100%;margin:20px 0;font-size: 16px;">
        <div  style="width:200px;text-align: center;">H5二维码</div>
        <div  style="width:200px;margin-left:50px;text-align: center;">小程序码</div>
        </div>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import QRCode from "qrcodejs2"
import html2canvas from "html2canvas"
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "邀约列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: "left",
          align: 'center'
        },
        {
          title: "标题",
          key: "title",
          align: "left",
          minWidth: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    color: "#2b85e4",
                  },
                  domProps: {
                    title: params.row.title,
                  },
                  on: {
                    click: (e) => {
                      this.editData(params.row.id);
                      e.stopPropagation();
                    },
                  },
                },
                params.row.title
              ),
            ]);
          },
        },
        {
          title: "开始时间",
          key: "startTime",
          align: "center",
          width: 170,
        },
        {
          title: "结束时间",
          key: "endTime",
          align: "center",
          width: 170,
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          width: 170,
        },
        {
          title: "启禁用",
          key: "idisplay",
          align: "center",
          width: 80,
          render: (h, params) => {
            return h("div",
              {
                class: "passedDiv"
              },
              [
                h(
                  "i-switch",
                  {
                    props: {
                      value: params.row.idisplay == 1 ? true : false,
                      "before-change": () => {
                        return new Promise((resolve) => {
                          this.passedData(params.row, resolve);
                        });
                      }
                    },
                    on: {
                      "on-change": (e) => {
                        this.tableData[params.index].idisplay = e ? 1 : 0;
                      }
                    },
                  }, [
                  h('span', { slot: 'open' }, '启'),
                  h('span', { slot: 'close' }, '禁'),
                ]
                ),

              ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "a",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 2px",
                    },

                    on: {
                      click: () => {
                        this.copyData(params.row.id);
                      },
                    },
                  },
                  "复制并创建"
                ),
                h(
                  "span",
                  {
                    style: {
                      margin: "0 2px",
                      color:"#bbb"
                    },
                  },
                  "|"
                ),
                h(
                  "a",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 2px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id);
                      },
                    },
                  },
                  "修改"
                ),
                h(
                  "span",
                  {
                    style: {
                      margin: "0 2px",
                      color:"#bbb"
                    },
                  },
                  "|"
                ),
                h(
                  "a",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    }, style: {
                      margin: "0 2px",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
                h(
                  "span",
                  {
                    style: {
                      margin: "0 2px",
                      color:"#bbb"
                    },
                  },
                  "|"
                ),
                h(
                  "a",
                  {
                    class: "viewDiv"
                    ,
                    props: {
                      type: "info",
                      size: "small",
                    }, style: {
                      margin: "0 2px",
                    },
                    on: {
                      click: () => {
                        this.shareData(params.row);
                      },
                    },
                  },
                  "分享码"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      shareUrl:"",
    };
  },
  created() {

  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    async loadInfo() {
      //读取数据列表
      this.loadData(1);
      this.$core.checkControlButtonRight(
        [{ btn: this.$(".editDiv"), right: "E" },
        { btn: this.$(".deleteDiv"), right: "D" },
        { btn: this.$(".addDiv"), right: "A" },
        { btn: this.$(".passedDiv"), right: "P" }
        ]
      ).then(res => {
        this.right = res;
      });
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/yunyaoyue/api/custom/list",
        data: {
          functionId: this.$core.getFunctionId(),
          ...this.pageParams
        },
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = res.pageParams
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    passedData(row, resolve) {
      if (this.right["P"] == false) {
        this.$Message.error({ background: true, content: "你没有启禁用权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }
      this.$Modal.confirm({
        title: '启禁确认',
        content: row ? '您确认要<span style="color:red"> ' + (row.idisplay == 1 ? '禁用' : '启用') + " </span>“" + row.title + '”吗？' : "确认要禁用/启用选中吗？",
        onOk: () => {
          this.$post({
            url: "/yunyaoyue/api/custom/passed", data: { ids: ids },
            success: (res) => {
              if (res.code == "200") {
                if (resolve) resolve();
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.id
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.id;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择审核项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.title + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/yunyaoyue/api/custom/delete", data: { ids: ids },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    copyData(id){
      this.$Modal.confirm({
        title: '复制确认',
        content: '复制并自动生成一份函，您确认要复制吗？',
        onOk: () => {
          this.loading = true;
          this.$post({
            url: "/yunyaoyue/api/custom/copydata", data: { id: id },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: "已生成一份函，在列表第一行" })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            },complete:()=>{
              this.loading = false;
            }
          });
        }
      });
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload,close) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
        }
        if (close == true) {
          this.closeModal();
        }
      });
    },
    shareData(row) {
      this.$refs.shareModal.showModal();
      this.shareUrl="./images/nopic.png";
      this.$get({
        url:"/gateway/api/weixinservice/getUnlimitedQRCode",
        data:{
          clientId:"yuyaoyue",
          url:"apps/yunyaoyue/pages/list/invite",
          scene:"b="+row.branchId+"&i="+row.id,
        },success:(res)=>{
            if(res.code=="200"&&res.data){
              this.shareUrl=res.data;
              console.log(this.shareUrl);
            }
          }
      })
      this.$("#qrcode").html("");
        new QRCode("qrcode", {
          text: this.$getProxy("/custom")+"/#/apps/yunyaoyue/pages/list/invite?b="+row.branchId+"&i="+row.id,
          width: 200,
          height: 200,
        })
        //  setTimeout(this.sendBase64Poster, 1000)
    }, // 生成base64的完整海报
    async sendBase64Poster() {
      await html2canvas(this.$refs.shareImage.$el, {
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        this.posterUrl = canvas.toDataURL("image/png", 1.0)
      })
    },
    errorImg(e){
      e.target.src='./images/nopic.png'
    }
  }
};
</script>
    
<style scoped lang='less'>
.qrcode{
    width:200px;
    height:200px;
    margin:0px;
    overflow: hidden;
    background-color: #f5f5f5;
    canvas,img{
        width:100%;
    }
}
</style>