 <template>
  <!-- 样式组件 -->
  <div
    :class="
      'fnc-info-view'
    "
    v-if="!isParamComp"
  >
  <img src="/images/information.png"/>
  </div>
  <!-- 参数设置组件 -->
  <div class="fnc-info-params" v-else>
    <Form :label-width="120" ref="infoForm">
       <FormItem label="分页显示">
        <RadioGroup v-model="data.isPage">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="每页显示条目">
        <Input
          type="Number"
          v-model.number="data.pageSize"
          placeholder="默认：10"
        ></Input>
      </FormItem>
      <FormItem label="搜索栏搜索">
        <RadioGroup v-model="data.isSearch">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="绑定搜索栏名称" v-if="data.isSearch">
        <Input
          placeholder="注：从对应的搜索栏组件设置中找到"
          v-model.trim="data.searchName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isSearch">
        <Input
          placeholder="注：API中参与搜索栏搜索的字段名"
          v-model.trim="data.searchKeyword"
        ></Input>
      </FormItem>
      <FormItem label="标签栏搜索">
        <RadioGroup v-model="data.isLFTabs">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="绑定标签栏名称" v-if="data.isLFTabs">
        <Input
          placeholder="注：从对应的标签栏组件设置中找到"
          v-model.trim="data.LFTabsName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isLFTabs">
        <Input
          placeholder="注：API中参与标签栏搜索的字段名"
          v-model.trim="data.lftabsKeyword"
        ></Input>
      </FormItem>
      <FormItem label="API地址">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
     <FormItem label="响应参数互换" v-if="data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
         placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
           <FormItem label="JSON数据" v-else>
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="15"
          placeholder='例：[
                {
                 type:text纯文本，image单图片，pictures多图片，video视频，auto或null 自动检测类型
                 images:图片地址，数组，当type=auto时，images为null或[]是纯文本模式，images.length<3是单图片，images.length>=3是多图片模式
                 video:视频地址，数组，当type=auto时，优先于images
                 title:标题
                 from:来源
                 like:点赞数/阅读数
                 desc:其他自定义
                 time:时间
                 url:跳转地址
                }
              ]'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button
          style="margin: 5px"
          type="info"
          @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import Information from "./components/Information";
 * 资讯组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "Information",
        api: "",
        data: null,
        isPage:false,//是否分页显示
        pageSize: 10, //每页显示条目
        isSearch: false,  //是否需要搜素栏搜索
        searchKeyword: '',  // 搜素栏关键字
        isLFTabs: false,    // 是否需要标签页搜索
        lftabsKeyWord: '',  // 标签页关键字
        LFTabsName:"",
        searchName:"",
      },
      dfData: JSON.stringify(
        [
       {
          type:"auto",//text纯文本，image单图片，pictures多图片，video视频，auto或null 自动检测类型
          images:[],//图片地址，数组，当type=auto时，images为null或[]是纯文本模式，images.length<3是单图片，images.length>=3是多图片模式
          video:[],//视频地址，数组，当type=auto时，优先于images
          title:"",//标题
          from:"",//来源于哪个栏目
          like:"",//点赞
          desc:"",//自定义
          time:1,//时间
          url:"",//跳转地址
       }
        ],
        null,
        "\t"
      ),
    };
  },
  mounted() {
    this.handleData();
  },
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    handleData() {
      
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.fnc-info-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img{
    width: 100%;
  }
}
</style>