<template>
 <Card :bordered="false">
            <template #title>水位平均值：{{ waterLevelAvg }}cm</template>
            <template #extra>
              <DatePicker type="date" placeholder="选择监测日期" style="width: 300px;top:-8px;position: relative;" @on-change="onChangeStatisticsDate"/>
        </template>
        <template><div id="eChartsZoom" style="width:100%;height:450px;"></div></template>  
        </Card>
</template>

<script>
import * as echarts from 'echarts';
export default {
  components: {
  },
  data() {
    return {
      id: 0,
      data: [],
      statisticsDate: "",
      waterLevelAvg: "-",
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    loadData(id, statisticsDate) {
      this.id=id;
      if (id) {
        this.$get({
          url: "/smartcovers/object/queryObjectCover",
          data: { id: id, statisticsDate: statisticsDate },
          success: (res) => {
            this.tableData = []
            if (res.code == "200" && res.dataList) {
              this.data = res.dataList[0]

              setTimeout(() => {
              var xAxis = [];
              var data = [];
              var waterLevelAvg = 0;
              var waterLevelCount = 0;
              this.data.coverStatistics && this.data.coverStatistics.map(ss => {
                xAxis.push(ss.coverName)
                var color = "#0D94AA"
                var width = 4
                if (ss.waterLevel >= (ss.depth || 200) - 30) {
                  color = "#EA1A1A"
                  width = 10
                }
                if (ss.waterLevel) {
                  waterLevelAvg += Number(ss.waterLevel)
                  waterLevelCount++
                }
                data.push({ value: ss.waterLevel, itemStyle: { borderWidth: width, borderColor: color, color: color } })
              })
              var avg = Math.ceil(waterLevelAvg/waterLevelCount);
              if(!isNaN(avg)) {
                this.waterLevelAvg=avg
              } else {
                this.waterLevelAvg='-'
              }
              
              this.createChart('eChartsZoom', { xAxis: xAxis, data: data })
            }, 1000);
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
    },
    submitData(callback) {
    },
    
    classModal() {
    },
    
    onChangeStatisticsDate(e) {
      this.loadData(this.id, e)
    },
    createChart(id, data) {
      let ec = echarts.init(document.getElementById(id))
      let option = {
        xAxis: {
          type: 'category',
          axisLabel: {
            show: true,
            interval: 0,
            rotate: -0,
          },
          data: data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: "inside",
            show: true,
            realtime: true,
            start: 0,
            end: parseInt(8 * 100 / data.data.length),
            xAxisIndex: [0],
            filterMode: "none",
          }, {
            type: "slider",
            brushSelect: false,
          }],
        series: [
          {
            symbol: '',
            sampling: 'lttb',
            itemStyle: {
              color: '#0D94AA'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00EAD5'
                },
                {
                  offset: 1,
                  color: '#0D94AA'
                }
              ])
            },
            data: data.data,
            smooth: true,
            type: 'line'
          }
        ]
      };
      ec.setOption(option);
    },
  }
};
</script>
    
   
<style scoped lang='less'>

/deep/.ivu-card-head {
    border-bottom: 1px solid #e8eaec;
    background-color: #17233d;
    color:#ffffff;
    padding: 14px 16px;
    line-height: 1;
}
</style>