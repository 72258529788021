<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="管线名称" prop="objectName">
        <Input type="text" v-model="formItems.objectName" placeholder="输入管线名称"></Input>
      </FormItem>
      <FormItem label="省/市/区" prop="county">
        <!-- <Select v-model="formItems.county" style="width: 180px">
          <Option v-for="item in projects" :value="item.objectNum" :key="item.objectName">{{ item.objectName }}</Option>
        </Select> -->
        <Cascader :data="areaList" v-model="area" @on-change="onAreaChange" v-width="200" />
      </FormItem>
      <FormItem label="管线地址" prop="address">
        <Input type="textarea" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="投资金额" prop="amount">
        <Input type="text" v-model="formItems.amount" placeholder="输入投资金额"></Input>
      </FormItem>
      <FormItem label="签约金额" prop="signAmount">
        <Input type="text" v-model="formItems.signAmount" placeholder="输入签约金额"></Input>
      </FormItem>
      <FormItem label="已收金额" prop="receivedAmount">
        <Input type="text" v-model="formItems.receivedAmount" placeholder="输入已收金额"></Input>
      </FormItem>
      <FormItem label="商户名称" prop="merchantName">
        <Input type="text" v-model="formItems.merchantName" placeholder="输入商户名称"></Input>
      </FormItem>
      <FormItem label="联系人名称" prop="linkman">
        <Input type="text" v-model="formItems.linkman" placeholder="输入联系人名称"></Input>
      </FormItem>
      <FormItem label="联系人电话" prop="linkmanPhone">
        <Input type="text" v-model="formItems.linkmanPhone" placeholder="输入联系人电话"></Input>
      </FormItem>
      <FormItem label="业务对接人" prop="businessMan">
        <Input type="text" v-model="formItems.businessMan" placeholder="输入业务对接人"></Input>
      </FormItem>
      <FormItem label="业务对接人电话" prop="businessManPhone">
        <Input type="text" v-model="formItems.businessManPhone" placeholder="输入业务对接人电话"></Input>
      </FormItem>

      <FormItem label="签约日期" prop="signTime">
        <DatePicker type="date" v-model="formItems.signTime"  @on-change="onChangesignTime" placeholder="选择签约日期"/>
      </FormItem>
      <FormItem label="动工日期" prop="startTime">
        <DatePicker type="date" v-model="formItems.startTime" @on-change="onChangeStarttime" placeholder="选择动工日期"/>
      </FormItem>

      <FormItem label="管线预计时间" prop="expectTime">
        <Input type="text" v-model="formItems.expectTime" placeholder="预计时间：1个月、1年"></Input>
      </FormItem>

      <FormItem label="管线长度" prop="lengths">
        <Input type="text" v-model="formItems.lengths" placeholder="输入管线长度"></Input>
      </FormItem>
      <FormItem label="总监控井盖数量" prop="coverNum">
        <Input type="text" v-model="formItems.coverNum" placeholder="输入"></Input>
      </FormItem>
    </Form>
    
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { TrackOpTypes } from "vue";
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
export default {
  components: {
    FNCModal, BaiduMap,
    BmMarker,
    BmView,
    BmNavigation,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        objectName:"",
        province:"",
        city:"",
        county:"",
        address:"",
        amount:"",
        signAmount:"",
        receivedAmount:"",
        merchantName:"",
        linkman:"",
        linkmanPhone:"",
        businessMan:"",
        businessManPhone:"",
        signTime:"",
        startTime:"",
        expectTime:"",
        lengths:"",
        coverNum:"",
        area:[]
      }),
      formItems: {},
      ruleItems: {
        objectName: [
          { required: true, message: '管线名称不能为空', trigger: 'blur' }
        ],
        // longitude: [
        //   { required: true, message: '定位不能为空', trigger: 'blur' }
        // ],
        address: [
          { required: true, message: '管线具体地址不能为空', trigger: 'blur' }
        ],
        // merchantName: [
        //   { required: true, message: '商户名称不能为空', trigger: 'blur' }
        //],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,

      // devices: [],
      // projects: [],
      // flowths: [],
      // waterStates: [],
      area:[],//'110000','110100','110102'
      areaList:[],
    };
  },
  mounted() {
  },
  methods: {
    async loadData(id) {
      this.area=[]
      if(!this.areaList||this.areaList.length==0)  this.loadBaseData();
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartcovers/object/queryObjectDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              let a=[res.data.province,res.data.city,res.data.county]
              this.area=a;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
     loadBaseData() {
     this.$core.getAreaList("100000","",(res)=>{this.areaList=res}) 
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartcovers/object/updateObject" : "/smartcovers/object/addObject",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    // 点击了地图
    clickMap(e) {
      this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    makerCenter(point) {
      if (this.map) {
        this.selectedLocation = point;
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
      }
    },
    mapFn() {
      this.$refs.mapModal.showModal();
      if (this.formItems.longitude) {
        this.keyword = "";
        this.pageCenter = { lng: this.formItems.longitude, lat: this.formItems.latitude }
        this.makerCenter(this.pageCenter)
      } else if (this.formItems.address) {
        this.keyword = this.formItems.address
        this.setPlace(this.formItems.address)
      }
    },
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    selectMap() {
      this.formItems.longitude = this.selectedLocation.lng
      this.formItems.latitude = this.selectedLocation.lat
      this.$refs.mapModal.hideModal();
    },
    setPlace(myValue) {
      if (!myValue) return;
      var that = this
      var local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: () => {
          try {
            var pp = local.getResults().getPoi(0).point
            that.pageCenter = { lng: pp.lng, lat: pp.lat }
            that.makerCenter(pp)
          } catch (e) { }
        },
      })
      local.search(myValue)
    },
    onChangesignTime(e){
      this.formItems.signTime=e
    },
    onChangeStarttime(e){
      this.formItems.startTime=e
    },
    onAreaChange(e){
      if (e.length == 3) {
        this.formItems.province = e[0];
        this.formItems.city = e[1];
        this.formItems.county = e[2];
      }
    }
  }
};
</script>
    
<style scoped lang='less'></style>