<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="井盖编号" prop="coverOutNum">
        <Input type="text" v-model="formItems.coverOutNum" placeholder="输入井盖编号"></Input>
      </FormItem>
      <FormItem label="井盖名称" prop="coverName">
        <Input type="text" v-model="formItems.coverName" placeholder="50字以内"></Input>
      </FormItem>
      <FormItem label="具体地址" prop="address">
        <Input type="textarea" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="经纬度" prop="longitude">
        <Input v-model.trim="formItems.longitude" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
        <Input v-model.trim="formItems.latitude" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
        <Button @click="mapFn">地图选点</Button>
      </FormItem>
      <FormItem label="所属管线" prop="objectNum">
        <Select v-model="formItems.objectNum" style="width: 180px">
          <Option v-for="item in projects" :value="item.objectNum" :key="item.objectName">{{ item.objectName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="管线设备序号" prop="objectSort">
        <Input type="text" v-model="formItems.objectSort" placeholder="不重复的数字，0为禁用" style="width: 180px" v-if="formItems.id"></Input>
        <div style="color:#ddd" v-else>自动生成</div>
      </FormItem>
      <FormItem label="绑定设备" prop="deviceId">
        <Select v-model="formItems.deviceId" style="width: 180px">
          <Option v-for="item in devices" :value="item.id" :key="item.deviceName">{{ item.deviceName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="流向" prop="version">
        <Select v-model="formItems.flowth" style="width: 180px">
          <Option v-for="item in flowths" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="水状态" prop="waterState">
        <Select v-model="formItems.waterState" style="width: 180px">
          <Option v-for="item in waterStates" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="深度" prop="depth">
        <Input type="text" v-model="formItems.depth" placeholder="深度 cm" style="width: 180px"></Input>
      </FormItem>

      <FormItem label="备注" prop="remarks">
        <Input type="textarea" v-model="formItems.remarks" placeholder="备注" rows="4"></Input>
      </FormItem>

    </Form>
    <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
      <template v-slot:contentarea>
        <div
          style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;">
          <Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button type="primary"
            @click="setPlace(keyword)">查询</Button>
        </div>
        <baidu-map :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter" :zoom="zoom"
          @ready="handler" :ak="$store.state.baiduAk" :clicking="true" @click="clickMap" :scroll-wheel-zoom="true">
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
        </baidu-map>
      </template>
      <template #toolsbar>
        <Button type="info" style="margin-right: 10px" @click="classModal">取消</Button>
        <Button type="primary" @click="selectMap">确定</Button>
      </template>
    </FNCModal>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
export default {
  components: {
    FNCModal, BaiduMap,
    BmMarker,
    BmView,
    BmNavigation,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        deviceId: 0,
        createBy: "",
        createTime: "",
        updateTime: null,
        updateBy: null,
        objectNum: "",
        objectSort:0,
        coverNum: "",
        coverOutNum: "",
        coverName: null,
        address: null,
        longitude: null,
        latitude: null,
        remarks: null,
        depth: "",
        flowth: null,
        waterState: null,
        material: null,
        lengthSize: null,
        widthSize: null,
        manholeCoverType: null,
      }),
      formItems: {},
      ruleItems: {
        coverOutNum: [
          { required: true, message: '井盖编号不能为空', trigger: 'blur' }
        ],
        coverName: [
          { required: true, message: '井盖名称不能为空', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '定位不能为空', trigger: 'blur' }
        ],
        objectNum: [
          { required: true, message: '必须绑定管线', trigger: 'blur' }
        ],
        deviceId: [
          { type:"number", required: true, message: '必须绑定设备', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,

      devices: [],
      projects: [],
      flowths: [],
      waterStates: [],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    async loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartcovers/cover/queryCoverDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$core.getStyleParamList("flowth", false, null, (res) => {
        this.flowths = res || []
      })
      this.$core.getStyleParamList("waterState", false, null, (res) => {
        this.waterStates = res || []
      })
      this.$get({
        url: "/smartcovers/cover/queryCoverDevice", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.devices = res.dataList
          }
        }
      })
      this.$get({
        url: "/smartcovers/object/queryObject", data: { pageSize: 1000 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.projects = res.dataList
          }
        }
      })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartcovers/cover/updateCover" : "/smartcovers/cover/addCover",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    // 点击了地图
    clickMap(e) {
      this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    makerCenter(point) {
      if (this.map) {
        this.selectedLocation = point;
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
      }
    },
    mapFn() {
      this.$refs.mapModal.showModal();
      if (this.formItems.longitude) {
        this.keyword = "";
        this.pageCenter = { lng: this.formItems.longitude, lat: this.formItems.latitude }
        this.makerCenter(this.pageCenter)
      } else if (this.formItems.address) {
        this.keyword = this.formItems.address
        this.setPlace(this.formItems.address)
      }
    },
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    selectMap() {
      this.formItems.longitude = this.selectedLocation.lng
      this.formItems.latitude = this.selectedLocation.lat
      this.$refs.mapModal.hideModal();
    },
    setPlace(myValue) {
      if (!myValue) return;
      var that = this
      var local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: () => {
          try {
            var pp = local.getResults().getPoi(0).point
            that.pageCenter = { lng: pp.lng, lat: pp.lat }
            that.makerCenter(pp)
          } catch (e) { }
        },
      })
      local.search(myValue)
    },
  }
};
</script>
    
<style scoped lang='less'></style>