<template>
  <div class="fnc-website-infomation-view">
    <div class="website-infomation-content" v-if="info&&info.id">
      <div class="website-infomation-title" v-if="id">
        {{ info.infoTitle }}
      </div>
      <div class="website-infomation-time"  v-if="id">
        <span>{{ info.publishtime || info.addtime }}</span>
        <span v-if="info.fromIn">发布于{{ info.fromIn }}</span> 
      </div>
      <div class="website-infomation-content" v-html="info.content">
      </div>
    </div>
    <div class="fnc-website-loadding" v-else>
      {{ info == null ? "" : "暂无内容" }}
    </div>
  </div>
</template>
<script>
//路由定义：@ROUTER=/information/view
import "./index.less"
export default {
  data() {
    return {
      functionId: "",
      id: 0,
      info: null,
    };
  },
  mounted() {
    this.functionId = this.$core.getUrlParam("functionId");
    this.id = this.$core.getUrlParam("id");
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      if (this.functionId) {
      this.$Message.config({ top:300});
      this.$Message.loading({content:"加载中...",duration:0})
        this.$get({
          url: this.id ? "/gateway/api/information/loadViewById" : "/gateway/api/information/loadViewByFunctionId",
          data: { functionId: this.functionId, id: this.id },
          success: (res) => {
            this.info={};
            if (res.code == '200')
              this.info = res.data || {};
          },complete:()=>{
          this.$Message.destroy();
        }
        })
      }
    },
  },
};
</script>