<template>
  <FNCContent>
    <template v-slot:title>组织结构{{ orgTypeName ? " -- " + orgTypeName : "" }}</template>
    <template v-slot:toolsbarRight>
      <Button type="error" style="float: right; margin-top: 0px; width: 100px" icon="ios-share-outline"
        :loading="saveLoading" :disabled="saveLoading" @click="submit">保存</Button>
    </template>
    <template v-slot:contentArea>
      <div class="leftMenu">
        <Tree :data="menuList" :load-data="getInitData" :render="renderContent" @on-select-change="select"
          class="demo-tree-render">
        </Tree>
      </div>
      <div class="rightEdit">
        <div v-if="JSON.stringify(currentFormData) !== '{}'" style="position: relative; width: 99%">

          <Form :model="currentFormData" :label-width="120" :rules="validateForm" ref="form" id="form">
            <FormItem label="层级位置" style="margin-top: 20px">
              <Breadcrumb separator=">">
                <BreadcrumbItem v-for="(item, index) in bcTitle" :key="index" style="margin-top: 0px; height: 20px">{{
                  item }}
                </BreadcrumbItem>
              </Breadcrumb>
            </FormItem>
            <FormItem label="ID" v-if="currentFormData.id">
              <Input disabled v-model.trim="currentFormData.id"></Input>
            </FormItem>
            <FormItem label="代码" v-if="currentFormData.orgCode">
              <Input disabled v-model.trim="currentFormData.orgCode"></Input>
            </FormItem>
            <FormItem label="名称" prop="orgName">
              <Input v-model.trim="currentFormData.orgName" placeholder="名称不能为空"></Input>
            </FormItem>
            <FormItem label="专属代码">
              <Input v-model.trim="currentFormData.orgExtraCode" placeholder="该组织的专属代码，可留空"></Input>
            </FormItem>

            <FormItem label="图片">
              <FNCUpload @success="uploadSuccess" type="image" accept=".jpg,.png,.gif,.jpeg"
                :defaultList="currentFormData.picture" :showView="true"></FNCUpload>
            </FormItem>
            <FormItem label="描述">
              <Input type="textarea" v-model="currentFormData.info" rows="5" placeholder="" />
            </FormItem>
            <FormItem label="地址">
              <Input v-model.trim="currentFormData.address" placeholder="该组织的地址，可留空"></Input>
            </FormItem>
            <FormItem label="经纬度">
              <Input v-model.trim="currentFormData.lng" placeholder="经度" style="width: 200px; margin-right: 20px"></Input>
              <Input v-model.trim="currentFormData.lat" placeholder="纬度" style="width: 200px; margin-right: 20px"></Input>
              <Button @click="mapFn">地图选点</Button>
            </FormItem>
            <FormItem label="排序">
              <InputNumber :min="1" v-model.trim="currentFormData.seq"></InputNumber>
            </FormItem>
            <FormItem label="启禁用">
              <RadioGroup v-model="currentFormData.idisplay">
                <Radio :label="1">启用</Radio>
                <Radio :label="2">禁用</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
        </div>
        <div v-else  style="position: relative; width: 99%">
        <Form  :label-width="120" label-colon>
          <div style="margin: 20px;color:#ff0000;font-size: 18px;">组织结构各属性说明：</div>
            <FormItem label="层级位置" style="color: #2d8cf0;">
            该项在组织结构中的从属层级
            </FormItem>
            <FormItem label="ID" style="color: #2d8cf0;">
             ID主键，索引号
            </FormItem>
            <FormItem label="代码" style="color: #2d8cf0;">
              自动生成标准代码
            </FormItem>
            <FormItem label="名称"  style="color: #2d8cf0;">
             必填项，组织结构可视名称
            </FormItem>
            <FormItem label="专属代码" style="color: #2d8cf0;">
              可选项，根据组织结构的用途定义
            </FormItem>

            <FormItem label="图片" style="color: #2d8cf0;">
              可选项，根据组织结构的用途定义
            </FormItem>
            <FormItem label="描述" style="color: #2d8cf0;">
             对该项描述，有助于理解此项用途
            </FormItem>
            <FormItem label="地址" style="color: #2d8cf0;">
            可选项，根据组织结构的用途定义
            </FormItem>
            <FormItem label="排序" style="color: #2d8cf0;">
             应用中按此项数字从小到大排序
            </FormItem>
            <FormItem label="启禁用" style="color: #2d8cf0;">
             设置禁用后，应用中不可见
            </FormItem>
          </Form>
        </div>
        <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
          <template v-slot:contentarea>
            <div
              style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;">
              <Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button
                type="primary" @click="setPlace(keyword)">查询</Button>
            </div>
            <baidu-map :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter" :zoom="zoom"
              @ready="handler" :ak="$store.state.baiduAk" :clicking="true" @click="clickMap" :scroll-wheel-zoom="true">
              <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
              <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
            </baidu-map>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="classModal">取消</Button>
            <Button type="primary" @click="selectMap">确定</Button>
          </template>
        </FNCModal>
      </div>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/organization
import FNCContent from "@/components/FNCContent";
import FNCModal from "@/components/FNCModal";
import FNCUpload from "@/components/FNCUpload";
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
export default {
  components: {
    FNCContent,
    FNCModal,
    FNCUpload, BaiduMap, BmMarker, BmView, BmNavigation
  },
  data() {
    return {
      orgType: "",
      orgTypeName: "",
      menuList: [
        {
          orgName: "根目录",
          id: "0",
          orgCode: "0",
          expand: true,
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                style: {
                  display: "inline-block",
                  width: "100%",
                },
              },
              [
                h("span", data.orgName),
                h(
                  "span",
                  {
                    style: {
                      display: "inline-block",
                      float: "right",
                      marginRight: "12px",
                    },
                  },
                  [
                    h("Button", {
                      props: Object.assign({}, this.buttonProps, {
                        icon: "md-refresh",
                        type: "info",
                      }),
                      style: {
                        marginRight: "5px",
                      },
                      on: {
                        click: (e) => {
                          e.stopPropagation();
                          this.refresh();
                        },
                      },
                    }),
                    h("Button", {
                      props: Object.assign({}, this.buttonProps, {
                        icon: "md-add",
                        type: "primary",
                      }),
                      style: {
                        width: "60px",
                        fontSize: "16px",
                      },
                      on: {
                        click: (e) => {
                          e.stopPropagation();
                          this.append(data);
                        },
                      },
                    }),
                  ]
                ),
              ]
            );
          },
          children: [],
        },
      ],
      buttonProps: {
        type: "default",
        size: "small",
      },
      currentFormData: {},
      initData: {
        //json配置,对照表中的configJson
        id: "", //id
        orgCode: "", //orgCode
        parentId: "",
        orgName: "", //orgName
        orgExtraCode: "", //orgExtraCode
        picture: "", //picture
        info: "",
        lng: "",
        lat: "",
        address: "", //address
        orgType: "", //orgType
        branchId: 0,
        seq: 99, //seq
        idisplay: 1,
      },
      validateForm: {
        orgName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      bcTitle: [],
      saveLoading: false,
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
    };
  },

  methods: {

    // 更新列表
    updateData(data, parent, parentId) {
      if (parentId == undefined) parentId = data[0].parentId;
      if (parent == undefined) parent = this.menuList;
      //写入数据
      for (let k in parent) {
        if (parent[k].orgCode == parentId) {
          parent[k].children = data;
          // parent[k].expand = true;
          this.$forceUpdate();
          return;
        } else if (parent[k].children && parent[k].children.length > 0) {
          this.updateData(data, parent[k].children, parentId);
        }
      }
    },

    submit() {
      this.$refs.form.validate((status) => {
        if (status) {
          if (!this.currentFormData.parentId || !this.orgType) {
            this.$Message.error({
              background: true,
              content: "数据不完整，请刷新页面重新编辑。",
            });
            return;
          }
          if (!this.currentFormData.orgType) this.currentFormData.orgType = this.orgType;
          this.saveLoading = true;
          this.$Message.loading({
            content: "正在提交数据...",
            duration: 0,
          });

          this.$post({
            url: "/gateway/api/organization/save",
            data: {
              ...this.currentFormData
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Notice.config({ top: 20 });
                this.$Notice.success({
                  title: "保存成功，正在更新列表",
                  duration: 3,
                });
                //更新列表
                this.getInitData(
                  { orgCode: this.currentFormData.parentId },
                  this.updateData
                );

                if (!this.currentFormData.id) {
                 let orgCode = this.currentFormData.parentId;
                  this.currentFormData={};
                  this.append({ orgCode: orgCode })
                }
              } else {
                this.$Message.destroy();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
              this.$Message.error({
                background: true,
                content: "保存失败，请联系管理员处理",
              });
            });
        }
      });
    },
    refresh() {
      this.currentFormData = {};
      this.menuList[0].children = [];
      this.getInitData({ orgCode: "0" });
    },
    //增加节点
    append(data) {
      this.checkModify(() => {
        var idata = JSON.parse(JSON.stringify(this.initData));
        idata.parentId = data.orgCode;
        idata.orgType = this.orgType;
        this.edit(idata);
      });
    },
    edit(data) {
      this.currentFormData = data;
      this.$nextTick(() => {
        this.bcTitle = [];
        this.getBCTitle(data.parentId, this.menuList[0]);
      });
    },
    getBCTitle(parentId, obj) {
      if (obj.orgCode == parentId) {
        this.bcTitle.unshift(obj.orgName);
        this.getBCTitle(obj.parentId, this.menuList[0]);
      } else if (obj.children && obj.children.length > 0) {
        for (let k in obj.children) {
          this.getBCTitle(parentId, obj.children[k]);
        }
      }
    },
    select(arr, data) {
      if (data.id && data.id != "0") {
        this.checkModify(() => {
          this.$Message.loading({
            content: "正在加载数据...",
            duration: 0,
          });
          this.$get({
            url: "/gateway/api/organization/view",
            data: {
              id: data.id,
            },
          })
            .then((res) => {
              this.$Message.destroy();
              if (res.code == 200 && res.data && res.data.id) {
                this.bcTitle = [];
                this.getBCTitle(data.orgCode, this.menuList[0]);
                this.bcTitle.pop();
                var idata = JSON.parse(JSON.stringify(this.initData));
                idata = { ...idata, ...res.data };
                this.edit(idata);
              } else {
                this.$Message.destroy();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              this.$Message.error({
                background: true,
                content: "获取数据失败，请联系管理员处理",
              });
            });
        });
      }
    },

    remove(root, node, data) {
      // console.log(node, data);
      this.$Modal.confirm({
        title: "温馨提示",
        loading: true,
        content: `<p>您正在删除<span style="color: red"> ${data.orgName} </span> ${data.children && data.children.length > 0
          ? "，其下所有的子级目录将会被同时删除"
          : ""
          }，此操作不可逆，是否继续</p>`,
        onOk: () => {
          this.$post({
            url: "/gateway/api/organization/delete",
            data: {
              id: data.id,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功",
                });
                this.$Modal.remove();
                //删除节点
                const parentKey = root.find((el) => el === node).parent;
                const parent = root.find((el) => el.nodeKey === parentKey).node;
                const index = parent.children.indexOf(data);
                parent.children.splice(index, 1);
                this.currentFormData = {};
              } else {
                this.$Modal.remove();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              this.$Modal.remove();
              this.$Message.error({
                background: true,
                content: "删除失败，请联系管理员处理",
              });
            });
        },
      });
    },
    renderContent(h, { root, node, data }) {
      return h(
        "span",
        {
          style: {
            display: "inline-block",
            width: "100%",
          },
        },
        [
          h("span", data.orgName),
          h(
            "span",
            {
              style: {
                display: "inline-block",
                float: "right",
                marginRight: "0px",
              },
            },
            [
              h("Button", {
                props: Object.assign({}, this.buttonProps, {
                  icon: "md-add",
                }),
                style: {
                  marginRight: "5px",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    this.append(data);
                  },
                },
              }),
              h("Button", {
                props: Object.assign({}, this.buttonProps, {
                  icon: "md-remove",
                }),
                style: {
                  marginRight: "5px",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    this.remove(root, node, data);
                  },
                },
              }),
            ]
          ),
        ]
      );
    },
    getInitData(item, callback) {
      this.$get({
        url: "/gateway/api/organization/list",
        data: {
          parentId: item.orgCode,
          orgType: this.orgType,
        },
      })
        .then((res) => {
          // console.log("getInitData=",res);
          if (res.code == 200) {
            this.$Message.destroy();
            if (!res.dataList) return;
            var list = res.dataList;
            list.map((item1) => {
              if (item1.hasChild > 0) {
                item1.expand = false;
                item1.loading = false;
                item1.children = [];
              }
            });
            if (item.orgCode == "0") {
              this.menuList[0].children = list;
            } else {
              if (callback) callback(list);
            }
            this.$forceUpdate()
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          // console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    uploadSuccess(file) {
      this.currentFormData.picture = file.url;
    },
    checkModify(callback) {
      if (this.currentFormData && this.currentFormData.orgName) {
        this.$Modal.confirm({
          title: "重置编辑区提醒",
          content: "你有内容正在编辑中，是否放弃？",
          onOk: callback,
        });
        return;
      } else {
        callback();
      }
    },
    // 点击了地图
    clickMap(e) {
      this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    makerCenter(point) {
      if (this.map) {
        this.selectedLocation = point;
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
      }
    },
    mapFn() {
      this.$refs.mapModal.showModal();
      if (this.currentFormData.lng) {
        this.keyword = "";
        this.pageCenter = { lng: this.currentFormData.lng, lat: this.currentFormData.lat }
        this.makerCenter(this.pageCenter)
      } else if (this.currentFormData.address) {
        this.keyword = this.currentFormData.address
        this.setPlace(this.currentFormData.address)
      }
    },
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    selectMap() {
      this.currentFormData.lng = this.selectedLocation.lng
      this.currentFormData.lat = this.selectedLocation.lat
      this.$refs.mapModal.hideModal();
    },
    setPlace(myValue) {
      if (!myValue) return;
      var that = this
      var local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: () => {
          try {
            var pp = local.getResults().getPoi(0).point
            that.pageCenter = { lng: pp.lng, lat: pp.lat }
            that.makerCenter(pp)
          } catch (e) { }
        },
      })
      local.search(myValue)
    },
  },
  created() {
  
    this.orgType = this.$core.getUrlParam("orgType") || null;
    if(this.orgType){
      this.$Message.loading({
      content: "正在加载数据，请稍等...",
      duration: 0,
    });
    this.orgTypeName = this.$core.getUrlParam("orgTypeName") || this.orgType;
    this.menuList[0].orgName = this.orgTypeName;
    this.getInitData({ orgCode: "0" });
    }else{
      this.$Modal.error({title:"加载错误",content:"组织结构的类型未定义，无法加载。",okText:"返回上一级",onOk:()=>{
        this.$router.back()
      }})
    }
  },
};
</script>

<style lang="less" scoped>
.leftMenu {
  position: fixed;
  top: 60px;
  left: 0px;
  width: calc(100% - 800px);
  bottom: 0;
  background: #f5f5f5;
  overflow: auto;

}

.rightEdit {
  position: fixed;
  top: 60px;
  width: 800px;
  right: 0;
  bottom: 0;
  overflow: auto;
}

/deep/.demo-tree-render .ivu-tree-title {
  min-width: 250px;
  width: calc(100% - 30px);
  padding-top: 10px;

  &>span {
    &>span:first-of-type {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 110px);
      display: inline-block;
    }
  }
}

/deep/.demo-tree-render .ivu-tree-arrow {
  padding: 10px;
}

/deep/.ivu-breadcrumb {
  background-color: #fff;
  line-height: 36px;
  padding: 0 20px;
  margin-bottom: 10px;
  position: relative;
}

.form {
  padding-right: 30px;
  height: calc(100vh - 106px);
  overflow: auto;
}

.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

.focus {
  border: 2px dotted #ff9900;
  box-shadow: 0px 0px 10px 2px #ff9900;
}

.FNC-header-index {
  overflow: hidden;

  &:hover {
    border: 2px dotted #ff9900;
  }

  .header-index-content {
    position: relative;
    width: 100%;
    z-index: 2;

    .title {
      font-size: 17px;
      // display: flex;
      width: calc(100% - 20px);
      padding: 0px 10px;
      line-height: 40px;
      height: 40px;
      color: #fff;
      white-space: nowrap;

      .space {
        margin-left: 6px;
      }
    }
  }

  .header-bgimg {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: auto;
  }

  .FNC-header-index-radius {
    margin-top: 5px;
    background: #fff;
    height: 12px;
    border-radius: 10px 10px 0 0;
  }

  .FNC-header-index-radius-immersion {
    position: relative;
    margin-top: -10px;
    background: #fff;
    height: 12px;
    border-radius: 10px 10px 0 0;
  }

  #headerCompGroup {
    .banner-swiper {
      width: 96%;
      margin-left: 2%;
      margin-bottom: 10px;
    }
  }
}

#componentsGroup {
  div:hover {
    box-shadow: 0px 0px 10px #808695;
  }

  .banner-swiper {
    height: 120px !important;
    border-radius: 5px;
    border: 2px solid #eee;
  }
}

.FNC-tabbar-index {
  position: fixed;
  bottom: 20px;
  left: calc((100% - 400px) / 2);
  right: calc((100% - 400px) / 2);
  height: 55px;
  background: url("/images/custompage-tabbar.jpg");
  background-size: 100% 100%;
  border-radius: 0 0 10px 10px;
  transition: all 0.2s;
  z-index: 999;

  &:hover {
    border: 2px dotted #ff9900;
  }
}
</style>
