 <template>
  <!-- 样式组件 -->
  <div :class="'fnc-goodslist-view'" v-if="!isParamComp">
    <img src="/images/goodslist.png" />
  </div>
  <!-- 参数设置组件 -->
  <div class="fnc-goodslist-params" v-else>
    <Form :label-width="120" ref="goodslistForm">
      <FormItem label="布局模式">
        <RadioGroup v-model="data.listType" @on-change="handleData">
          <Radio :label="1">瀑布流</Radio>
          <Radio :label="2">列表</Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="是否分页">
        <RadioGroup v-model="data.isPage">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="每页条目数">
        <Input
          type="Number"
          v-model.number="data.pageSize"
          placeholder="默认：10"
        ></Input>
      </FormItem>
      <FormItem label="背景色">
        <ColorPicker v-model="data.background" recommend />
      </FormItem>
         <FormItem label="搜索栏搜索">
        <RadioGroup v-model="data.isSearch">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
       <FormItem label="绑定搜索栏名称" v-if="data.isSearch">
        <Input
          placeholder="注：从对应的搜索栏组件设置中找到"
          v-model.trim="data.searchName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isSearch">
        <Input
          placeholder="注：API中参与搜索栏搜索的字段名"
          v-model.trim="data.searchKeyword"
        ></Input>
      </FormItem>
      <FormItem label="标签栏搜索">
        <RadioGroup v-model="data.isLFTabs">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="绑定标签栏名称" v-if="data.isLFTabs">
        <Input
          placeholder="注：从对应的标签栏组件设置中找到"
          v-model.trim="data.LFTabsName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isLFTabs">
        <Input
          placeholder="注：API中参与标签栏搜索的字段名"
          v-model.trim="data.lftabsKeyword"
        ></Input>
      </FormItem>
      <FormItem label="API地址">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
     <FormItem label="响应参数互换" v-if="data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
          placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
           <FormItem label="JSON数据" v-else>
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="20"
          placeholder='例：[
                {
                  "goodsId": "ID",
                  "goodsImg": "图片",
                  "goodsName": "标题",
                  "isHot": 1,//热销
                  "isBest": 1,//推荐
                  "shopPrice": 112,//售价
                  "goodsNumber": 22//库存数量
                }
              ]'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button
          style="margin: 5px"
          type="info"
          @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import GoodsList from "./components/GoodsList";
 * 商品组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "GoodsList",
        api: "",
        data: null,
        listType: 1,
        background: "#f5f5f5",
        isPage: true,
        pageSize: 10,
        isSearch: false,  //是否需要搜素栏搜索
        searchKeyword: '',  // 搜素栏关键字
        isLFTabs: false,    // 是否需要标签页搜索
        lftabsKeyWord: '',  // 标签页关键字
        LFTabsName:"",
        searchName:"",
      },
      dfData: JSON.stringify(
        [
          {
            goodsId: "",
            goodsImg: "",
            goodsName: "",
            isHot: 0,
            isBest: 0,
            shopPrice: 0,
            goodsNumber: 0,
          },
        ],
        null,
        "\t"
      ),
    };
  },
  created() {},
  mounted(){
    this.handleData();
  },
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    handleData() {
      if (this.data.listType == 2) {
        this.$(this.target).find("img").attr("src", "/images/goodslist2.png");
      } else {
        this.$(this.target).find("img").attr("src", "/images/goodslist.png");
      }
    },
   verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.fnc-goodslist-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img {
    width: 100%;
  }
}
</style>