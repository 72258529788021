<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="信息类型" prop="infoType">
        <Select v-model="formItems.infoType" :disabled="infoType ? true : false" style="width: 200px">
          <Option v-for="item in infoTypeList" :value="item.styleid" :key="item.stylename">{{ item.stylename }}</Option>
        </Select>
      </FormItem>
      <FormItem label="分类/标签" prop="label">
        <Select v-model="formItems.label" style="width: 300px" filterable allow-create @on-create="handleCreate"
          placeholder="请选择，可输入并创建新标签。">
          <Option v-for="item in labelList" :value="item" :key="item">{{ item }}</Option>
        </Select>
        <span style="margin-left: 10px;">输入新标签（8个字内）后，按下回车键即可新建标签。</span>
      </FormItem>
      <FormItem label="标题" prop="infoTitle">
        <Input type="text" v-model="formItems.infoTitle" placeholder="100字以内"></Input>
      </FormItem>
      <FormItem label="价格" prop="price" v-if="formItems.infoType == 'intro'">
        <Input type=" " v-model="formItems.price" style="width:200px"></Input> 元
      </FormItem>
      <FormItem label="信息来源" prop="fromIn" v-if="formItems.infoType == 'news'">
        <Input type="text" v-model="formItems.fromIn"></Input>
      </FormItem>

      <FormItem label="发布时间" prop="publishtime" v-if="formItems.infoType == 'news'">
        <DatePicker type="datetime" :value='formItems.publishtime' @on-change="formItems.publishtime = $event" clearable
          editable placeholder="可指定发布时间" style="width: 200px" />
      </FormItem>

      <FormItem label="开始时间" prop="starttime" v-if="formItems.infoType == 'activity'">
        <DatePicker :value='formItems.starttime' @on-change="formItems.starttime = $event" type="datetime"
          format="yyyy-MM-dd HH:mm" placeholder="活动开始时间" style="width: 200px" />
      </FormItem>

      <FormItem label="结束时间" prop="endtime" v-if="formItems.infoType == 'activity'">
        <DatePicker :value='formItems.endtime' @on-change="formItems.endtime = $event" type="datetime"
          format="yyyy-MM-dd HH:mm" placeholder="活动结束时间" style="width: 200px" />
      </FormItem>
      <FormItem label="地址" prop="fromIn" v-if="formItems.infoType == 'activity' || formItems.infoType == 'map'">
        <Input type="text" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="经纬度" v-if="formItems.infoType == 'activity' || formItems.infoType == 'map'">
        <Input v-model.trim="formItems.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
        <Input v-model.trim="formItems.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
        <Button @click="mapFn">地图选点</Button>
      </FormItem>
      <FormItem label="图片标题" prop="infoPicture">
        <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.infoPicture" :showDel="true"
          @success="upLogoImage">
        </FNCUpload>
        <div style="width:calc(100% - 120px);margin-left:30px;float:left;display: flex;flex-direction: column;">
          <div style="font-size: 12px;color:#888;">从“富文本图片清单”中选图</div>
          <div
            style="width:100%;height:60px;border-radius:5px;padding:5px;overflow-y: auto;font-size: 12px;color:#888;background-color: #f5f5f5;">
            <template v-if="pictureList && pictureList.length > 0">
              <img v-for="item in pictureList" :src="item.url" style="height:50px;margin:0 2px 4px;cursor:pointer;"
                @click="() => { formItems.infoPicture = item.url }" />
            </template>
            <div v-else>暂无图片</div>
          </div>
        </div>
      </FormItem>
      <div style="padding-left:80px;">
        <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=内容"
          style="width:100%;height:500px;border:0px;" @load="setContent"></iframe>
      </div>
      <FormItem label="启禁用" prop="idisplay">
        <RadioGroup v-model="formItems.idisplay">
          <Radio :label="1">启用</Radio>
          <Radio :label="0">禁用</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
    <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
      <template v-slot:contentarea>
        <div
          style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;">
          <Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button type="primary"
            @click="setPlace(keyword)">查询</Button></div>
        <baidu-map :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter" :zoom="zoom"
          @ready="handler" :ak="$store.state.baiduAk" :clicking="true" @click="clickMap" :scroll-wheel-zoom="true">
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
        </baidu-map>
      </template>
      <template #toolsbar>
        <Button type="info" style="margin-right: 10px" @click="classModal">取消</Button>
        <Button type="primary" @click="selectMap">确定</Button>
      </template>
    </FNCModal>
  </div>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';
import FNCModal from "@/components/FNCModal"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"

export default {
  components: {
    FNCUpload, FNCModal, BaiduMap,
    BmMarker,
    BmView,
    BmNavigation,
  },
  props: {
    infoTypeList: [],
    infoType: null,
  },

  data() {
    return {
      pictureList: [],
      formItemsDefault: {
        id: 0,
        infoType: "",
        infoTitle: "",
        infoPicture: "",
        price: 0,
        fromIn: '',
        idisplay: 1,
        content: "",
        publishtime: "",
        starttime: "",
        endtime: "",
        lng: "",
        lat: "",
        address: "",
      },
      formItems: {},
      ruleItems: {
        infoType: [
          { required: true, message: '信息类型不能为空', trigger: 'blur' }
        ],
        infoTitle: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      labelList: [],
    };
  },
  mounted() {
    //初始化编辑富文本后的事件
    top.editorCallback = this.editorCallback;
  },
  methods: {
    setContent() {
      var v_Obj = document.getElementById("ueditor").contentWindow;//获取iframe对象
      try {
        v_Obj.setContent(this.formItems.content);//写入编辑器富文本内容
        this.pictureList = v_Obj.getMultimedia("image");//获取多媒体集
      } catch (e) { }
    },
    editorCallback() {
      var v_Obj = document.getElementById("ueditor").contentWindow;//获取iframe对象
      this.pictureList = v_Obj.getMultimedia("image");//获取多媒体集
    },
   async loadData(id) {
      this.formItems = JSON.parse(JSON.stringify(this.formItemsDefault));
      this.formItems.infoType = this.infoType;
      await this.loadLabelList();
      if (id) {
        this.$get({
          url: "/gateway/api/information/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.setContent()
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      } else {
        this.setContent()
      }
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.formItems.publishtime = this.$core.formatDate(this.formItems.publishtime || new Date(), "yyyy-MM-dd HH:mm:ss");
          // this.formItems.starttime = this.$core.formatDate(this.formItems.starttime || new Date(), "yyyy-MM-dd HH:mm:ss");
          // this.formItems.endtime = this.$core.formatDate(this.formItems.endtime || new Date(), "yyyy-MM-dd HH:mm:ss");
          var v_Obj = document.getElementById("ueditor").contentWindow;//获取iframe对象
          this.formItems.content = v_Obj.getContent();
          callback(true)
          this.$post({
            url: "/gateway/api/information/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(file) {
      this.formItems.infoPicture = file.url;
    },
    // 点击了地图
    clickMap(e) {
      this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    makerCenter(point) {
      if (this.map) {
        this.selectedLocation = point;
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
      }
    },
    mapFn() {
      this.$refs.mapModal.showModal();
      if (this.formItems.lng) {
        this.keyword = "";
        this.pageCenter = { lng: this.formItems.lng, lat: this.formItems.lat }
        this.makerCenter(this.pageCenter)
      } else if (this.formItems.address) {
        this.keyword = this.formItems.address
        this.setPlace(this.formItems.address)
      }
    },
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    selectMap() {
      this.formItems.lng = this.selectedLocation.lng
      this.formItems.lat = this.selectedLocation.lat
      this.$refs.mapModal.hideModal();
    },
    setPlace(myValue) {
      if (!myValue) return;
      var that = this
      var local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: () => {
          try {
            var pp = local.getResults().getPoi(0).point
            that.pageCenter = { lng: pp.lng, lat: pp.lat }
            that.makerCenter(pp)
          } catch (e) { }
        },
      })
      local.search(myValue)
    },
    handleCreate(e) {
      if (this.labelList.find(item => item == e) == null)
        this.labelList.push(e);
    },
   async loadLabelList(){
     await this.$get({
        url: "/gateway/api/information/labellist",
        success: res => {
          if(res.code=="200")
          this.labelList = res.dataList||[];
        }
      });
    },
  }
};
</script>
    
<style scoped lang='less'></style>