import Vue from "vue";
import App from "./index.vue";
import router from "@/router";
import store from "@/store";
import ViewUI from "view-design";
import axios from "axios";
import VueAxios from "vue-axios";
import md5 from 'js-md5';
import $ from "jquery";
import { get, post,getProxy } from "@/api";
import core from '@/utils/core'
import VueCropper from 'vue-cropper'

Vue.use(VueCropper)

Vue.prototype.$core = core;
axios.defaults.timeout = 300000;
Vue.use(ViewUI, VueAxios, axios);
Vue.prototype.axios = axios;

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$getProxy = getProxy;
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;
Vue.prototype.$ = $;

//可自定义路由起码目录
var startPath=["views/website/"];

new Vue({
  router:router(startPath),
  store,
  render: (h) => h(App),
}).$mount("#app");
