<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="APPID" prop="appid">
        <Input type="text" v-model="formItems.appid" placeholder="APPID"></Input>
      </FormItem>
      <FormItem label="APP名称" prop="appName">
        <Input type="text" v-model="formItems.appName" placeholder="50字以内"></Input>
      </FormItem>
      <FormItem label="版本名称" prop="version">
        <Input type="text" v-model="formItems.version" placeholder="例：1.1.1"></Input>
      </FormItem>
      <FormItem label="版本号" prop="versionCode">
        <Input type="number" number  v-model="formItems.versionCode" placeholder="数字，例：10101"></Input>
      </FormItem>
      <FormItem label="APK包上传" prop="url">
        <FNCUpload type="file" accept=".apk" :showView="true" :defaultList="formItems.url"
          @success="upAPK">
        </FNCUpload>
      </FormItem>
      <FormItem label="更新内容" prop="updateInfo">
        <Input type="textarea" v-model="formItems.updateInfo" placeholder="更新内容" rows="8"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';

export default {
  components: {
    FNCUpload
  },
  data() {
    return {
      pictureList: [],
      formItemsDefault: JSON.stringify({
        id: 0,
        appName: "",
        appid: "",
        version: "",
        versionCode: "",
        updateInfo: '',
        url: "",
      }),
      formItems: {},
      ruleItems: {
        appName: [
          { required: true, message: 'APP不能为空', trigger: 'blur' }
        ],
        appid: [
          { required: true, message: 'APPID不能为空', trigger: 'blur' }
        ],
        versionCode: [
          {required: true, message: '版本号不能为空', trigger: 'blur',type:"number" }
        ],
        url: [
          { required: true, message: '更新地址不能为空', trigger: 'blur' }
        ],
      },
    };
  },
  mounted() {
  },
  methods: {
   async loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/gateway/api/appupdate/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      } 
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: "/gateway/api/appupdate/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upAPK(file) {
      this.formItems.url = file.url;
    },
  }
};
</script>
    
<style scoped lang='less'></style>