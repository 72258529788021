<template>
  <!-- 样式组件 -->
  <div :class="'FNC-picturegroup-view'" v-if="!isParamComp">
    <img src="/images/picturegroup.png" />
  </div>
  <!-- 参数设置组件 -->
  <div class="FNC-picturegroup-params" v-else>
    <Form :label-width="100" ref="picturegroupForm">
      <FormItem label="配置提示">
        屏幕宽度为750px，请根据宽度比例计算上、下、左、右的距离及长、宽的大小，以px为单位。
      </FormItem>
      <FormItem label="图片集"> </FormItem>
      <Draggable
        :sort="true"
        :animation="200"
        style="width: 100%"
        @sort="handSort"
        v-if="showDraggable"
      >
        <div v-for="(item, index) in data.data" :key="'Pic' + index" class="card">
          <FNCUpload
            @success="
              (res) => {
                uploadSuccess(index, res);
              }
            "
            :defaultList="item.image"
            type="image"
            accept=".jpg,.png,.gif,.jpeg"
            v-if="showUpload"
          ></FNCUpload>
          <div style="display: flex; flex-wrap: wrap; margin-left: -30px">
            <FormItem label="ID" style="margin:2px;">
           <Input v-model.trim="item.id" placeholder="组件在页内的唯一标识，选填" style="width:263px;">
            </Input>
          </FormItem>
            <FormItem label="宽度" style="margin: 2px">
              <Input
                v-model.trim="item.width"
                placeholder="最大为750px"
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="高度" style="margin: 2px">
              <Input
                v-model.trim="item.height"
                placeholder="不确定可填：auto"
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="左边距" style="margin: 2px">
              <Input
                v-model.trim="item.marginLeft"
                placeholder=""
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="右边距" style="margin: 2px">
              <Input
                v-model.trim="item.marginRight"
                placeholder=""
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="上边距" style="margin: 2px">
              <Input
                v-model.trim="item.marginTop"
                placeholder=""
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="下边距" style="margin: 2px">
              <Input
                v-model.trim="item.marginBottom"
                placeholder=""
                style="width: 80px"
                @on-blur="handleData"
              ></Input>
            </FormItem>
            <FormItem label="圆角" style="margin: 2px">
              <RadioGroup v-model="item.radius" @on-change="handleData">
                <Radio :label="true">是</Radio>
                <Radio :label="false">否</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="跳转地址" style="display: flex; flex-direction: column">
              <div style="display: flex; margin: 2px; margin-left: -100px">
                <Select
                  v-model="item.action.type"
                  style="width: 130px; margin-right: 2px"
                  placeholder="跳转类型"
                >
                  <Option value="local">本地地址</Option>
                  <Option value="app">跳转APP</Option>
                  <Option value="weapp">跳转小程序</Option>
                  <Option value="web">跳转网页</Option>
                </Select>
                <Input v-model.trim="item.action.url">
                  <div slot="prepend">
                    <div style="color: #999">页面地址</div>
                  </div>
                </Input>
              </div>
              <div label="" style="display: flex; flex-direction: column">
                <div
                  style="display: flex; margin: 2px; margin-left: 5px"
                  v-if="item.action.type == 'app'"
                >
                  <Input v-model.trim="item.action.package">
                    <div slot="prepend" style="color: #999">APP主包名</div>
                  </Input>
                </div>
                <div
                  style="display: flex; margin: 2px; margin-left: 5px"
                  v-if="item.action.type == 'weapp'"
                >
                  <Input v-model.trim="item.action.appid">
                    <div slot="prepend" style="color: #999">原始APPID</div>
                  </Input>
                </div>
              </div>
            </FormItem>
          </div>
          <Icon type="md-close" size="20" @click="deleteData(index)" />
        </div>
      </Draggable>
      <FormItem label="">
        <Button type="info" @click="addData" style="width: 150px">增加</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import PictureGroup from "./components/PictureGroup";
 * 图片集组件
 */
import Draggable from "vuedraggable";
import FNCUpload from "@/components/FNCUpload";
export default {
  components: { Draggable, FNCUpload },
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showUpload: false,
      showDraggable: true,
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "PictureGroup",
        api: "",
        data: [],
      },
    };
  },
  mounted() {
    if (this.isParamComp) {
      this.showUpload = true;
      this.handleData();
    }
  },
  methods: {
    handleData() {
      var that = this.$(this.target);
      that.empty();
      if (this.data.data && this.data.data.length > 0) {
        //  that.addClass("FNC-picturegroup-group");
        this.$(this.target).css("width", "404px");
        this.$(this.target).css("margin-left", "-2%");
        this.data.data.map((item) => {
          if (!item.action) item.action = {};
          var w = "calc(" + item.width + " * (400/750))",
            h =
              item.height == "auto"
                ? item.height
                : "calc(" + item.height + " * (400/750))",
            t = "calc(" + item.marginTop + " * (400/750))",
            l = "calc(" + item.marginLeft + " * (400/750))",
            b = "calc(" + item.marginBottom + " * (400/750))",
            r = "calc(" + item.marginRight + " * (400/750))";
          that.append(
            this.$(
              '<img src="' +
                item.image +
                '" style="width:' +
                w +
                ";min-height:5px;background:#eee;height:" +
                h +
                ";margin:" +
                t +
                " " +
                r +
                " " +
                b +
                " " +
                l +
                ";" +
                (item.radius ? "border-radius:10px;" : "") +
                '">'
            )
          );
        });
      } else {
        //  that.removeClass("FNC-picturegroup-group");
        this.$(this.target).css("width", "100%");
        this.$(this.target).css("margin-left", "0");
        that.append(this.$('<img src="/images/picturegroup.png">'));
      }
    },
    addData() {
      if (!this.data.data) this.data.data = [];
      this.data.data.push({
        image: "",
        action: {},
        width: "750px",
        height: "auto",
        marginLeft: "0px",
        marginRight: "0px",
        marginTop: "0px",
        marginBottom: "0px",
        radius: false,
      });
      this.handleData();
    },
    deleteData(target) {
      if (this.data.data && this.data.data.length > target) {
        this.data.data.splice(target, 1);
        this.handleData();
        // console.log(this.data.data);
      }
    },
    uploadSuccess(target, file) {
      // if (file && file.url) {
      if (this.data.data && this.data.data.length > target) {
        this.data.data[target].image = file.url;
      }
      // }
      // this.showUpload=false;
      this.$nextTick(() => {
        // this.showUpload=true;
        this.handleData();
      });
    },
    handSort(e) {
      console.log(this.data.data);
      //拖拉
      this.data.data[e.oldIndex] = this.data.data.splice(
        e.newIndex,
        1,
        this.data.data[e.oldIndex]
      )[0];
      this.showDraggable = false;
      this.showUpload = false;
      this.$nextTick(() => {
        this.showDraggable = true;
        this.showUpload = true;
        this.handleData();
      });
    },
  },
};
</script>
<style lang="less">
.FNC-picturegroup-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #ffffff;
  img {
    width: 100%;
  }
}
.FNC-picturegroup-group {
  display: flex;
  flex-wrap: wrap;
}

.FNC-picturegroup-params {
  .card {
    margin: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    background: #f9f9f9;
    border-radius: 5px;
    border: #eee solid 1px;
    box-shadow: 1px 1px 5px #ccc;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
</style>
