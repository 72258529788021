<template>
  <!-- 分隔线组件 -->
  <div
    :class="
      'fnc-SeparationLine-view'
    "
    v-if="!isParamComp"
  >
  <img src="/images/line.png"/>
  </div>
  <!-- 分隔线组件 -->
  <div class="fnc-SeparationLine-params" v-else>
    <Form :label-width="150" ref="lineForm">
        <FormItem label="分隔线背景">
          <ColorPicker
            v-model="data.backgroundColor"
            recommend
             @on-active-change="colorChange"
          />
        </FormItem>
        <FormItem label="阴影">
          <RadioGroup v-model="data.shadow">
            <Radio :label="true">是</Radio>
            <Radio :label="false">否</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="分隔线高度">
        <Input
          v-model.trim="data.height"
          @on-blur="handleData"
        ></Input>
      </FormItem>
      <FormItem label="左边距">
        <Input
          v-model.trim="data.marginLeft"
          @on-blur="handleData"
        ></Input>
      </FormItem>
         <FormItem label="右边距">
        <Input
          v-model.trim="data.marginRight"
          @on-blur="handleData"
        ></Input>
      </FormItem>
         <FormItem label="上边距">
        <Input
          v-model.trim="data.marginTop"
          @on-blur="handleData"
        ></Input>
      </FormItem>
         <FormItem label="下边距">
        <Input
          v-model.trim="data.marginBottom"
          @on-blur="handleData"
        ></Input>
      </FormItem>
     
    </Form>
  </div>
</template>

<script>
/**
 * import SeparationLine from "./components/SeparationLine";
 * 分隔线
 */
export default {
  components: {},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {
        };
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "SeparationLine",
        height: '20px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        backgroundColor:"#f5f5f5",
        shadow:false,
      },
    };
  },
  mounted() {
    if(this.isParamComp)    this.handleData();
  },
  methods: {
    colorChange(color) {
      this.data.backgroundColor = color;
      this.handleData();
    },
      handleData() {
      var that=this.$(this.target);
      that.empty();
      that.css("width","calc(100% - "+this.data.marginLeft+" - "+this.data.marginRight+")");
      that.css("height",this.data.height);
      that.css("marginLeft",this.data.marginLeft);
      that.css("marginRight",this.data.marginRight);
      that.css("marginTop",this.data.marginTop);
      that.css("marginBottom",this.data.marginBottom);
      that.css("backgroundColor",this.data.backgroundColor);
      that.removeClass('fnc-SeparationLine-view-bottom-border');
      if(this.data.shadow==true){
        that.addClass('fnc-SeparationLine-view-bottom-border');
      }
      }
  },
};
</script>
<style lang="less">
.fnc-SeparationLine-params {
  width: 100%;
  margin: 5px 0;
}
.fnc-SeparationLine-view {
  position: relative;
  width: 100%;
  min-height: 5px;
  margin: 5px 0;
  // overflow: hidden;
  background-color: #eee;
  img{
    width: 100%;
  }
}
.fnc-SeparationLine-view-bottom-border{
  min-height:10px;
  border-bottom: 2px solid #00000012;
  box-shadow: 0px 10px 10px #00000012;
  z-index: 10;
}
</style>