<template>
  <div class="fnc-global-index">
    {{ title }}
  
  </div>
</template>
<script>
//路由定义：@ROUTER=/weixinauth
export default {
  components: {},
  data() {
    return {
      title: "正在授权中...",
      jsCode: "",
      bindingKey: "",
      state: "",
      weixinAppId: "",
      info:""
    };
  },
  created() {
    this.weixinAuth();
  },
  mounted() {

  },
  methods: {
    weixinAuth() {
      this.info=location.href
      this.jsCode = this.$core.getUrlParam("code");
      this.bindingKey = this.$core.getUrlParam("bindingKey");
      this.state = this.$core.getUrlParam("state");
      this.state=(this.state+"#").split("#")[0]
      this.weixinAppId = this.$core.getUrlParam("weixinAppId");
      var token = this.$core.getUrlParam("token");
      if(token){
        window.sessionStorage.setItem("token",token);
      }
      token=window.sessionStorage.getItem("token");
      if (!this.jsCode) {
        var url = (location.href + "???").split("?")[0]
        //使用公从号登录，跳转地址
        var REDIRECT_URI = encodeURIComponent(url)
        location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.weixinAppId + "&redirect_uri=" + REDIRECT_URI + "&response_type=code&scope=snsapi_base&state=" + this.bindingKey + "#wechat_redirect";
      } else {
        this.$post({
          url:"/gateway/api/weixinservice/oaBinding",
          data:{key:this.state,jsCode:this.jsCode},
          header:{Authorization : "Bearer " + token},
          success:(res)=>{
            if(res.code=="200"){
              this.title="已成功绑定公从号";
            }else{
              this.title=res.desc||"绑定公从号失败";
            }
          },
          fail:(res)=>{
            this.title=res.desc||"绑定公从号失败";
          }
        })
      }
    },
  },
};
</script>
<style scoped lang='less'>
.fnc-global-index{
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  font-size: 18px;
}
</style>