<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <div style="width:500px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入名称/手机号码" @on-search="loadData(1)" /></div>
    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="() => { editData() }" v-if="right.A">
        <Icon type="md-add-circle" size="18" />添加
      </Button>

      <Button type="warning" class="deleteDiv" @click="() => { deleteData() }" v-if="right.D">
        <Icon type="md-trash" size="18" />删除
      </Button>

    </template>
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" :fullscreen="false" width="800px" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal" :depts="depts"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//指定商户的用户管理：@ROUTER=/mgrbranchuser
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/MgrEdit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "商户用户列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: "left",
          align: 'center'
        },
        {
          title: "ID",
          key: "id",
          align: "center",
          width: 200,
        },
        {
          title: "姓名",
          key: "realname",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div",{style: { display: "flex",alignItems:"center" }}, [
              h(
                "img", {
                attrs: {
                  src: params.row.picture || "./images/noicon.png",
                },
                style: { width:"30px",height:"30px",borderRadius:"5px",marginRight:"5px" }
              }
              ), h(
                "div", {
              }, params.row.realname
              )
            ]
            )
          }
        },
        {
          title: "手机号码",
          key: "mobile",
          align: "center",
          width: 160,
        },
        {
          title: "性别",
          key: "sex",
          align: "center",
          width: 80,
        },
        {
          title: "地址",
          key: "address",
          align: "center",
          minWidth: 250,
        },
        {
          title: "部门",
          key: "deptid",
          align: "center",
          width: 180,
          render: (h, params) => {
            return h("div", this.convectCascader(params.row.deptid)
            )
          }
        },
        {
          title: "身份角色",
          key: "rolename",
          align: "center",
          minWidth: 300,
        },
        {
          title: "启禁用",
          key: "idisplay",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("div",params.row.displayornot==1?"启用":"禁用"
            )}
        },
        {
          title: "操作",
          slot: "action",
          width: 160,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.userId);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      branchId:"",
      depts:[],
    };
  },
  created() {
    this.branchId=this.$core.getUrlParam("branchId");
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    async loadModuleInfo() {
      this.$get({
        url: "/gateway/api/organization/app/list",data:{orgType:"dept",branchId:this.branchId}, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.depts = res.dataList
          }
        }
      })
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
     //级联值转换
     convectCascader(deptid,i,depts,name){
      depts=depts||this.depts;
      name=name||"";
      i=i||0;
      i=i+3;
      if(deptid.length>=i){
        let did = deptid.substring(0,i);
        let dept=depts.find(item=>item.orgCode==did);
        if(dept&&dept.orgName){
          name=(name?name+" / ":"")+dept.orgName;
          if(dept.children&&dept.children.length>0){
            name = this.convectCascader(deptid,i,dept.children,name)
          }
        }
      }
      return name;
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/gateway/api/manage/users/bmgr/list",
        data: {...this.pageParams,branchId:this.branchId},
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    deleteData(row) {
      if (this.right["D"] == false) {
        this.$Message.error({ background: true, content: "你没有删除权限" })
        return;
      }
      var ids = ""
      if (row) {
        ids = row.userId
      } else {
        let selectedList = this.$refs.fncTable.getSelection();
        if (selectedList && selectedList.length > 0) {
          ids = selectedList.map(item => {
            return item.userId;
          }).join(",")
        }
      }
      if (!ids) {
        this.$Message.error({ background: true, content: "并没有选择删除项" })
        return;
      }

      this.$Modal.confirm({
        title: '删除确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.realname + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/gateway/api/manage/users/bmgr/delete", data: { ids: ids,branchId:this.branchId },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(id) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    }
  }
};
</script>
    
<style scoped lang='less'></style>