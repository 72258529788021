import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baiduAk:"oR9SavpjmlmgfxOynbKdXsY3bTUt17GX",//百度AK
    portal:{},
  },
  mutations: {
    portalInfo(state,payload){
      state.portal = payload
    },
  },
  actions: {},
  modules: {},
});
