 <template>
  <!-- 样式组件 -->
  <div
    :class="
      'fnc-list-view'
    "
    v-if="!isParamComp"
  >
  <img src="/images/list.png"/>
  </div>
  <!-- 参数设置组件 -->
  <div class="fnc-list-params" v-else>
    <Form :label-width="100" ref="listForm">
       <FormItem label="透明背景">
        <RadioGroup v-model="data.isBGTransparent">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="数据来源">
        <RadioGroup v-model="data.dataFrom">
          <Radio label="api">通过API或JSON数据获取</Radio>
          <Radio label="menu">直接获取当前栏目的子栏目列表</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="API地址" v-if="data.dataFrom != 'menu'">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
       <FormItem label="响应参数互换" v-if="data.dataFrom != 'menu' && data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
          placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
           <FormItem label="JSON数据"  v-if="data.dataFrom != 'menu' && !data.api">
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="20"
          placeholder='例：[
                {
                  title:"标题",
                  thumb: "图标地址",
                  url: "跳转地址，原生功能在地址前加：original://",
                  note: "描述信息",
                  extraText: "额外信息",
                  extraThumb:"额外图标地址，与额外信息二选一",
                  hasBorder: 是否有边框false,
                  isSwitch: 额外信息是否开关false,
                  switchColor:"开关的颜色",
                  switchIsCheck:额外信息开关的值true,
                  switchChangeApi: "额外信息开关点击调用接口",
                  arrow:是否带箭头方向true,
                  disabled: 是否禁用false
                }
              ]'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button
          style="margin: 5px"
          type="info"
          @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import List from "./components/List";
 * 列表组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "List",
        api: "",
        data: null,
        dataFrom: "api",
      },
      dfData: JSON.stringify(
        [
          {
            title:"标题",
            thumb: "图标地址",
            url: "跳转地址",
            note: "描述信息",
            extraText: "额外信息",
            extraThumb:"额外图标地址，与额外信息二选一",
            hasBorder: false,//是否有边框
            isSwitch: false,//额外信息是否开关
            switchColor:"开关的颜色",
            switchIsCheck:true,//额外信息开关的值
            switchChangeApi: "额外信息开关点击调用接口",
            arrow:true,//是否带箭头方向
            disabled: false//是否禁用
          }
        ],
        null,
        "\t"
      ),
    };
  },
  mounted() {
    this.handleData();
  },
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    handleData() {
      
    },
   verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.fnc-list-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img{
    width: 100%;
  }
}
</style>