<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
     
      <FormItem label="标题" prop="title">
        <Input type="text" v-model="formItems.title" placeholder="200字以内"></Input>
      </FormItem>
   
      <FormItem label="简述" prop="info">
        <Input type="textarea" rows="10" v-model="formItems.info"></Input>
      </FormItem>
      <FormItem label="联系人" prop="linkman" >
        <Input type="text" v-model="formItems.linkman"></Input>
      </FormItem>
      <FormItem label="联系电话" prop="mobile" >
        <Input type="text" v-model="formItems.mobile"></Input>
      </FormItem>
      <FormItem label="参与人数" prop="persons" >
        <Input type="text" v-model="formItems.persons"></Input>
      </FormItem>
      <FormItem label="开始时间" prop="startTime">
        <DatePicker :value='formItems.startTime' @on-change="formItems.startTime=$event"  type="datetime" format="yyyy-MM-dd HH:mm" placeholder="活动开始时间"
          style="width: 200px" />
      </FormItem>

      <FormItem label="结束时间" prop="endTime">
        <DatePicker :value='formItems.endTime' @on-change="formItems.endTime=$event" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="活动结束时间"
          style="width: 200px" />
      </FormItem>
      <FormItem label="地址" prop="address" >
        <Input type="text" v-model="formItems.address"></Input>
      </FormItem>
      <FormItem label="经纬度" prop="lng">
        <Input v-model="formItems.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
        <Input v-model="formItems.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
        <Button @click="mapFn">地图选点</Button>
      </FormItem>
      <FormItem label="图片封面" prop="poster">
        <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.poster" :showDel="true"
          @success="upLogoImage">
        </FNCUpload>
      </FormItem>
      <FormItem label="启禁用" prop="idisplay">
        <RadioGroup v-model="formItems.idisplay">
          <Radio :label="1">启用</Radio>
          <Radio :label="0">禁用</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
    <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
                <template v-slot:contentarea>
                    <div style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;"><Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button type="primary" @click="setPlace(keyword)">查询</Button></div>
                    <baidu-map
                        :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter"
                        :zoom="zoom"
                        @ready="handler"
                        :ak="$store.state.baiduAk"
                        :clicking="true"
                        @click="clickMap"
                        :scroll-wheel-zoom="true"
                    >
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
                    </baidu-map>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="classModal">取消</Button>
                    <Button type="primary" @click="selectMap">确定</Button>
                </template>
            </FNCModal>
  </div>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';
import FNCModal from "@/components/FNCModal"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"

export default {
  components: {
    FNCUpload,FNCModal, BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
  },
  props: {

  },

  data() {
    return {
      pictureList: [],
      formItemsDefault: {
        id: 0,
        poster: "",
        title: "",
        info: '',
        idisplay: 1,
        linkman: "",
        mobile: "",
        persons: "",
        startTime: "",
        endTime: "",
        lng:"",
        lat:"",
        address:"",
      },
      formItems: {},
      ruleItems: {
        
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        info: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        lng: [
          // { required: true, message: '经纬度不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation:{},
      pageCenter:{},
      keyword:"",
      zoom: 16,
    };
  },
  mounted() {
  },
  methods: {
    loadData(id) {
      this.formItems =  JSON.parse(JSON.stringify(this.formItemsDefault));
     
      if (id) {
        this.$get({
          url: "/yunyaoyue/api/custom/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      } 
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(!this.formItems.lng||!this.formItems.lat){
            this.$Message.error({ background: true, content:  "经纬度必填" })
            return;
          }
          callback(true)
          this.$post({
            url: "/yunyaoyue/api/custom/save",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true,true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(file) {
      this.formItems.poster = file.url;
    },
     // 点击了地图
     clickMap(e) {
            this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation=point;
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
            }
        },
        mapFn() {
            this.$refs.mapModal.showModal();
            if (this.formItems.lng) {
                this.keyword="";
                this.pageCenter = { lng: this.formItems.lng, lat: this.formItems.lat }
                this.makerCenter(this.pageCenter)
            } else if (this.formItems.address) {
                this.keyword=this.formItems.address
                this.setPlace(this.formItems.address)
            }
        },
        classModal(){
          this.$refs.mapModal.hideModal();
        },
        selectMap() {
            this.formItems.lng=this.selectedLocation.lng
            this.formItems.lat=this.selectedLocation.lat
            this.$refs.mapModal.hideModal();
        },
        setPlace(myValue) {
           if(!myValue) return;
            var that = this
            var local = new this.BMap.LocalSearch(this.map, {
                onSearchComplete: () => {
                    try{
                    var pp = local.getResults().getPoi(0).point
                    that.pageCenter = { lng: pp.lng, lat: pp.lat }
                    that.makerCenter(pp)
                    }catch(e){}
                },
            })
            local.search(myValue)
        },
  }
};
</script>
    
<style scoped lang='less'></style>