<template>
  <div class="middleground-main">
    <div class="middleground">
      <div class="header">
        <div class="header-col-1">
          <div class="header-col-4">实时监测：{{ nowdate }}</div>
        </div>
        <div class="header-col-1">
          <div class="header-col-3">
            <div class="header-col-3-title">{{ isFull ? title : "" }}</div>

          </div>

        </div>
        <div class="header-col-1">
          <div class="header-col-5">
            <Icon :type="maxBtn ? 'md-contract' : 'md-expand'" size="30" @click="fullscreenHandle"
              style="cursor:pointer;" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-items">
          <div class="content-item">
            <div class="content-item-title">实时服务工单</div>
            <Carousel class="content-item-carousel content-item-carousel-content" autoplay loop :autoplay-speed="10000"
              arrow="never" dots="none">
              <CarouselItem v-for="i in Math.ceil(repairRecord.length / 10)" :key="'order'+i">

                <List>

                  <ListItem :class="'ivulistitem-' + (index % 2 + 1)"
                    v-for="(item, index) in repairRecord.slice((i - 1) * 10, i * 10)" :key="'order'+i+'-'+index">
                    <ListItemMeta>
                      <template #avatar></template>
                      <template #title>
                        <Icon type="ios-radio-button-on"
                          :class="item.status==1?'ivulistitem-wram':'ivulistitem-green'" />
                        <b> [{{ item.orderType=='2'?"购买":"维修" }}] {{ item.brandName }}</b>
                      </template>
                      <template #description>
                          <div v-if="item.imei"><b>IMEI：</b>{{ item.imei }}</div>
                          <div><b>时间：</b>{{ item.createTime }}</div>
                      </template>
                   
                    </ListItemMeta>
                    <template #action>
                      <!-- <li><div :class="item.status==1?'items-content-online':'items-content-offline'" @click="gotoOrder">
                           {{ checkOrderStatus(item.status) }}
                          </div></li> -->

                          <li><div :class="item.status==1?'items-content-online':'items-content-offline'"  @click="()=>{gotoOrderDetail(item.id)}">
                         {{ checkOrderStatus(item.status) }}
                          </div></li>
                    </template>
                  </ListItem>

                </List>
              </CarouselItem>
            </Carousel>
            <div class="content-charts-title">7天工单数汇总</div>
            <div id="eCharts1" class="eCharts-small"></div>
          </div>
        </div>
        <div class="content-items content-items-center">
          <div class="content-item content-item-center">
            <div class="center-total">

              <div style="cursor: pointer;" @click="gotoDevicestocks">
                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="75"
                stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#2db7f5">
                <div>
                  <h2 class="i-circle-name"> {{ totalData.deviceCount }}</h2>
                  <p class="i-circle-name">设备总数</p>
                </div>
              </i-circle>
              </div>
              <div style="cursor: pointer;" @click="gotoDevicestocks">
                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="60"
                stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#19be6b">
                <h2 class="i-circle-name"> {{ totalData.deviceBindCount }}</h2>
                <p class="i-circle-name">设备绑定数</p>
                </i-circle>
              </div>
              <div style="cursor: pointer;" @click="()=>{gotoDevicepush('')}">
                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="80"
                stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#ff9900">
                <h2 class="i-circle-name"> {{ totalData.warnCount }}</h2>
                <p class="i-circle-name">报警总数</p>
              </i-circle>
              </div>
            
              <div style="cursor: pointer;" @click="gotoOrder">
                <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="50"
                stroke-linecap="round" trail-color="#c5c8ce" stroke-color="#ed4014">
                <h2 class="i-circle-name"> {{ totalData.orderCount }}</h2>
                <p class="i-circle-name">工单总数</p>
              </i-circle>
              </div>
             

            </div>
            <baidu-map :clicking="true" :dragging="true" :inertial-dragging="true" class="baidu-map-view"
              :scroll-wheel-zoom="true" :center="center" :zoom="zoom" :ak="$store.state.baiduAk" @ready="handler"
              @zoomend="moveend" @moveend="moveend" @touchmove="() => {
                map.enableDragging()
              }
                " @touchend="() => {
    map.disableDragging()
  }
    ">

              <bm-overlay pane="labelPane" class="marker-div" :style="activeed.id == marker.id ? 'z-index:1' : ''"
                v-for="(marker, index) in pointsArr" :key="'map'+index" @draw="({ el, BMap, map }) => {
                  draw(el, BMap, map, marker)
                }
                  ">
                <div class="marker-name">{{marker.deviceName+"（"+ marker.imei+"）"}}</div>
                <img :class="activeed.id == marker.id ? 'marker-img-active' : 'marker-img'"
                  :src="checkStatus(marker) ? './images/map-overlay-warm.png' : './images/map-overlay.png'"
                  @tap="infoWindowOpen(marker)" />
              </bm-overlay>
            </baidu-map>
          </div>
        </div>
        <div class="content-items">
          <div class="content-item">
            <div class="content-item-title">实时报警记录</div>
            <Carousel class="content-item-carousel content-item-carousel-content" autoplay loop :autoplay-speed="11000"
              arrow="never" dots="none">
              <CarouselItem v-for="i in Math.ceil(warmRecord.length / 5)" :key="'warm'+i">
                <List>

                  <ListItem :class="'ivulistitem-' + (index % 2 + 1)"
                    v-for="(item, index) in warmRecord.slice((i - 1) * 5, i * 5)"  :key="'warm'+i+'-'+index">
                    <ListItemMeta>
                        <template #avatar></template>
                      <template #title>
                        <Icon type="ios-radio-button-on"
                          :class="'ivulistitem-' + (!item.faultStatus && !item.dismantleStatus && !item.warnStatus && !item.lowbatteryStatus ? 'green' : 'wram')" />
                        <b> {{ item.deviceName }}</b>
                      </template>
                      <template #description>
                        <div style="cursor: pointer;" @click="()=>{gotoDevicepush(item.imei)}">

                        <div style="clear:both;">
                          <div style="float:left;"><b>IMEI：</b>{{ item.imei }}</div>
                          <div style="float:left;margin-left:20px;"><b>时间：</b>{{ item.pushTime }}</div>
                        </div>
                        <div class="custom-foot-items-state ">
                          <div class="items-content-online" v-if="item.faultStatus == 1">
                            故障
                          </div>
                          <div class="items-content-online" v-if="item.dismantleStatus == 1">
                            拆卸
                          </div>
                          <div class="items-content-online" v-if="item.warnStatus == 1">
                            报警
                          </div>
                          <div class="items-content-online" v-if="item.lowbatteryStatus == 1">
                            电量低
                          </div>
                          <div class="items-content-offline"
                            v-if="!item.faultStatus && !item.dismantleStatus && !item.warnStatus && !item.lowbatteryStatus">
                            恢复正常
                          </div>
                        </div>

                      </div>
                      </template>
                    </ListItemMeta>

                  </ListItem>

                </List>
              </CarouselItem>

            </Carousel>
            <div class="content-charts-title">7天报警数汇总</div>
            <div id="eCharts2" class="eCharts-small"></div>

          </div>

        </div>
      </div>
    </div>
    
  <FNCModal ref="editModal" title="工单详情" :fullscreen="false" width="800px" height="600px">
    <template v-slot:contentarea>
    <Form ref="form"   :label-width="150">
      <FormItem label="品牌名称" prop="brandName">
        <label> {{ formItems.brandName }}</label>
      </FormItem>
      <FormItem label="申请时间" prop="createTime">
        <label>{{ formItems.createTime }}</label>
      </FormItem>
      <FormItem label="类型" prop="orderType">
        <label>{{ formItems.orderTypeName }}</label>
      </FormItem>
      <FormItem label="工单备注" prop="orderRemark">
        <label>{{ formItems.orderRemark }}</label>
      </FormItem>
      <FormItem label="工单状态" prop="status">
        <Select v-model="formItems.status">
          <Option v-for="item in orderStatus" :value="item.value" :key="'status'+item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="处理备注" prop="serviceRemark">
        <Input  type="textarea" v-model="formItems.serviceRemark" placeholder="输入处理备注" rows="6"></Input>
      </FormItem>
    </Form>
    </template>
    <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
    </template>
  </FNCModal>

  </div>
</template>

<script>
//路由定义：@ROUTER=/smokemiddleground

import FNCModal from "@/components/FNCModal";
import * as echarts from 'echarts';
import { BaiduMap, BmMarker, BmInfoWindow, BmLocalSearch, BmView, BmPointCollection, BmGeolocation, BmOverlay } from "vue-baidu-map"
export default {
  components: {
    BaiduMap, BmMarker, BmInfoWindow, BmLocalSearch, BmView, BmPointCollection, BmGeolocation, BmOverlay,
    FNCModal,
  },

  data() {
    return {
      title: "智慧烟感数据中台",
      center: { lat: 23.112223, lng: 113.331084 },
      BMap: null,
      map: null,
      userLocation: {},
      zoom: 14,
      activeed: {},
      dataList: [],
      pointsArr: [],
      maxBtn: false,
      nowdate: "",
      repairRecord: [],
      warmRecord: [],
      warm_i: 0,
      totalData: {},
      objectCoverData: { i: 0, currObjectName: "", dataList: [] },
      orderStatus: [],
      dataSmokeList: [],
      isFull: false,

      saveLoading: false,
      formItemsDefault: JSON.stringify({
        id: null,
        orderType:"",
        orderRemark:"",
        status:"",
        branchId:null,
        orderTypeName:"",
      }),
      formItems: {},
      ruleItems: {
        // orderType: [
        //   { required: true, message: '类型不能为空', trigger: 'change' }
        // ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      orderTypes: [],

    };
  },
  created() {
    // $(document).attr("title", this.title);
    setInterval(() => {
      this.nowdate = this.$core.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
    }, 1000);
    this.loadBaseData();
  },
  mounted() {
    this.loadTimeOutData();
    this.queryMapDeviceShow();
  },
  destroyed() {
  },
  methods: {
    fullscreenHandle() {
      this.toggleFullScreen();
      this.maxBtn = !this.maxBtn;
    },
    loadBaseData() {
      this.$core.getStyleParamList("smartsmoke_orderStatus", false, "", (res) => {
        this.orderStatus = res || []
      })
      this.$core.getStyleParamList("smartsmoke_orderType", false, null, (res) => {
        this.orderTypes = res || []
      })
    },
    loadTimeOutData() {
      this.loadData();
      setTimeout(this.loadTimeOutData, 60 * 1000);
    },
    loadData() {
      this.$get({
        url: "/smartsmoke/statistics/bigScreenStatistics", data: { pageSize: 30 }, success: (res) => {
          if (res.code == "200" && res.data) {
            this.totalData = res.data;
            this.warmRecord = res.data.deviceRecordList;
            this.repairRecord = res.data.orderList;
            this.dataSmokeList = res.data.deviceAddressList;
            setTimeout(() => {
              if (res.data.orderWeek) {
                var xAxis = [];
                var data = [];
                res.data.orderWeek && res.data.orderWeek.map(ss => {
                  xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                  data.push(ss.num)
                })
                this.createChart('eCharts1', "line", { xAxis: xAxis, data: data }, 0)
              }
              if (res.data.deviceRecordWeek) {
                var xAxis = [];
                var data = [];
                res.data.deviceRecordWeek && res.data.deviceRecordWeek.map(ss => {
                  xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                  data.push(ss.num)
                })
                this.createChart('eCharts2', "line", { xAxis: xAxis, data: data }, 0)
              }

            }, 1000);
          }
        }
      })
    },
    queryMapDeviceShow() {
      if (this.dataSmokeList.length > 0) {
        var i = this.warm_i;
        if (i > this.dataSmokeList.length - 1) i = 0;
        var cover = this.dataSmokeList[i];
        this.center = { lat: cover.lat, lng: cover.lng };
        this.activeed = { ...cover };
        this.warm_i = i + 1;
        setTimeout(this.queryMapDeviceShow, 10000);
      } else
        setTimeout(this.queryMapDeviceShow, 1000);
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },

    createChart(id, type, data, showType, param) {
      let ec = echarts.init(document.getElementById(id))
      let option = {
        grid: {
          top: 20,
          bottom: 40,
          left: 40,
          right: 20, ...param && param.grid
        },
        xAxis: {
          type: showType == 1 ? 'value' : "category",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0,
            lineStyle: { color: "#888888" }, ...param && param.xAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#333333',
              width: showType == 1 ? 1 : 0,
            },
          },
          data: data.xAxis,
        },
        yAxis: {
          type: showType == 1 ? 'category' : "value",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0, ...param && param.yAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {

              color: '#888888',
              width: showType == 1 ? 0 : 1,
            },
          },
          data: data.xAxis,

        },
        series: [
          {
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#ed4014'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#ed4014'
                },
                {
                  offset: 1,
                  color: '#ff9900'
                }
              ])
            },
            data: data.data,
            smooth: true,
            type: type
          }
        ]
      };
      ec.setOption(option);
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      // let mapStyle = { style: "midnight" };
      // this.map.setMapStyle(mapStyle);
      // this.getCurlocation()
    },
    getCurlocation() {
      //通过原生获取定位
      var _this = this
      if (!this.BMap) return false
      this.center = { lat: this.center.lat + 0.00001, lng: this.center.lng + 0.00001 }
      // 获取浏览器当前定位
      let BMap = this.BMap
      let geolocation = new BMap.Geolocation()

      geolocation.getCurrentPosition(function (r) {
        _this.center = {
          lng: r.point.lng,
          lat: r.point.lat,
        }
        _this.userLocation = {
          longitude: r.point.lng,
          latitude: r.point.lat,
        }
        var iframe = _this.$("iframe")
        iframe.map(item => {
          if (iframe[item].src.indexOf("api.map.baidu.com/res/staticPages/location.html") > 0) {
            iframe[item].remove()
          }
        })
      })
    },
    draw(el, BMap, map, marker) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(marker.lng, marker.lat))
      el.style.left = pixel.x + "px"
      el.style.top = pixel.y + "px"
    },
    moveend(e) {
      if (e && e.target) this.zoom = e.target.getZoom()
      if (this.map) {
        var bounds = this.map.getBounds() //获取地图可视区域
        var sw = bounds.getSouthWest() //获取西南角的经纬度(左下端点)
        var ne = bounds.getNorthEast() //获取东北角的经纬度(右上端点)
        if (this.dataSmokeList) {
          var list = []
          this.dataSmokeList.map(item => {
            // console.log(item, sw, ne)
            try {
              if (Number(item.lng) >= sw.lng && Number(item.lat) >= sw.lat && Number(item.lng) <= ne.lng && Number(item.lat) <= ne.lat) {
                list.push(item)
                // console.log(item);
              }
            } catch (e) { }
          })
          this.pointsArr = []
          this.$nextTick(() => {
            this.pointsArr = list
          })
        }
      }
    },
    // 在全屏与非全屏之间来回切换
    toggleFullScreen(d) {
      if (this.isFullScreen()) {
        this.isFull = false;
        this.exitFullScreen()
      } else {
        this.isFull = true;
        this.fullScreen();
      }
    },

    /**实现F11全屏效果*/
    fullScreen() {
      if (this.isFullScreen()) return;
      var docElm = document.documentElement;
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }/*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }/*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }/*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    },
    /**退出F11全屏*/
    exitFullScreen() {
      if (!this.isFullScreen()) return;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    /**判断是否全屏*/
    isFullScreen() {
      return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false;
    },
    /**判断全屏模式是否是可用*/
    isFullscreenEnabled() {
      return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || false;
    },
    /**判断整个页面被一个标签铺满*/
    isFullscreenForNoScroll() {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf("chrome") > -1) {/*webkit*/
        return (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width);
      } else {/*IE 9+  fireFox*/
        return (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width);
      }
    },
    /**实现局部div、dom元素全屏*/
    fullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var rfs = sel.requestFullScreen || sel.webkitRequestFullScreen || sel.mozRequestFullScreen || sel.msRequestFullScreen, wscript;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(sel);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitFullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var el = document, cfs = sel.cancelFullScreen || sel.webkitCancelFullScreen || sel.mozCancelFullScreen || sel.exitFullScreen, wscript;
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    checkStatus(item) {
      if((new Date()).getTime>item.heartbeatTime+24*60*60*1000){
        return false
      }
      if(item.faultStatus&&!item.dismantleStatus&&!item.warnStatus&&!item.lowbatteryStatus){
        return false
      }else{
        return true
      }
    },
    checkOrderStatus(value) {
      var object = this.orderStatus.find(item => value == item.value)
      return object ? object.name : "无"
    },
    gotoOrder(){
      top.vue.goto("/serviceorder?functionid=0503")
    },
    gotoOrderDetail(id){
      console.log(111,id);
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {

        this.$get({
          url: "/smartsmoke/serviceorder/queryServiceorderDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.formItems.status = res.data.status + "";

              if(this.formItems.orderType == '1') {
                this.formItems.orderTypeName = '维修';
              } else if(this.formItems.orderType == '2') {
                this.formItems.orderTypeName = '新购';
              }
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }

      this.$refs.editModal.showModal();

    },
    gotoDevicepush(imei) {
      top.vue.goto("/devicepush?functionid=0504&keyword="+imei)
    },
    gotoDevicestocks() {
      top.vue.goto("/devicestocks?functionid=0502")
    },

    closeModal() {
      this.$refs.editModal.hideModal();
    },


    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$post({
            url: "/smartsmoke/serviceorder/createOrUpdateServiceorder",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: "保存成功" })
                this.closeModal();
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {

            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },

  }
};
</script>
    
<style scoped lang='less'>
.middleground-main {
  width: 100%;
  height: 100vh;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  .middleground {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      position: fixed;
      z-index: 11;
      width: 100%;
      height: 66px;
      background-color: #eef9ff;
      box-shadow: inset 0px -7px 7px -7px #99caff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-col-1 {
        padding: 0 20px;
        display: flex;
        font-size: 18px;
      }

      .header-col-2 {}

      .header-col-3 {
        font-size: 30px;
        padding: 10px 20px;
        letter-spacing: 0.3em;
        display: flex;
        text-align: center;
        flex-direction: column;

        .header-col-3-title {
          height: 45px;
        }

        .header-col-3-img {
          position: relative;
          margin-bottom: -41px;
        }
      }

      .header-col-4 {
        width: 300px;
      }

      .header-col-5 {
        width: 300px;
        text-align: right;
      }
    }

    .content {
      margin-top: 60px;
      width: 100%;
      height: calc(100% - 105px);
      min-height: 830px;

      .content-items {
        width: 450px;
        height: 100%;
        margin: 30px 10px 0 10px;
        float: left;
        display: flex;
        flex-direction: column;

        .content-item {
          margin: 18px 0;
          width: 100%;
          height: 100%;
          background-color: #f4faff;
          color: #333;
          box-shadow: inset 0px 0px 7px 0px #99caff;
          border-radius: 5px;

          .content-item-icon {
            position: relative;
            margin-top: -32px;
            z-index: 0;
          }

          .content-charts-title {

            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
            font-size: 16px;
            color: #2d8cf0;
            padding: 5px 0;
            font-weight: bold;
          }

          .content-item-title {
            width: 150px;
            text-align: center;
            font-weight: bold;
            background-color: #2d8cf0;
            color: #fff;
            padding: 6px;
            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
            border-radius: 5px;
          }

          .content-item-carousel {
            width: calc(100% -40px);
            margin: 10px 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .ivulistitem-1 {
              background-color: #e2ebf4;
              padding: 10px 10px;
              color: #333;
              border-radius: 4px;
            }

            .ivulistitem-2 {
              background-color: #eff8ff;
              padding: 10px 10px;
              color: #333;
              border-radius: 4px;
              margin: 2px 0;
            }

            .ivulistitem-wram {
              font-size: 18px;
              color: #ff451a;
              padding-right: 10px;
            }

            .ivulistitem-green {
              font-size: 18px;
              color: #00d2a4;
              padding-right: 10px;
            }

            /deep/.ivu-list-split .ivu-list-item {
              border-bottom: 0px;
            }

            /deep/.ivu-list-item-meta-description {

              color: #333;
              font-size: 14px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-meta-title {
              font-weight: 500;
              margin-bottom: 4px;
              color: #333;
              font-size: 16px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-action>li {
              position: relative;
              display: inline-block;
              padding: 0 8px;
              color: #333;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              cursor: pointer;
            }
          }

          .content-item-carousel-small {
            height: 280px;
          }

          .content-item-carousel-content {
            height: calc(100% - 280px);
          }
        }

        .content-item-center {
          width: 100%;
          height: 952px;

          .center-total {
            margin: 10px 0;
            width: 100%;
            display: flex;
            justify-content: center;

            .i-circle {
              margin: 20px;

              .i-circle-name {
                line-height: 1.8em;
              }
            }
          }
        }
      }

      .content-items-center {
        width: calc(100% - 960px);
      }
    }

    .eCharts-small {
      width: 100%;
      height: 230px;
      position: relative;
      top: 0px;
    }

    .eCharts-big {
      width: 100%;
      height: 440px;
      position: relative;
    }
  }

  .baidu-map-view {
    height: calc(100% - 250px);
    width: calc(100% - 20px);
    margin: 10px;
    z-index: 1;
    border-radius: 10px;

    .marker-div {
      position: absolute;
      width: 0px;
      height: 0px;

      .marker-name {
        position: absolute;
        color: #fff;
        background: #2b85e4;
        border-radius: 5px;
        padding: 2px 5px;
        white-space: nowrap;
        font-size: 12px;
      }

      .marker-img {
        width: 40px;
        height: 40px;
        transition: all 0.4s;
        position: relative;
        left: -20px;
        top: -40px;
      }

      .marker-img-active {
        opacity: 0.99;
        width: 65px;
        height: 65px;
        transition: all 0.4s;
        position: relative;
        left: -32.5px;
        top: -65px;
      }
    }


  }

  .items-content-online {
    float: right;
    color: #fff;
    background-color: #FD0000;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
    margin-left: 3px;
  }

  .items-content-offline {
    float: right;
    color: #ffffff;
    background-color: #00C2B1;
    ;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
    margin-left: 3px;
  }

  .custom-foot-items-state {
    clear: both;
    font-size: 14px;
    color: #888;
    padding-top: 6px;
  }


}

/deep/.BMap_cpyCtrl {
  display: none !important;
}

/deep/.anchorBL {
  display: none !important;
}</style>