 <template>
  <!-- 样式组件 -->
  <div
    :class="
      'fnc-info-view'
    "
    v-if="!isParamComp"
  >
  <img src="/images/infoview.png"/>
  </div>
  <!-- 参数设置组件 -->
  <div class="fnc-info-params" v-else>
    <Form :label-width="120" ref="infoForm">
      <FormItem label="显示标题">
        <RadioGroup v-model="data.showTitle">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="标签栏搜索">
        <RadioGroup v-model="data.isLFTabs">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
        <FormItem label="绑定标签栏名称" v-if="data.isLFTabs">
        <Input
          placeholder="注：从对应的标签栏组件设置中找到"
          v-model.trim="data.LFTabsName"
        ></Input>
      </FormItem>
      <FormItem label="搜索字段名" v-if="data.isLFTabs">
        <Input
          placeholder="注：API中参与标签栏搜索的字段名"
          v-model.trim="data.lftabsKeyword"
        ></Input>
      </FormItem>
      <FormItem label="API地址">
        <Input
          v-model.trim="data.api"
          placeholder="api接口"
          type="textarea"
          :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}，可读取缓存或地址传参</div>
      </FormItem>
      <FormItem label="响应参数互换">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
         placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import InforView from "./components/InforView";
 * 信息详情组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "InfoView",
        api: "",
        data: null,
        isLFTabs: false,    // 是否需要标签页搜索
        lftabsKeyWord: '',  // 标签页关键字
        LFTabsName:"",
        showTitle:true,
        convertParams:""
      },
    };
  },
  mounted() {
    this.handleData();
  },
  methods: {
   
    handleData() {
      
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.fnc-info-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img{
    width: 100%;
  }
}
</style>