<template>
  <FNCContent>
    <template v-slot:title> 租户与平台设置 </template>
    <template v-slot:toolsbarLeft>

    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="() => { editData() }">
        <Icon type="md-add-circle" />添加
      </Button>
    </template>
    <template v-slot:contentArea>
      <FNCTable :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" title="编辑窗" width="800px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/platformsetting
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      talbeColumns: [
        {
          title: "系统名称",
          key: "systemName",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    color: "#2b85e4",
                  },
                  domProps: {
                    title: params.row.systemName,
                  },
                  on: {
                    click: (e) => {
                      this.editData(params.row.clientId);
                      e.stopPropagation();
                    },
                  },
                },
                params.row.systemName
              ),
            ]);
          },
        }, {
          title: "绑定的域名",
          key: "domain",
          align: "center",
          width: 250,
          render: (h, params) => {
            var domain = params.row.domain || [];
            var rd = [];
            domain.map(item => {
              if (item) {
                let r = h(
                  "p",
                  {
                    style: {
                      display: "inline-block",
                      width: "100%",
                    },
                  },
                  item
                )
                rd.push(r)
              }
            })
            return h(
              "div",
              {
                style:{
                  width:"100%",
                  textAlign:"left",
                }
              },
              rd
            )
          },
        },
        {
          title: "ClientID",
          key: "clientId",
          align: "center",
          width: 180,
        },
        {
          title: "Scope",
          key: "scope",
          width: 100,
          align: "center",
        },
        {
          title: "GrantType",
          key: "grantType",
          width: 120,
          align: "center",
        },
        {
          title: "启禁用",
          key: "idisplay",
          align: "center",
          width: 80,
          render: (h, params) => {
            return params.row.locked == 1 ? null : h("div",
              {
                class: "passedDiv"
              },
              [
                h(
                  "i-switch",
                  {
                    props: {
                      value: params.row.idisplay == 1 ? true : false,
                      "before-change": () => {
                        return new Promise((resolve) => {
                          this.passedData(params.row, resolve);
                        });
                      }
                    },
                    on: {
                      "on-change": (e) => {
                        this.tableData[params.index].idisplay = e ? 1 : 0;
                      }
                    },
                  }, [
                  h('span', { slot: 'open' }, '启'),
                  h('span', { slot: 'close' }, '禁'),
                ]
                ),

              ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          render: (h, params) => {
            return params.row.locked == 1 ? null : h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.clientId);
                      },
                    },
                  },
                  "修改"
                ),

                h(
                  "Button",
                  {
                    class: "deleteDiv"
                    ,
                    props: {
                      type: "error",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.deleteData(params.row);
                      },
                    },
                  },
                  "删除"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
    };
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/gateway/api/manage/platform/list", data: this.pageParams, success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = res.pageParams
            this.tableData = res.dataList
            this.$nextTick(() => {
              this.$core.checkControlButtonRight(
                [{ btn: this.$(".editDiv"), right: "E" },
                { btn: this.$(".deleteDiv"), right: "D" },
                { btn: this.$(".addDiv"), right: "A" },
                  // { btn: this.$(".passedDiv"), right: "P" }
                ]
              ).then(res => {
                this.right = res;
              });
            })
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    passedData(row, resolve) {
      if (this.right["P"] == false) {
        this.$Message.error({ background: true, content: "你没有启禁用权限" })
        return;
      }
      this.$Modal.confirm({
        title: '启禁确认',
        content: '您确认要<span style="color:red"> ' + (row.idisplay == 1 ? '禁用' : '启用') + " </span>" + row.systemName + '吗？',
        onOk: () => {
          this.$post({
            url: "/gateway/api/manage/platform/passed", data: { ids: row.clientId },
            success: (res) => {
              if (res.code == "200") {
                if (resolve) resolve();
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    deleteData(row) {
      this.$Modal.confirm({
        title: '删除确认',
        content: '您确认要<span style="color:red"> ' + row.systemName + " </span>吗？",
        onOk: () => {
          this.$post({
            url: "/gateway/api/manage/platform/delete", data: { ids: row.clientId },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: res.desc })
                this.loadData(1);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },
    editData(clientId) {
      if (clientId && this.right["E"] == true || !clientId && this.right["A"] == true) {
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(clientId);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    }
  }
};
</script>
    
<style scoped lang='less'></style>