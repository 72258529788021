<template>
  <div style="">
    <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
      <FormItem label="设备编号" prop="deviceNum">
        <Input type="text" v-model="formItems.deviceNum" placeholder="输入设备编号"></Input>
      </FormItem>
      <FormItem label="设备名称" prop="deviceName">
        <Input type="text" v-model="formItems.deviceName" placeholder="输入设备名称"></Input>
      </FormItem>
      <FormItem label="长" prop="lengthSize">
        <Input type="text" v-model="formItems.lengthSize" placeholder="输入长度：cm"></Input>
      </FormItem>
      <FormItem label="宽" prop="widthSize">
        <Input type="text" v-model="formItems.widthSize" placeholder="输入宽度：cm"></Input>
      </FormItem>
      <FormItem label="厚" prop="thickSize">
        <Input type="text" v-model="formItems.thickSize" placeholder="输入厚度：cm"></Input>
      </FormItem>
      <FormItem label="材质" prop="material">
        <Select v-model="formItems.material" style="width: 180px">
          <Option v-for="item in materials" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="井盖形状" prop="manholeCoverType">
        <Select v-model="formItems.manholeCoverType" style="width: 180px">
          <Option v-for="item in manholeCoverTypes" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="井盖类型" prop="manholeCoverModel">
        <Select v-model="formItems.manholeCoverModel" style="width: 180px">
          <Option v-for="item in manholeCoverModels" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="信号传输方式" prop="manholeCoverSendModel">
        <Select v-model="formItems.manholeCoverSendModel" style="width: 180px">
          <Option v-for="item in manholeCoverSendModels" :value="item.value" :key="item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="是否支持定位" prop="locationTag">
        <RadioGroup v-model="formItems.locationTag">
            <Radio :label="true">
               支持
            </Radio>
            <Radio :label="false">
             不支持
            </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="使用年限" prop="useTime">
        <Input type="text" v-model="formItems.useTime" placeholder="输入使用年限"></Input>
      </FormItem>
      <FormItem label="设备品牌名称" prop="branchName">
        <Input type="text" v-model="formItems.branchName" placeholder="输入设备品牌名称" style="width: 180px"></Input>
      </FormItem>
      <FormItem label="供应商" prop="applyMerchantNum">
        <Input type="text" v-model="formItems.applyMerchantNum" placeholder="输入供应商" style="width: 180px"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import FNCModal from "@/components/FNCModal"
// import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
export default {
  components: {
    FNCModal, 
    // BaiduMap,
    // BmMarker,
    // BmView,
    // BmNavigation,
  },
  data() {
    return {
      formItemsDefault: JSON.stringify({
        id: 0,
        deviceNum: "",
        deviceName:"",
        lengthSize: "",
        widthSize:"",
        thickSize:"",
        material:"",
        manholeCoverType:"",
        manholeCoverModel:"",
        manholeCoverSendModel:"",
        locationTag:null,
        useTime:"",
        branchName:"",
        applyMerchantNum:"",
      }),
      formItems: {},
      ruleItems: {
        deviceNum: [
          { required: true, message: '设备编号不能为空', trigger: 'blur' }
        ],
        deviceName: [
          { required: true, message: '设备名称不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,


      materials: [],
      manholeCoverTypes: [],
      manholeCoverModels: [],
      manholeCoverSendModels: [],
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    async loadData(id) {
      this.formItems = JSON.parse(this.formItemsDefault);
      if (id) {
        this.$get({
          url: "/smartcovers/cover/queryDeviceDetail",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    /**
    * 获取选项数据
    */
    loadBaseData() {
      this.$core.getStyleParamList("material", false, null, (res) => {
        this.materials = res || []
      })
      this.$core.getStyleParamList("manholeCoverType", false, null, (res) => {
        this.manholeCoverTypes = res || []
      })
      this.$core.getStyleParamList("manholeCoverModel", false, null, (res) => {
        this.manholeCoverModels = res || []
      })
      this.$core.getStyleParamList("manholeCoverSendModel", false, null, (res) => {
        this.manholeCoverSendModels = res || []
      })
      
      // this.$get({
      //   url: "/smartcovers/cover/queryCoverDevice", data: { pageSize: 1000 }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.devices = res.dataList
      //     }
      //   }
      // })
      // this.$get({
      //   url: "/smartcovers/object/queryObject", data: { pageSize: 1000 }, success: (res) => {
      //     if (res.code == "200" && res.dataList) {
      //       this.projects = res.dataList
      //     }
      //   }
      // })
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          callback(true)
          this.$post({
            url: this.formItems.id ? "/smartcovers/cover/updateDevice" : "/smartcovers/cover/addDevice",
            data: { ...this.formItems }
            , success: (res) => {
              if (res.code == "200") {
                callback(false, true);
                this.$Message.success({ background: true, content: "保存成功" })
              } else {
                this.$Message.error({ background: true, content: res.desc || "保存出错" })
              }
            }, complete: () => {
              callback(false)
            }
          });
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    // 点击了地图
    // clickMap(e) {
    //   this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    // },
    // handler({ BMap, map }) {
    //   this.BMap = BMap
    //   this.map = map
    // },
    // makerCenter(point) {
    //   if (this.map) {
    //     this.selectedLocation = point;
    //     this.map.clearOverlays()
    //     this.map.addOverlay(new this.BMap.Marker(point))
    //   }
    // },
    // mapFn() {
    //   this.$refs.mapModal.showModal();
    //   if (this.formItems.longitude) {
    //     this.keyword = "";
    //     this.pageCenter = { lng: this.formItems.longitude, lat: this.formItems.latitude }
    //     this.makerCenter(this.pageCenter)
    //   } else if (this.formItems.address) {
    //     this.keyword = this.formItems.address
    //     this.setPlace(this.formItems.address)
    //   }
    // },
    // classModal() {
    //   this.$refs.mapModal.hideModal();
    // },
    // selectMap() {
    //   this.formItems.longitude = this.selectedLocation.lng
    //   this.formItems.latitude = this.selectedLocation.lat
    //   this.$refs.mapModal.hideModal();
    // },
    // setPlace(myValue) {
    //   if (!myValue) return;
    //   var that = this
    //   var local = new this.BMap.LocalSearch(this.map, {
    //     onSearchComplete: () => {
    //       try {
    //         var pp = local.getResults().getPoi(0).point
    //         that.pageCenter = { lng: pp.lng, lat: pp.lat }
    //         that.makerCenter(pp)
    //       } catch (e) { }
    //     },
    //   })
    //   local.search(myValue)
    // },
  }
};
</script>
    
<style scoped lang='less'></style>