<template>
  <div class="middleground-main">
    <div class="middleground">
      <div class="header">
        <div class="header-col-1">
          <div class="header-col-4">实时监测：{{ nowdate }}</div>
        </div>
        <div class="header-col-1">
          <div class="header-col-2"><img src="/images/mb_03.png" /></div>
          <div class="header-col-3">
            <div class="header-col-3-title">{{ title }}</div>
            <div class="header-col-3-img">
              <img src="/images/mb_08.png" />
            </div>
          </div>
          <div class="header-col-2"><img src="/images/mb_05.png" /></div>
        </div>
        <div class="header-col-1">
          <div class="header-col-5">
            <Icon :type="maxBtn ? 'md-contract' : 'md-expand'" size="30" @click="fullscreenHandle"
              style="cursor:pointer;" />
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-items">
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">运行实时记录</div>
            <Carousel class="content-item-carousel" autoplay loop :autoplay-speed="10000" arrow="never" dots="none">
              <CarouselItem v-for="i in Math.ceil(querySendRecord.length / 6)">
                <List>

                  <ListItem :class="'ivulistitem-' + (index % 2 + 1)"
                    v-for="(item, index) in querySendRecord.slice((i - 1) * 6, i * 6)">
                    <ListItemMeta>
                      <template #avatar></template>
                      <template #title>
                        <div style="float:left;">
                          <Icon type="ios-radio-button-on"
                            :class="'ivulistitem-' + (index % 2 == 0 ? 'wram' : 'green')" />
                          {{ item.coverName }}
                        </div>
                        <div style="float:right;">{{ item.createTime }}</div>
                      </template>
                      <template #description>
                        <div style="float:right;">
                          倾斜度：{{ item.inclined }}&nbsp;&nbsp;&nbsp;&nbsp;电量：{{ item.power }}&nbsp;&nbsp;&nbsp;&nbsp;水位：{{
                            item.waterLevel }}
                        </div>
                      </template>
                    </ListItemMeta>
                  </ListItem>
                </List>
              </CarouselItem>

            </Carousel>
          </div>
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">管线井盖数</div>
            <div id="eCharts3" class="eCharts-big"></div>
          </div>
        </div>
        <div class="content-items content-items-center">
          <div class="content-item content-item-center">
            <div class="center-total">

              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="75"
                stroke-linecap="round" stroke-color="#2db7f5">
                <div>
                  <h2 class="i-circle-name"> {{ totalData.projectTotal }}</h2>
                  <p class="i-circle-name">管线总数</p>
                </div>
              </i-circle>
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="60"
                stroke-linecap="round" stroke-color="#19be6b">
                <h2 class="i-circle-name"> {{ totalData.warmTotal }}</h2>
                <p class="i-circle-name">报警总数</p>
              </i-circle>
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="80"
                stroke-linecap="round" stroke-color="#ff9900">
                <h2 class="i-circle-name"> {{ totalData.repairTotal }}</h2>
                <p class="i-circle-name">待维修总数</p>
              </i-circle>
              <i-circle class="i-circle" :size="180" :trail-width="8" :stroke-width="10" :percent="50"
                stroke-linecap="round" stroke-color="#ed4014">
                <h2 class="i-circle-name"> {{ totalData.coverTotal }}</h2>
                <p class="i-circle-name">井盖总数</p>
              </i-circle>

            </div>
            <baidu-map :clicking="true" :dragging="true" :inertial-dragging="true" class="baidu-map-view"
              :scroll-wheel-zoom="true" :center="center" :zoom="zoom" :ak="$store.state.baiduAk" @ready="handler"
              @zoomend="moveend" @moveend="moveend" @touchmove="() => {
                map.enableDragging()
              }
                " @touchend="() => {
    map.disableDragging()
  }
    ">

              <bm-overlay pane="labelPane" class="marker-div" :style="activeed.id == marker.id ? 'z-index:1' : ''"
                v-for="(marker, index) in pointsArr" :key="index" @draw="({ el, BMap, map }) => {
                  draw(el, BMap, map, marker)
                }
                  ">
                <div class="marker-name">{{ marker.coverName }}</div>
                <img :class="activeed.id == marker.id ? 'marker-img-active' : 'marker-img'"
                  :src="marker.manholeCoverStatus == 'warm' || marker.manholeCoverStatus == 'repair' ? './images/map-overlay-warm.png' : './images/map-overlay.png'"
                  @tap="infoWindowOpen(marker)" />
              </bm-overlay>
              <!-- 
              <bm-overlay pane="labelPane" class="marker-div" @draw="({ el, BMap, map }) => {
                draw(el, BMap, map, userLocation)
              }
                ">
                <img :src="'/images/map-user.png'" class="marker-img">
              </bm-overlay> -->
            </baidu-map>
            <div style="margin:15px 10px;">{{ objectCoverData.currObjectName }}管线水位监测图</div>
            <div id="eCharts4" class="eCharts-small" style="height:200px;"></div>
          </div>
        </div>
        <div class="content-items">
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">实时报警记录</div>
            <Carousel class="content-item-carousel content-item-carousel-small" autoplay loop :autoplay-speed="11000"
              arrow="never" dots="none">
              <CarouselItem v-for="i in Math.ceil(coverWardRecord.length / 4)">
                <List>

                  <ListItem :class="'ivulistitem-' + (index % 2 + 1)"
                    v-for="(item, index) in coverWardRecord.slice((i - 1) * 4, i * 4)">
                    <ListItemMeta>
                      <template #avatar></template>
                      <template #title>
                        <Icon type="ios-radio-button-on" :class="'ivulistitem-' + (index % 2 == 0 ? 'wram' : 'green')" />
                        {{ item.coverName }}
                      </template>
                      <template #description>推送时间：{{ item.createTime }}</template>
                    </ListItemMeta>
                    <template #action>
                      <li>{{ convertObject("warmType", "value", "name", item.warmType) }}</li>
                    </template>
                  </ListItem>

                </List>
              </CarouselItem>

            </Carousel>
            <div class="content-charts-title">7天报警汇总</div>
            <div id="eCharts1" class="eCharts-small"></div>

          </div>
          <div class="content-item">
            <img src="/images/mb_12.png" class="content-item-icon" />
            <div class="content-item-title">实时维修记录</div>
            <Carousel class="content-item-carousel content-item-carousel-small" autoplay loop :autoplay-speed="12000"
              arrow="never" dots="none">
              <CarouselItem v-for="i in Math.ceil(coverRepairRecord.length / 4)">
                <List>

                  <ListItem :class="'ivulistitem-' + (index % 2 + 1)"
                    v-for="(item, index) in coverRepairRecord.slice((i - 1) * 4, i * 4)">
                    <ListItemMeta>
                      <template #avatar></template>
                      <template #title>
                        <Icon type="ios-radio-button-on" :class="'ivulistitem-' + (index % 2 == 0 ? 'wram' : 'green')" />
                        {{ item.coverName }}
                      </template>
                      <template #description>报修时间：{{ item.createTime }}</template>
                    </ListItemMeta>
                    <template #action>
                      <li>{{ convertObject("manholeCoverWarmStatus", "value", "name", item.manholeCoverWarmStatus) }}</li>
                    </template>
                  </ListItem>

                </List>
              </CarouselItem>


            </Carousel>
            <div class="content-charts-title">7天维修汇总</div>
            <div id="eCharts2" class="eCharts-small"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//路由定义：@ROUTER=/middleground
import * as echarts from 'echarts';
import { BaiduMap, BmMarker, BmInfoWindow, BmLocalSearch, BmView, BmPointCollection, BmGeolocation, BmOverlay } from "vue-baidu-map"
export default {
  components: {
    BaiduMap, BmMarker, BmInfoWindow, BmLocalSearch, BmView, BmPointCollection, BmGeolocation, BmOverlay
  },

  data() {
    return {
      title: "探霸智能井盖数据中台",
      center: { lat: 23.112223, lng: 113.331084 },
      BMap: null,
      map: null,
      userLocation: {},
      zoom: 14,
      activeed: {},
      dataList: [],
      pointsArr: [],
      maxBtn: false,
      nowdate: "",
      querySendRecord: [],
      coverRepairRecord: [],
      coverWardRecord: [],
      coverWard_i: 0,
      totalData: {},
      objectCoverData: { i: 0, currObjectName: "", dataList: [] },
      manholeCoverWarmStatus: [],
      warmType: [],
      dataCoverList: []
    };
  },
  created() {
    $(document).attr("title", this.title);
    $("body").css("background", "#00133c");
    setInterval(() => {
      this.nowdate = this.$core.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
    }, 1000);
    this.loadBaseData();
  },
  mounted() {
    this.loadTimeOutData();
    this.coverWardRecordMapShow();
    this.queryObjectCoverShow();
  },
  destroyed() {
    $("body").css("background", "#ffffff");
  },
  methods: {
    fullscreenHandle() {
      this.toggleFullScreen();
      this.maxBtn = !this.maxBtn;
    },
    loadBaseData() {
      this.$core.getStyleParamList("manholeCoverWarmStatus", false, "", (res) => {
        this.manholeCoverWarmStatus = res || []
      })
      this.$core.getStyleParamList("warmType", false, "", (res) => {
        this.warmType = res || []
      })
    },
    loadTimeOutData() {
      this.loadData();
      setTimeout(this.loadTimeOutData, 60 * 1000);
    },
    loadData() {
      this.$get({
        url: "/smartcovers/statistics/bigScreenStatistics", data: {}, success: (res) => {
          if (res.code == "200" && res.data) {
            setTimeout(() => {
              if (res.data.coverWarmsWeek) {
                var xAxis = [];
                var data = [];
                res.data.coverWarmsWeek && res.data.coverWarmsWeek.map(ss => {
                  xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                  data.push(ss.num)
                })
                this.createChart('eCharts1', "line", { xAxis: xAxis, data: data }, 0)
              }
              if (res.data.coverRepairWeek) {
                var xAxis = [];
                var data = [];
                res.data.coverRepairWeek && res.data.coverRepairWeek.map(ss => {
                  xAxis.push(ss.date && ss.date.length > 5 ? ss.date.substring(5) : ss.date)
                  data.push(ss.num)
                })
                this.createChart('eCharts2', "line", { xAxis: xAxis, data: data }, 0)
              }

            }, 1000);
          }
        }
      })
      this.$get({
        url: "/smartcovers/cover/querySendRecord", data: { pageSize: 60 }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.querySendRecord = res.dataList
          }
        }
      })
      this.$get({
        url: "/smartcovers/cover/queryCoverRepairRecord", data: { pageSize: 40, functionid: "0203" }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.coverRepairRecord = res.dataList
          }
        }
      })
      this.$get({
        url: "/smartcovers/cover/queryCoverWardRecord", data: { pageSize: 40, functionid: "0202" }, success: (res) => {
          if (res.code == "200" && res.dataList) {
            this.coverWardRecord = res.dataList
          }
        }
      })
      //统计总数
      this.$get({
        url: "/smartcovers/statistics/homeStatistics",
        success: (res) => {
          if (res.code == "200") {
            this.totalData = res.data
          }
        }
      })

      this.$get({
        url: "/smartcovers/object/queryObjectCover",
        data: { pageSize: 1000 },
        success: (res) => {
          if (res.code == "200" && res.dataList) {
            var xAxis = [];
            var data = [];
            res.dataList.map(ss => {
              xAxis.push(ss.objectName)
              data.push(ss.coverStatistics ? ss.coverStatistics.length : 0)
            })
            this.createChart('eCharts3', "bar", { xAxis: xAxis, data: data }, 1, {
              grid: {
                top: 20,
                bottom: 40,
                left: 80,
                right: 20
              },
              yAxisLabel: { rotate: 0 },
            })

            this.objectCoverData.dataList = res.dataList

          }
        }
      })
      this.$get({
        url: "/smartcovers/cover/queryCover", data: { pageSize: 1000000 }, success: (res) => {
          if (res.code == "200") {
            this.dataCoverList = res.dataList
          }
        }
      })
    },
    convertObject(target, inFeild, outFeild, value) {
      var object = this[target].find(item => value == item[inFeild])
      return object ? object[outFeild] : "无"
    },
    queryObjectCoverShow() {
      if (this.objectCoverData.dataList.length > 0) {
        var i = this.objectCoverData.i;
        if (i > this.objectCoverData.dataList.length - 1) i = 0;
        var list = this.objectCoverData.dataList[i];
        var xAxis = [];
        var data = [];
        list && list.coverStatistics && list.coverStatistics.map(ss => {
          xAxis.push(ss.coverName)
          var color = ss.waterLevel >= (ss.depth || 200) - 30 ? "#ff0000" : "rgb(255, 70, 131)"
          data.push({ value: ss.waterLevel, itemStyle: { borderColor: color, color: color } })
        })
        this.objectCoverData.currObjectName = list.objectName;
        this.createChart('eCharts4', "line", { xAxis: xAxis, data: data }, 0)
        this.objectCoverData.i = i + 1;
        if (xAxis.length > 0) {
          setTimeout(this.queryObjectCoverShow, 10000);
        } else {
          setTimeout(this.queryObjectCoverShow, 100);
        }
      } else
        setTimeout(this.queryObjectCoverShow, 1000);
    },
    coverWardRecordMapShow() {
      if (this.coverWardRecord.length > 0) {
        var i = this.coverWard_i;
        if (i > this.coverWardRecord.length - 1) i = 0;
        var cover = this.coverWardRecord[i];
        this.center = { lat: cover.latitude, lng: cover.longitude };
        this.activeed = { ...cover, id: cover.coverId };
        this.coverWard_i = i + 1;
        setTimeout(this.coverWardRecordMapShow, 10000);
      } else
        setTimeout(this.coverWardRecordMapShow, 1000);
    },
    createChart(id, type, data, showType, param) {
      let ec = echarts.init(document.getElementById(id))
      let option = {
        grid: {
          top: 20,
          bottom: 40,
          left: 40,
          right: 20, ...param && param.grid
        },
        xAxis: {
          type: showType == 1 ? 'value' : "category",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0,
            lineStyle: { color: "#888888" }, ...param && param.xAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#333333',
              width: showType == 1 ? 1 : 0,
            },
          },
          data: data.xAxis,
        },
        yAxis: {
          type: showType == 1 ? 'category' : "value",
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 0, ...param && param.yAxisLabel
          },
          axisLine: {
            lineStyle: { color: "#888888" }
          },
          splitLine: {
            show: true,
            lineStyle: {

              color: '#333333',
              width: showType == 1 ? 0 : 1,
            },
          },
          data: data.xAxis,

        },
        series: [
          {
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#2db7f5'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#2db7f5'
                },
                {
                  offset: 1,
                  color: '#000a32'
                }
              ])
            },
            data: data.data,
            smooth: true,
            type: type
          }
        ]
      };
      ec.setOption(option);
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      let mapStyle = { style: "midnight" };
      this.map.setMapStyle(mapStyle);
      // this.getCurlocation()
    },
    getCurlocation() {
      //通过原生获取定位
      var _this = this
      if (!this.BMap) return false
      this.center = { lat: this.center.lat + 0.00001, lng: this.center.lng + 0.00001 }
      // 获取浏览器当前定位
      let BMap = this.BMap
      let geolocation = new BMap.Geolocation()

      geolocation.getCurrentPosition(function (r) {
        _this.center = {
          lng: r.point.lng,
          lat: r.point.lat,
        }
        _this.userLocation = {
          longitude: r.point.lng,
          latitude: r.point.lat,
        }
        var iframe = _this.$("iframe")
        iframe.map(item => {
          if (iframe[item].src.indexOf("api.map.baidu.com/res/staticPages/location.html") > 0) {
            iframe[item].remove()
          }
        })
      })
    },
    draw(el, BMap, map, marker) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(marker.longitude, marker.latitude))
      el.style.left = pixel.x + "px"
      el.style.top = pixel.y + "px"
    },
    moveend(e) {
      if (e && e.target) this.zoom = e.target.getZoom()
      if (this.map) {
        var bounds = this.map.getBounds() //获取地图可视区域
        var sw = bounds.getSouthWest() //获取西南角的经纬度(左下端点)
        var ne = bounds.getNorthEast() //获取东北角的经纬度(右上端点)
        if (this.dataCoverList) {
          var list = []
          this.dataCoverList.map(item => {
            // console.log(item, sw, ne)
            try {
              if (Number(item.longitude) >= sw.lng && Number(item.latitude) >= sw.lat && Number(item.longitude) <= ne.lng && Number(item.latitude) <= ne.lat) {
                list.push(item)
                // console.log(item);
              }
            } catch (e) { }
          })
          this.pointsArr = []
          this.$nextTick(() => {
            this.pointsArr = list
          })
        }
      }
    },
    // 在全屏与非全屏之间来回切换
    toggleFullScreen(d) {
      this.isFullScreen() ? this.exitFullScreen() : this.fullScreen();
    },

    /**实现F11全屏效果*/
    fullScreen() {
      if (this.isFullScreen()) return;
      var docElm = document.documentElement;
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }/*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }/*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }/*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    },
    /**退出F11全屏*/
    exitFullScreen() {
      if (!this.isFullScreen()) return;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    /**判断是否全屏*/
    isFullScreen() {
      return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false;
    },
    /**判断全屏模式是否是可用*/
    isFullscreenEnabled() {
      return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || false;
    },
    /**判断整个页面被一个标签铺满*/
    isFullscreenForNoScroll() {
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf("chrome") > -1) {/*webkit*/
        return (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width);
      } else {/*IE 9+  fireFox*/
        return (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width);
      }
    },
    /**实现局部div、dom元素全屏*/
    fullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var rfs = sel.requestFullScreen || sel.webkitRequestFullScreen || sel.mozRequestFullScreen || sel.msRequestFullScreen, wscript;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(sel);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    exitFullScreenForDOM(sel) {
      typeof sel == "string" && (sel = document.querySelector(sel));
        /**el是具体的dom元素*/var el = document, cfs = sel.cancelFullScreen || sel.webkitCancelFullScreen || sel.mozCancelFullScreen || sel.exitFullScreen, wscript;
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
        return;
      }
      if (typeof window.ActiveXObject != "undefined") {
        wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    }

  }
};
</script>
    
<style scoped lang='less'>
.middleground-main {
  width: 100%;
  height: 100%;
  min-height: 900px;
  font-size: 16px;
  background-color: #00133c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .middleground {
    width: 100%;
    height: 100%;
    min-width: 1600px;
    min-height: 900px;
    display: flex;
    flex-direction: column;

    .header {
      width: 100%;
      height: 60px;
      background-color: #000716;
      box-shadow: inset 0px -7px 7px -7px #00e4ff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-col-1 {
        padding: 0 20px;
        display: flex;
        font-size: 18px;
      }

      .header-col-2 {}

      .header-col-3 {
        font-size: 30px;
        padding: 0 20px;
        letter-spacing: 0.3em;
        display: flex;
        text-align: center;
        flex-direction: column;
        .header-col-3-title{
          height:51px;
        }
        .header-col-3-img {
          position: relative;
          margin-bottom: -41px;
        }
      }

      .header-col-4 {
        width: 300px;
      }

      .header-col-5 {
        width: 300px;
        text-align: right;
      }
    }

    .content {
      width: 100%;

      .content-items {
        width: 450px;
        margin: 30px 10px 0 10px;
        float: left;
        display: flex;
        flex-direction: column;

        .content-item {
          margin: 18px 0;
          width: 100%;
          height: 458px;
          box-shadow: inset 0px 0px 7px 0px #00e4ff;

          .content-item-icon {
            position: relative;
            margin-top: -32px;
            z-index: 0;
          }

          .content-charts-title {

            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
            font-size:12px;
            color:#ffe400;
            padding:5px 0;
          }

          .content-item-title {

            position: relative;
            margin: -20px 0 0 15px;
            z-index: 1;
          }

          .content-item-carousel {
            width: calc(100% -40px);
            margin: 10px 20px;
            height: 410px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .ivulistitem-1 {
              background-color: #000a32;
              padding: 10px 10px;
            }

            .ivulistitem-2 {
              background-color: #021a54;
              padding: 10px 10px;
            }

            .ivulistitem-wram {
              font-size: 12px;
              color: #ff451a;
              padding-right: 10px;
            }

            .ivulistitem-green {
              font-size: 12px;
              color: #5ff4d4;
              padding-right: 10px;
            }

            /deep/.ivu-list-split .ivu-list-item {
              border-bottom: 0px;
            }

            /deep/.ivu-list-item-meta-description {

              color: rgb(210, 210, 210);
              font-size: 14px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-meta-title {
              font-weight: 500;
              margin-bottom: 4px;
              color: rgb(255, 255, 255);
              font-size: 16px;
              line-height: 22px;
            }

            /deep/.ivu-list-item-action>li {
              position: relative;
              display: inline-block;
              padding: 0 8px;
              color: rgb(255, 255, 255);
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              cursor: pointer;
            }
          }

          .content-item-carousel-small {
            height: 280px;
          }
        }

        .content-item-center {
          width: 100%;
          height: 952px;

          .center-total {
            width: 100%;
            display: flex;
            justify-content: center;

            .i-circle {
              margin: 20px;

              .i-circle-name {
                line-height: 1.8em;
              }
            }
          }
        }
      }

      .content-items-center {
        width: calc(100% - 960px);
      }
    }

    .eCharts-small {
      width: 100%;
      height: 150px;
      position: relative;
      top: -20px;
    }

    .eCharts-big {
      width: 100%;
      height: 440px;
      position: relative;
    }
  }

  .baidu-map-view {
    height: 500px;
    width: calc(100% - 20px);
    margin: 10px;
    z-index: 1;
    border-radius: 10px;

    .marker-div {
      position: absolute;
      width: 0px;
      height: 0px;

      .marker-name {
        position: absolute;
        color: #555;
        background: #eee;
        border-radius: 5px;
        padding: 2px 5px;
        white-space: nowrap;
        font-size: 12px;
      }

      .marker-img {
        width: 40px;
        height: 40px;
        transition: all 0.4s;
        position: relative;
        left: -20px;
        top: -40px;
      }

      .marker-img-active {
        opacity: 0.99;
        width: 65px;
        height: 65px;
        transition: all 0.4s;
        position: relative;
        left: -32.5px;
        top: -65px;
      }
    }


  }

}

/deep/.BMap_cpyCtrl {
  display: none !important;
}

/deep/.anchorBL {
  display: none !important;
}
</style>