<template>
    <FNCContent>
        <template v-slot:title>场景交互定制</template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="importExcelBtn">导入</Button>
        </template>
        <template v-slot:contentArea>
        <div style="position: relative;border-bottom:1px solid #ddd;">
            <Breadcrumb separator="/" style="padding-right: 100px">
                当前位置：
                <BreadcrumbItem v-for="(item, index) in bcTitle" :key="index"><Button type="primary" ghost size="small"  @click="getLevelList(item.id)">{{ item.title }}</Button></BreadcrumbItem>
                <Button type="primary" size="small" icon="md-add" @click="append(bcTitle[bcTitle.length-1])"></Button>
            </Breadcrumb>
            <Button type="success" style="position: absolute; right: 14px; top: 0px" icon="ios-share-outline" @click="submit">保存</Button>
        </div>
            <div class="leftMenu">
             <List class="clist" v-if="currentList&&currentList.length>0" :loading="loading">
        <ListItem style="border-bottom:1px solid #eee;" v-for="(item, index) in currentList.slice((currentPage-1)*pageSize,currentPage*pageSize)" :key="'R' + index">
          <div
            style="width: 100%;"
          >
            <a @click="getLevelList(item.id)" class="lista">{{item.title}}</a>
          </div>
          <template slot="action">
           
            <li>
              <a
                @click="
                  () => {
                    select(item);
                  }
                "
                >修改</a
              >
            </li>
            <li>
              <a
                @click="
                  () => {
                    remove(item);
                  }
                "
                >删除</a
              >
            </li> <li>
                <a
                @click="
                  () => {
                    move(item)
                  }
                "
                >移动</a
              >
              
            </li>
             <li>
                <a
                @click="
                  () => {
                    createMpCode(item)
                  }
                "
                >分享码</a
              >
              
            </li>
          </template>
        </ListItem>
      </List>
      <Page
        style="float: right; margin: 10px 0"
        :model-value="currentPage"
        @on-change="hadlePage"
        @on-page-size-change="hadlePageSize"
        :page-size="pageSize"
        :total="currentList.length"
        :page-size-opts="[10, 20, 50, 100,200]"
        size="small"
        show-total
        show-elevator
        show-sizer
      />
            </div>
            <div class="rightEdit">
                <div v-if="JSON.stringify(currentFormData) !== '{}'" style="position: relative">
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="ID" v-if="currentFormData.id">
                            <Input disabled v-model.trim="currentFormData.id"></Input>
                        </FormItem>
                        <FormItem label="名称" prop="title">
                            <Input v-model.trim="currentFormData.title" placeholder="请输入名称" maxlength="50"></Input>
                        </FormItem>
                        <FormItem label="是否主入口" prop="isMap">
                            <RadioGroup v-model="currentFormData.isMain">
                                <Radio :label="1">是</Radio>
                                <Radio :label="0">否</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="绑定社区" v-show="currentFormData.isMain==1">
                            <Select v-model="currentFormData.region" style="width:100px;margin-right:5px;" placeholder="全部区" @on-change="changeRegion1">
                            <Option :value="item.orgCode" v-for="(item, index) in regionList" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        <Select v-model="currentFormData.stree" style="width:150px;margin-right:5px; " placeholder="全部街道/乡镇" @on-change="changeStree1" >
                            <Option :value="item.orgCode" v-for="(item, index) in streeList1" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        <Select v-model="currentFormData.orgCode" style="width:160px;margin-right:5px;" placeholder="全部社区" clearable >
                            <Option :value="item.orgCode" v-for="(item, index) in projectList1" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        </FormItem>

                        <FormItem label="地图模式" prop="isMap">
                            <RadioGroup v-model="currentFormData.isMap">
                                <Radio :label="1">是</Radio>
                                <Radio :label="0">否</Radio>
                            </RadioGroup>
                            <span style="margin-left: 10px">注：选择“是”则本层级的子层级将以地图模式展示，需要为子层级配置经纬度</span>
                        </FormItem>
                        <FormItem label="经纬度">
                            <Input v-model.trim="currentFormData.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
                            <Input v-model.trim="currentFormData.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
                            <Button @click="mapFn(true)">地图选点</Button>
                        </FormItem>
                        <FormItem label="地点指引/描述">
                            <Input v-model.trim="currentFormData.distance" placeholder="请输入30字内地点指引/描述"></Input>
                        </FormItem>
                        <FormItem label="背景图片/图标">
                            <span class="validate" slot="label">背景图片/图标</span>
                            <FNCUpload
                                ref="FNCUpload"
                                v-if="showFNCUpload"
                                type="image"
                                accept=".jpg,.png,.gif,.jpeg"
                                :defaultList="defaultList"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :showView="true"
                            ></FNCUpload>
                        </FormItem>

                        <FormItem label="显示位置" prop="station">
                            <RadioGroup v-model="currentFormData.station">
                                <Radio label="left">左</Radio>
                                <Radio label="right">右</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="按钮排序" prop="sort">
                            <InputNumber :min="1" :max="99999999" v-model.trim="currentFormData.sort"></InputNumber>
                        </FormItem>
                        <FormItem label="子按钮颜色">
                            <ColorPicker v-model="currentFormData.btnColor" recommend @on-active-change="colorChange" />
                        </FormItem>

                        <FormItem label="小程序跳转链接">
                            <div v-for="(item, index) in currentFormData.url" :key="index">
                                <FormItem :label-width="85">
                                    <span slot="label">按钮名称：</span>
                                    <Input v-model.trim="item.name" style="width: calc(100% - 85px)" placeholder="请输入按钮的名称"></Input>
                                </FormItem>
                                <FormItem :label-width="85">
                                    <span slot="label" style="margin-top: 10px; display: inline-block">跳转链接：</span>
                                    <Input
                                        v-model.trim="item.url"
                                        placeholder="请输入跳转链接"
                                        :style="{
                                            width: index == currentFormData.url.length - 1 ? 'calc(100% - 207px)' : 'calc(100% - 151px)',
                                            margin: '10px 20px 10px 0',
                                        }"
                                    ></Input>
                                    <Button type="primary" style="margin-right: 10px" @click="addUrl" v-if="index == currentFormData.url.length - 1"><Icon type="md-add" /></Button>
                                    <Button type="error" @click="delUrl(index)"><Icon type="ios-trash-outline" /></Button>
                                </FormItem>
                                <Divider style="margin: 10px 0" v-if="index != currentFormData.url.length - 1" />
                            </div>
                            <Button type="primary" @click="addUrl" v-if="!currentFormData.url || currentFormData.url.length == 0"><Icon type="md-add" /></Button>
                        </FormItem>
                        <!-- <iframe
              name="ueditor"
              id="ueditor"
              src="/ueditor/index.html?titlename=简介"
              style="width: 100%; height: 500px; border: 0px"
              @load="loadUeditor"
            ></iframe> -->
                        <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=简介" style="width: 100%; height: 500px; border: 0px" @load="loadUeditor"></iframe>
                    </Form>
                </div>
                <div v-else class="noContent">场景交互编辑区</div>
            </div>
            <FNCModal title="场景移动设置" width="60%" height="calc(100vh - 200px)" :value="moveSceneStatus" @input="moveSceneStatusFn">
                <template #contentarea>
                     <div style="position: relative;border-bottom:1px solid #ddd;">
            <Breadcrumb separator="/">
                当前位置：
                <BreadcrumbItem><Button type="primary" ghost size="small"  @click="getMLevelList(-1)">根</Button></BreadcrumbItem>
                <BreadcrumbItem v-for="(item, index) in bcMTitle" :key="index"><Button type="primary" ghost size="small"  @click="getMLevelList(item.id)">{{ item.title }}</Button></BreadcrumbItem>
            </Breadcrumb>
        </div>
            <div>
                <RadioGroup  style="width:100%" v-model="moveParentId">
             <List style="background: #fff;
        height: calc(100vh - 360px);
        overflow: auto;" v-if="moveMenuList&&moveMenuList.length>0" :loading="loading">
        <ListItem style="border-bottom:1px solid #eee;" v-for="(item, index) in currentMList.slice((currentMPage-1)*100,currentMPage*100)" :key="'M' + index">
           <div
            style="width: 100%;"
          >
            <a @click="getMLevelList(item.id)" class="lista">{{item.title}}</a>
          </div>
          <template slot="action">
           <Radio :label="item.id" style="color:#2b85e4;">选中此项</Radio>
          </template>
        </ListItem>
      </List>
      </RadioGroup>
      <Page
        style="float: right; margin: 10px 0"
        :model-value="currentMPage"
        @on-change="hadleMPage"
        :page-size="100"
        :total="currentMList.length"
        size="small"
        show-total
        show-elevator
      />
            </div>

                </template>
                <template #toolsbar>
                    <Button @click="moveSceneStatus = false" style="margin-right: 10px">取消</Button>
                    <Button type="primary" @click="saveMove">保存</Button>
                </template>
            </FNCModal>

            <FNCModal title="生成小程序码" width="600px" height="calc(100vh - 200px)" :value="mpCodeStatus" @input="moveMpCodeFn">
                <template #contentarea>
                    <div>
                        <Select v-model="region" style="width: 50%; margin-bottom: 10px" placeholder="请选择区" @on-change="changeRegion" filterable>
                            <Option :value="item.orgCode" v-for="(item, index) in regionList" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        <Select v-model="stree" style="width: 50%; margin-bottom: 10px" placeholder="请选择街道/乡镇" @on-change="changeStree" filterable>
                            <Option :value="item.orgCode" v-for="(item, index) in streeList" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        <Select v-model="project" style="width: 100%; margin-bottom: 10px" placeholder="请选择社区" clearable filterable>
                            <Option :value="item.orgCode" v-for="(item, index) in projectList" :key="index">{{ item.orgName }}</Option>
                        </Select>
                        <div style="width: 100%">状态栏背景颜色</div>
                        <Select v-model="statusBarTextColor" style="width: 50%; margin-bottom: 10px; margin-top: 10px" placeholder="状态栏字体颜色">
                            <Option value="#ffffff">亮色</Option>
                            <Option value="#000000">暗色</Option>
                        </Select>
                        <ColorPicker v-model="statusBarBackgroundColor" recommend @on-active-change="colorMpChange" style="width: 50%; margin-bottom: 10px" />

                        <Button type="primary" style="width: 100%; margin-bottom: 10px" @click="makeQRCode">生成小程序码</Button>
                    </div>
                    <div style="margin: 20px; width: calc(100% - 40px); text-align: center" v-html="qrcodeUrl"></div>
                </template>
            </FNCModal>

            <!-- 导入 -->
            <FNCModal title="导入" width="40%" height="240px" :value="exportExcel.status" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <FNCUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/circletemplate.xlsx', downName: '十五分钟生活圈导入模板' }"
                        :action="exportExcel.exportAction"
                        :uploadData="uploadData"
                        :isShow="exportExcel.showExcel"
                        @success="excelSuccess"
                    ></FNCUploadExcel>
                </template>
            </FNCModal>

            <FNCModal class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度" :value="mapStatus" @input="mapFn">
                <template v-slot:contentarea>
                    <div style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;"><Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button type="primary" @click="setPlace(keyword)">查询</Button></div>
                    <baidu-map
                        :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter"
                        :zoom="zoom"
                        @ready="handler"
                        :ak="$store.state.baiduAk"
                        :clicking="true"
                        @click="clickMap"
                        :scroll-wheel-zoom="true"
                    >
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
                    </baidu-map>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="mapFn(false)">取消</Button>
                    <Button type="primary" @click="selectMap">确定</Button>
                </template>
            </FNCModal>
        </template>
    </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/scenemanage
import FNCContent from "@/components/FNCContent"
import FNCModal from "@/components/FNCModal"
import FNCUpload from "@/components/FNCUpload"
import FNCUploadExcel from "@/components/FNCUploadExcel"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
export default {
    components: {
        FNCContent,
        FNCModal,
        FNCUpload,
        FNCUploadExcel,
        BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
    },
    data() {
        return {
            mapStatus: false,
            zoom: 16,
            pageCenter: {},
            selectedLocation:{},
            BMap: null,
            map: null,
            keyword:"",
            menuList: [
                {
                    title: "所有场景",
                    id: 0,
                    children: [],
                },
            ],
            currentList:[],
            currentPage:1,
            pageSize:50,
            loading:false,
            buttonProps: {
                type: "default",
                size: "small",
            },
            currentFormData: {},
            defaultList: [],
            showFNCUpload: false,
            validateForm: {
                title: [{ required: true, message: "请输入名称", trigger: "blur" }],
                sort: [
                    {
                        required: true,
                        type: "number",
                        message: "请输入按钮排序",
                        trigger: "blur",
                    },
                ],
            },
            bcTitle: [{title:"所有场景",id:0}],
            // 移动
            moveSceneStatus: false,
            moveMenuList: [],
            moveObj: {},
            moveParentId: "",
            mpCodeStatus: false,
            region: "",
            stree: "",
            project: "",
            regionList: [],
            streeList: [],
            projectList: [],
            streeList1: [],
            projectList1: [],
            currentScene: {},
            qrcodeUrl: "",
            statusBarTextColor: "#ffffff",
            statusBarBackgroundColor: "#19be6b",

            exportExcel: {
                status: false,
                exportAction: "",
                showExcel: 0,
            },
            currentMPage:1,
            currentMList:[],
            bcMTitle: [],
        }
    },
    methods: {
        // 点击导入按钮
        importExcelBtn() {
            this.exportExcel.status = true
            this.exportExcel.showExcel++
            this.exportExcel.exportAction = window.vue.getProxy()["/gateway"].target + "/apps/o2o/api/o2o/admin/importLifeCircle"
        },
        // 关闭导入弹窗事件
        excelModalData(status) {
            this.exportExcel.status = status
        },
        //导入成功的回调
        excelSuccess() {
            this.exportExcel.status = false
            this.getSceneData(0)
        },
        colorChange(color) {
            this.currentFormData.btnColor = color
        },
        saveMove() {
            if(!this.moveObj||!this.moveObj.id||typeof(this.moveParentId) != "number"){
                this.$Message.error({
                            background: true,
                            content: "未选择移动目标或被移动目标",
                        })
                return;
            }
            var that=this
            this.$post({url:"/gateway/apps/o2ogateway/o2o/finger/scene/move",data: {
                id: this.moveObj.id,
                parentId: this.moveParentId,
                oemCode: process.env.NODE_ENV == "production" ? "zjsm" : "FNC",
        }})
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "保存成功",
                        })
                        setTimeout(() => {
                            that.moveSceneStatus = false
                            that.getSceneData(0)
                        }, 500)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                    this.moveParentId=""
                })
                .catch(err => {
                     this.moveParentId=""
                    this.$Message.error({
                        background: true,
                        content: "保存失败，请联系管理员处理",
                    })
                })
        },
        moveSelectChange(id) {
            console.log(id);
            this.moveParentId = id
        },
        moveSceneStatusFn(status) {
            this.moveSceneStatus = status
        },
        // 过滤
        delMoveObj(id, list) {
            list.map((item, index, arr) => {
                if (id == item.id) {
                    arr.splice(index, 1)
                } else if (item.children && item.children.length > 0) {
                    this.delMoveObj(id, item.children)
                }
            })
        },
        move(data) {
            this.moveObj = JSON.parse(JSON.stringify(data))
            this.moveSceneStatus = true
            this.moveMenuList = JSON.parse(JSON.stringify(this.menuList))
            this.delMoveObj(data.id, this.moveMenuList)
            this.getMLevelList(0);
        },
        colorMpChange(color) {
            this.statusBarBackgroundColor = color
        },
        createMpCode(data) {
            this.mpCodeStatus = true
            this.currentScene = data
            this.region = data.region
            this.changeRegion(data.region)
            this.changeStree(data.stree)
            this.$nextTick(()=>{
                this.stree = data.stree
                this.project = data.orgCode
            });
            this.qrcodeUrl = ""

        },
        moveMpCodeFn(status) {
            this.mpCodeStatus = status
        },
        // 区
        selectRegion(code) {
            this.region = ""
            this.stree = ""
            this.project = ""
            this.regionList = []
            this.streeList = []
            this.projectList = []
            if (code == null || code == "") return
            this.getPulishData(code, "regionList")
        },
        // 区选择
        changeRegion(code) {
            this.stree = ""
            this.project = ""
            this.streeList = []
            this.projectList = []
            this.getPulishData(code, "streeList")
        },
        // 街道选择
        changeStree(code) {
            this.project = ""
            this.projectList = []
            this.getPulishData(code, "projectList")
        },

        // 区选择1
        changeRegion1(code) {
            this.currentFormData.stree = ""
            this.currentFormData.orgCode = ""
            this.streeList1 = []
            this.projectList1 = []
            this.getPulishData(code,"streeList1")
        },
        // 街道选择1
        changeStree1(code) {

            this.currentFormData.orgCode = ""
            this.projectList1 = []
         
            this.getPulishData(code,"projectList1")
        },
        // 获取范围列表
        getPulishData(code, list) {
            this.$get({url:"/datamsg/api/pc/staff/selectCascadeDataScope", data:{
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
           } }).then(res => {
                // console.log(res.dataList);
                if (res.code == 200) {
                    this[list] = res.dataList
                }
            })
        },
        // 生成二维码
        makeQRCode() {
            if (!this.stree || this.stree == "") {
                this.$Message.error({
                    background: true,
                    content: "只能生成街道或社区的小程序码，请选择街道",
                })
                return
            }
            let params = {}
            if (this.project && this.project.length > 0) {
                params = {
                    communityList: (() => {
                        let result = []
                        //  console.log(this.projectList);
                        this.projectList.map(item => {
                            if (this.project.indexOf(item.orgCode) != -1) {
                                result.push({
                                    projectCode: item.mappingCode,
                                    projectName: item.orgName,
                                })
                            }
                        })
                        return result
                    })(),
                }
            } else {
                params.streetCode = this.stree
            }
            this.$Message.loading({
                content: "正在生成小程序码...",
                duration: 0,
            })
            params = {
                ...params,
                type: "SIGN_IN",
                action: `${process.env.NODE_ENV == 'production' ? 'https://suiyueshop.FNCtech.com' : 'https://lf.gzenon.com'}/#/pages/stservices/index/index&modelCode=`+ this.currentScene.id,
                statusBarTextColor: this.statusBarTextColor,
                statusBarBackgroundColor: this.statusBarBackgroundColor,
            }

            this.$post(
                {url:"/datamsg/api/pc/wxqr/createWxAppletQr",
                data:{
                    type: "ACTIVITY_SIGN_IN",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/pages/webView/index",
                        params: params,
                    }),
                },
               header: { "Content-Type": "application/json" }
             } ).then(res => {
                this.$Message.destroy()
                if (res.code == 200 && res.data) {
                    this.qrcodeUrl =
                        '<div style="color:red;width:100%;text-align:center;font-size:20px;margin-bottom:20px;">' +
                        this.currentScene.title +
                        '</div><img src="' +
                        res.data.codeUrl +
                        '" style="width:450px;height:450px;">'
                } else {
                    this.qrcodeUrl = "无效的小程序码"
                }
            })
        },

        // 新增保存插入
        insertSceneData(parentId, arr) {
            for (let k in arr) {
                if (arr[k].id == parentId) {
                    arr[k].children.push({
                        title: this.currentFormData.title,
                        id: this.currentFormData.id,
                        parentId: parentId,
                        expand: false,
                        children: [],
                    })
                    return
                } else if (arr[k].children && arr[k].children.length > 0) {
                    this.insertSceneData(parentId, arr[k].children)
                }
            }
        },
        // 修改更新menuList的title
        updateSceneData(id, arr) {
            for (let k in arr) {
                if (arr[k].id == id) {
                    arr[k].title = this.currentFormData.title
                    return
                } else if (arr[k].children && arr[k].children.length > 0) {
                    this.updateSceneData(id, arr[k].children)
                }
            }
        },
        submit() {
            this.$refs.form.validate(status => {
                if (status) {
                    if (this.currentFormData.isMap != 1) this.currentFormData.isMap = 0

                    this.currentFormData.bgImage = this.$refs.FNCUpload.uploadList[0] ? this.$refs.FNCUpload.uploadList[0].url : ""
                    // if (!this.currentFormData.bgImage) {
                    //     this.$Message.error({
                    //         background: true,
                    //         content: "请上传背景图片",
                    //     })
                    //     return
                    // }
                    if (!this.currentFormData.station) {
                        this.$Message.error({
                            background: true,
                            content: "显示位置必选",
                        })
                        return
                    }

                    let urlArr = []
                    for (let k in this.currentFormData.url) {
                        if (this.currentFormData.url[k].name && this.currentFormData.url[k].url) {
                            urlArr.push(this.currentFormData.url[k])
                        } else if (!this.currentFormData.url[k].name && this.currentFormData.url[k].url) {
                            this.$Message.error({
                                background: true,
                                content: "请填写第" + (Number(k) + 1) + "个小程序跳转的按钮名称",
                            })
                            return
                        } else if (!this.currentFormData.url[k].url && this.currentFormData.url[k].name) {
                            this.$Message.error({
                                background: true,
                                content: "请填写第" + (Number(k) + 1) + "个小程序跳转链接",
                            })
                            return
                        }
                    }

                    var v_Obj = document.getElementById("ueditor").contentWindow
                    this.currentFormData.content = v_Obj.getContent()
                    let url = urlArr && urlArr.length > 0 ? JSON.stringify(urlArr) : ""
                    this.currentFormData.isMain=this.currentFormData.isMain || 0;
                    this.$post({url:"/gateway/apps/o2ogateway/o2o/finger/scene/save",data: {
                        ...this.currentFormData,
                        url: url,
                        ani: "hide",
                        oemCode: process.env.NODE_ENV == "production" ? "zjsm" : "FNC",
                }})
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                if (!this.currentFormData.id) {
                                    //代表新增，此时清空currentFormData
                                    this.$set(this.currentFormData, "id", res.data.id)
                                    this.insertSceneData(res.data.parentId, this.menuList)
                                } else {
                                    //代表修改，把title更新回menuList
                                    this.updateSceneData(res.data.id, this.menuList)
                                }
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },
        addUrl() {
            if (!this.currentFormData.url) this.currentFormData.url = []
            this.currentFormData.url.push({ name: "", url: "" })
        },
        delUrl(index) {
            this.currentFormData.url.splice(index, 1)
        },
        loadUeditor() {
            let ueditor = document.getElementById("ueditor")
            if (ueditor) {
                var v_Obj = ueditor.contentWindow //获取iframe对象
                v_Obj.setContent(this.currentFormData.content ? this.currentFormData.content : "") //写入编辑器富文本内容
            } else {
                setTimeout(this.loadUeditor, 300)
            }
        },
        append(data) {
            this.showFNCUpload = false
            this.$nextTick(() => {
                this.showFNCUpload = true
            })
            this.defaultList = []
            this.currentFormData = {
                parentId: data.id,
                btnColor: "",
                url: [],
                sort: 99,
                station: "left",
            }
            this.bcTitle = []
            this.getBCTitle(data.id, this.menuList[0])
            this.loadUeditor()
        },
        getBCTitle(id, obj) {
            if (obj.id == id) {
                this.bcTitle.unshift({title:obj.title,id:obj.id})
                this.getBCTitle(obj.parentId, this.menuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getBCTitle(id, obj.children[k])
                }
            }
        },
        getLevelList(id,obj) {
            this.currentList=[]
             this.currentPage=1
             if(!obj) obj=this.menuList[0]
             this.getLevelList2(id,obj)
        },
         getLevelList2(id,obj) {
            if (obj.id == id) {
                this.currentList=obj.children;
                this.bcTitle=[];
                this.getBCTitle(id, this.menuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getLevelList2(id, obj.children[k])
                }
            }
        },
           getMLevelList(id,obj) {
            this.currentMList=[]
             this.currentMPage=1
             this.$nextTick(()=>{
              if(id==-1){
                    this.currentMList=this.moveMenuList
                    this.bcMTitle=[];
                }else{
                 if(!obj) obj=this.moveMenuList[0]
                 this.getMLevelList2(id,obj)
                }
             });
        },
         getMLevelList2(id,obj) {
            if (obj.id == id) {
                this.currentMList=obj.children;
                this.bcMTitle=[];
                this.getMBCTitle(id, this.moveMenuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getMLevelList2(id, obj.children[k])
                }
            }
        },
        getMBCTitle(id, obj) {
            if (obj.id == id) {
                this.bcMTitle.unshift({title:obj.title,id:obj.id})
                this.getMBCTitle(obj.parentId, this.moveMenuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getMBCTitle(id, obj.children[k])
                }
            }
        },
        hadlePageSize(e){
            this.pageSize=e
        },
        hadlePage(e){
          this.currentPage=e
        },
        hadleMPage(e){
          this.currentMPage=e
        },
        select(data) {
            if (data.id) {
                this.currentFormData={};
                this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
                })
                this.$get({url:"/gateway/apps/o2ogateway/o2o/finger/scene/viewById",data: {
                    id: data.id,
                    oemCode: process.env.NODE_ENV == "production" ? "zjsm" : "FNC",
             } })
                    .then(res => {
                        if (res.code == 200 && res.data) {
                            if (!res.data.btnColor) {
                                res.data.btnColor = ""
                            }
                            if (res.data.bgImage) {
                                this.defaultList = [{ name: "", url: res.data.bgImage }]
                            } else {
                                this.defaultList = []
                            }
                            this.showFNCUpload = false
                            this.$nextTick(() => {
                                this.showFNCUpload = true
                            })


                            this.changeRegion1(res.data.region);
                            this.changeStree1(res.data.stree);

                            this.currentFormData = res.data
                            // console.log("currentFormData", this.currentFormData)
                            this.$forceUpdate()
                            // this.bcTitle = []
                            // this.getBCTitle(data.id, this.menuList[0])
                            // this.bcTitle.pop()
                            this.loadUeditor()
                            this.$Message.destroy()
                        } else {
                            this.$Message.error({
                                background: true,
                                content: res.desc,
                            })
                        }
                        
                    })
                    .catch(err => {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: "获取数据失败，请联系管理员处理",
                        })
                    })
            }
        },
        remove(data) {
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: `<p>您正在删除<span style="color: red"> ${data.title} </span> ${
                    data.children && data.children.length > 0 ? "，其下所有的场景将会被同时删除" : ""
                }，此操作不可逆，是否继续</p>`,
                onOk: () => {
                    this.$post({url:"/gateway/apps/o2ogateway/o2o/finger/scene/delete",data:{
                        id: data.id,
                        oemCode: process.env.NODE_ENV == "production" ? "zjsm" : "FNC",
                    }})
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "删除成功",
                                })
                                this.$Modal.remove()
                                this.getSceneData(0)
                            } else {
                                this.$Modal.remove()
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Modal.remove()
                            this.$Message.error({
                                background: true,
                                content: "删除失败，请联系管理员处理",
                            })
                        })
                },
            })
        },
       
        getSceneData(id) {
            this.loading=true
            this.$get({url:"/gateway/apps/o2ogateway/o2o/finger/scene/query",data: {
                parentId: id,
                isTree: 1,
                oemCode: process.env.NODE_ENV == "production" ? "zjsm" : "FNC",
         } })
                .then(res => {
                    this.loading=false
                    if (res.code == 200 && res.dataList) {
                        this.$Message.destroy()
                        this.menuList[0].children = res.dataList
                        this.getLevelList(id,this.menuList[0]);
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.loading=false
                    this.$Message.destroy()
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图
        clickMap(e) {
            this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation=point;
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
            }
        },
        mapFn(status) {
            this.mapStatus = status
            if (this.currentFormData.lng) {
                this.keyword="";
                this.pageCenter = { lng: this.currentFormData.lng, lat: this.currentFormData.lat }
                this.makerCenter(this.pageCenter)
            } else if (this.currentFormData.distance) {
                this.keyword=this.currentFormData.distance
                this.setPlace(this.currentFormData.distance)
            }
        },
        selectMap() {
            this.currentFormData.lng=this.selectedLocation.lng
            this.currentFormData.lat=this.selectedLocation.lat
            this.mapStatus = false
        },
        setPlace(myValue) {
           if(!myValue) return;
            var that = this
            var local = new this.BMap.LocalSearch(this.map, {
                onSearchComplete: () => {
                    try{
                    var pp = local.getResults().getPoi(0).point
                    that.pageCenter = { lng: pp.lng, lat: pp.lat }
                    that.makerCenter(pp)
                    }catch(e){}
                },
            })
            local.search(myValue)
        },
    },
    created() {
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
        this.$Message.loading({
            content: "正在加载数据，请稍等...",
            duration: 0,
        })
        this.getSceneData(0)
        this.selectRegion("4401")
    },
}
</script>

<style lang='less' scoped>
.leftMenu {
    position: fixed;
    top: 115px;
    left: 0px;
    width: 550px;
    bottom: 0;
    background: #ffffff;
    overflow: hidden;
    border-right:1px solid #ddd;
    padding:0 10px;
    .clist {
        background: #fff;
        height: calc(100% - 60px);
        overflow: auto;
    }    
   .ivu-list-item {
    padding: 8px 0;
    &:hover{
        background: #f8f8f9;
    }
   }

}
.rightEdit {
    position: fixed;
    top: 115px;
    left: 554px;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
/deep/.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
/deep/.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
/deep/.ivu-breadcrumb {
    background-color: #fff;
    line-height: 30px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
    .ivu-breadcrumb-item-separator{
        color:#555;
    }
}
.form {
    padding-right: 30px;
    height: calc(100vh - 106px);
    overflow: auto;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.lista{
    color:#333;
    &:hover{
        color:#ff0000;
    }
}
</style>
