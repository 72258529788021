<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
      <div style="width:250px;"><Input search enter-button="查询" clearable v-model="pageParams.keyword"
          placeholder="输入管线名称" style="width: 600px" @on-search="loadData(1)" /></div>
    </template>
  
    <template v-slot:contentArea>
      <FNCTable ref="fncTable" :loading="loading" :fixTable="true" :talbeColumns="talbeColumns" :tableData="tableData"
        :pageParams="pageParams" :pagesizeOpts="pagesizeOpts" @handleLoadData="loadData"></FNCTable>
      <FNCModal ref="editModal" :title="editName+'水位监测'" :fullscreen="false" width="80%" height="600px">
        <template v-slot:contentarea>
          <Edit ref="editContent" @closeModal="closeModal"></Edit>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 8px" @click="closeModal">
            <Icon type="md-close" />取消
          </Button>
          <Button type="primary" @click="submitData" :loading="saveLoading">
            <Icon type="md-checkmark-circle" />保存
          </Button>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/monitor
import FNCContent from "@/components/FNCContent";
import FNCTable from "@/components/FNCTable";
import FNCModal from "@/components/FNCModal";
import Edit from "./childrens/Edit";
import * as echarts from 'echarts';
export default {
  components: {
    FNCTable,
    FNCContent,
    FNCModal,
    Edit,
  },

  data() {
    return {
      title: "信息列表",
      loading: false,
      pageParams: {
        maxCount: 0,//数据总条数
        currentPage: 0,//当前页(默认第一页)
        pageSize: 20,//每页大小(默认10)，无限屏读取时的数量
        maxPage: 1,//总页数
        sort: "id",//排序字段
        order: "desc",//排序方式
        keyword: "",//通用查询条件
        keyValue: "",//无限屏滚动读取数据时，根据顺序Key值读取
        objectNum:"",
      },
      pagesizeOpts: [20, 30, 50, 100],//分页数组
      tableData: [],
      dataList: [],
      talbeColumns: [
        {
          title: "管线名",
          key: "objectName",
          align: "center",
          width: 300,
        },
      
        {
          title: "水位监测",
          key: "objectNum",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div",{style:{width: "100%",height:"80px"},attrs:{id:"eCharts"+params.index}})
          },
        }, 
      
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("div",
              [
                h(
                  "Button",
                  {

                    class: "editDiv"
                    ,
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      margin: "0 5px",
                    },

                    on: {
                      click: () => {
                        this.editData(params.row.id,params.row.objectName);
                      },
                    },
                  },
                  "水位监测"
                ),
              ]);
          },
        },
      ],
      right: {},
      saveLoading: false,
      infoType: "",
      infoTypeList: [],
      projects:[],
      editName:""
    };
  },
  created() {
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    async loadModuleInfo() {
      //读取栏目信息
      await this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      //读取数据列表
      this.loadData(1);
      this.$core.getControlRight().then(res => {
        this.right = res;
      });
    },
    convertObject(target,inFeild,outFeild,value){
      var object=this[target].find(item=>value==item[inFeild])
      return object?object[outFeild]:"无"
    },
    loadData(currentPage) {
      if (currentPage) {
        this.pageParams.currentPage = currentPage;
      } else {
        this.pageParams.currentPage = this.pageParams.currentPage + 1;
      }
      this.loading = true;
      this.$get({
        url: "/smartcovers/object/queryObjectCover",
        data: this.pageParams,
        success: (res) => {
          this.tableData = []
          if (res.code == "200" && res.pageParams) {
            this.pageParams = {...this.pageParams,...res.pageParams}
            this.tableData = res.dataList
            setTimeout(() => {
              res.dataList.map((item, index) => {
                var xAxis = [];
                var data = [];
                item.coverStatistics && item.coverStatistics.map(ss => {
                  xAxis.push(ss.coverName)
                  var color = ss.waterLevel >= (ss.depth || 200) - 30 ? "#ff0000" : "rgb(255, 70, 131)"
                  data.push({ value: ss.waterLevel, itemStyle: { borderColor: color, color: color } })
                })
                this.createChart('eCharts' + index, { xAxis: xAxis, data: data })
              })
            }, 1000);
          } else {
            this.$Message.error({ background: true, content: res.desc || "读取出错", duration: 3 })
          }
          this.loading = false;
        }
      })
    },
    createChart(id, data) {
      let ec = echarts.init(document.getElementById(id))
      let option = {
        xAxis: {
          type: 'category',
          axisLabel: {
            show: false,
            interval: 0,
            rotate: -90,
          },
          data: data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(255, 70, 131)'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 158, 68)'
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)'
                }
              ])
            },
            data: data.data,
            smooth: true,
            type: 'line'
          }
        ]
      };
      ec.setOption(option);
    },
    editData(id,objectName) {
      if (id && this.right["E"] == true || !id && this.right["A"] == true) {
        this.editName=objectName;
        this.$refs.editModal.showModal();
        this.$refs.editContent.loadData(id);
      }
    },
    closeModal() {
      this.$refs.editModal.hideModal();
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadData(1);
          this.closeModal();
        }
      });
    }
  }
};
</script>
    
<style scoped lang='less'>

.sc-monitor-index {
    background-color: #ecf0f5;
    height:100%;
    .sc-monitor-search {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        padding:20px;
        height:60px;
        z-index: 10;
        .sc-monitor-search-item {
           width:calc(100vw - 40px);
        }
        .at-search-bar__action {
            background-color: #00BCD4;
            &:active{
                background-color: #007382;
            }
        }
    }
    .sc-monitor-search-sp{
        height:100px;
    }

    .sc-index-custom-foot-title {
        padding: 20px;
        font-size: 32px;
        font-weight: bold;
    }
   

    .sc-index-custom-foot-items {
        display: flex;
        flex-direction: column;
        margin:20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        .custom-foot-items-title{
            font-size: 32px;
            font-weight: bold;
            color:#333;
            background-color: #f3f3f3;
            padding:20px;
            border-radius: 10px 10px  0 0 ;
            .title-sub{
                font-size: 30px;
                color:#aaa;
                font-weight: lighter;
                float:right;
                margin:4px 10px 0 0;
            }
            .title-zoom{
                font-weight: lighter;
                float:right;
                color:#999;
                font-size: 35px;
            }
            .title-address {
                font-size: 24px;
                color:#aaa;
                font-weight: lighter;
                clear: both;
                padding:0 10px;
            }
        }
        .custom-foot-items-time{
            font-size: 26px;
            color:#888;
            margin: 20px 0;
            width:100%;
            height:160px;
            position: relative;
            z-index: 0;
        }
        .custom-foot-items-btn {
            width:100%;
            margin-top: 30px;
            .custom-foot-items-btn-red{
                float: left;
                color:#fff;
                background-color: #FD0000;
                font-size: 26px;
                padding:4px 20px;
                border-radius: 30px;
            }
            .custom-foot-items-btn-,.custom-foot-items-btn-init,.custom-foot-items-btn-success{
                float: left;
                color:#888888;
                background-color: #D8D8D8;
                font-size: 26px;
                padding:4px 20px;
                border-radius: 30px;
                margin-right:10px;
            }
            .custom-foot-items-btn-repair{
                float: left;
                color:#ffffff;
                background-color: #ff000099;
                font-size: 26px;
                padding:4px 20px;
                border-radius: 30px;
                margin-right:10px;
            }
            .custom-foot-items-btn-repair{
                float: left;
                color:#ffffff;
                background-color: #92890099;
                font-size: 26px;
                padding:4px 20px;
                border-radius: 30px;
                margin-right:10px;
            }
            .custom-foot-items-btn-yellow{
                float: right;
                color:#fff;
                background-color: #EC970B;
                font-size: 26px;
                padding:6px 20px;
                border-radius: 10px;
                margin-right:10px;
                &:active{
                    background-color: #ff0000;
                }
            }
            .custom-foot-items-btn-gray-right{
                float: right;
                color:#fff;
                background-color: gray;
                font-size: 26px;
                padding:6px 20px;
                border-radius: 10px;
                margin-right:10px;
                &:active{
                    background-color: #EC970B;
                }
            }
            .custom-foot-items-btn-green{
                float: right;
                color:#fff;
                background-color: #19be6b;
                font-size: 26px;
                padding:6px 20px;
                border-radius: 10px;
                margin-right:10px;
                &:active{
                    background-color: #2db7f5;
                }
            }
        }
    }
}

.sc-monitorview-index {
    background-color: #ecf0f5;
    height:100%;
    width: 100%;
    font-size: calc(32px * var(--scale));
    .sc-monitorview--title{
        width: calc(100% - 40px * var(--scale));
        height:calc(60px * var(--scale));
        display: flex;
        align-items: center;
        background-color: #000;
        color:#fff;
        padding: calc(20px * var(--scale));
        .sc-monitorview--title-back{
            width:calc(60px * var(--scale));
            font-size: calc(45px * var(--scale));
            padding-right: calc(20px * var(--scale));
        }
        .sc-monitorview--title-title{
            width:calc(100% - 320px * var(--scale));
            font-size: calc(32px * var(--scale));
        }
        .sc-monitorview--title-timer{
            width:calc(200px * var(--scale));
            font-size: calc(32px * var(--scale));
            .weui-picker, .weui-picker__hd {
                font-size: calc(32px * var(--scale)) !important;
                }
                .weui-picker, .weui-picker__hd,.at-float-layout .layout-header ,.at-float-layout--active .at-float-layout__container {
                    border-top-left-radius: calc(20px * var(--scale));
                    border-top-right-radius: calc(20px * var(--scale));
                }
        }
        
        .sc-monitorview--title-more{
            width:calc(40px * var(--scale));
            font-size: calc(32px * var(--scale)) !important;
        }
    }
    .sc-monitorview--avg{
        display: flex;
        align-items: center;
        width:calc(100% - 40px * var(--scale));
        height:calc(80px * var(--scale));
        padding: 0 20px;
        font-size: calc(32px * var(--scale));
    }

}
</style>