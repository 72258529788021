<template>
  <div>
    <div
      v-if="listShow&&type == 'image' && uploadList && uploadList.length > 0"
      style="display: inline-block"
    >
      <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="index">
        <template v-if="item.status === 'finished' && item.url">
          <img :src="item.url" />
          <div class="demo-upload-list-cover">
            <Icon
              type="ios-eye-outline"
              @click.native="handleView(item.url)"
              v-if="showView"
            ></Icon>
            <Icon
              v-if="showDel"
              type="ios-trash-outline"
              @click.native="handleRemove(item)"
            ></Icon>
          </div>
        </template>
        <template v-else>
          <Progress
            v-if="item.showProgress"
            :percent="item.percentage"
            hide-info
          ></Progress>
        </template>
      </div>
    </div>
    <div
      v-if="showView&&type != 'image' && uploadList && uploadList.length > 0"
      style="display: inline-block"
    >
      <div class="demo-upload-list1" v-for="(item, index) in uploadList" :key="index">
        <template v-if="item.status == 'finished' && item.url">
          {{item.name||item.url}}
            <Icon
              v-if="showDel"
              size="20"
              type="ios-trash-outline"
              @click.native="handleRemove(item)"
            ></Icon>
        </template>
        <template v-else>
          <Progress
            v-if="item.showProgress"
            :percent="item.percentage"
          ></Progress>
        </template>
      </div>
    </div>
    <!-- 图片 -->
    <Upload
      v-if="type == 'image'"
      ref="upload"
      :headers="headers"
      :show-upload-list="false"
      :default-file-list="defaultFileList"
      :on-success="handleSuccess"
      :accept="accept"
      :max-size="maxSize"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :multiple="multiple"
      type="drag"
      :action="action"
      :style="{
        display: 'inline-block',
        width: listShow?fileLength == uploadList.length ? '0px' : '80px':'100%',
      }"
      :before-upload="beforeUpload"
      :data="uploadParam"
    >
    <slot>
      <div id="upButton"
        style="width: 80px; height: 80px; line-height: 80px"
        v-if="fileLength <= uploadList.length ? false : true"
      >
        <Icon type="md-add" size="60" style="color: #bbb; margin: 8px auto"></Icon>
      </div>
    </slot>
    </Upload>
    <Modal
      title="查看大图"
      v-model="visible"
      footer-hide
      v-if="type == 'image' && showView"
    >
      <img :src="visibleUrl" v-if="visible" style="width: 100%" />
    </Modal>

    <!-- 文件 -->
    <Upload
      v-if="type != 'image'"
      ref="upload"
      :headers="headers"
      :show-upload-list="false"
      :default-file-list="defaultFileList"
      :on-success="handleSuccess"
      :accept="accept"
      :max-size="maxSize"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :multiple="multiple"
      type="drag"
      :action="action"
      :before-upload="beforeUpload"
      :data="uploadParam"
    >
    <slot>
      <div style="padding: 20px 0;color: #3399ff">
        <Icon type="ios-cloud-upload" size="52"></Icon>
        <p>点击或拖拽到这里上传</p>
      </div>
    </slot>
    </Upload>
  </div>
</template>

<script>
/**
 * 返回方法：在引用中定义success
 */
import FNCModal from "@/components/FNCModal";
import { hashFile } from "@/utils/hashFile";
export default {
  props: {
    type: {
      //上传图片还是文件
      type: String,
      default: "image", //image , file ,
    },
    uploadParam: {
      type: Object,
      default: () => {
        return null;
      },
    },
    accept: { type: String, default: "" }, //上传文件类型:image/png....参考：https://blog.csdn.net/weixin_44599143/article/details/107932099
    multiple: { type: Boolean, default: false }, //是否允许多选，默认false
    maxSize: { type: Number, default: 0 }, //文件限制大小,单位 kb
    headers: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showView: {
      //上传图片时是否需要查看大图，上传文件时显示列表
      type: Boolean,
      default: false,
    },
    showDel: {
      //上传图片时是否需要删除图片
      type: Boolean,
      default: true,
    },
    defaultList: { type: Array, default: null }, //默认上传列表
    fileLength: {
      // 最多上个文件个数
      type: [String, Number],
      default: 1,
    },
    action: { type: String, default: "" }, //上传地址
  },
  data() {
    return {
      uploadList: [],
      defaultFileList: [],
      visible: false,
      visibleUrl: "",
      listShow:true,
    };
  },
  watch: {
    defaultList: {
      handler(fileList) {
        var defaultFileList = [];
        var type = Object.prototype.toString.call(fileList);
        if (type == "[object Array]") {
          if (fileList.length > 0) {
            if (Object.prototype.toString.call(fileList[0]) == "[object String]") {
              fileList.map((item) => {
                let obj = { url: item, name: item };
                defaultFileList.push(obj);
              });
            } else {
              defaultFileList = fileList;
            }
          }
        } else if (fileList && type == "[object String]") {
          try {
            var files = JSON.parse(fileList);
            if (Object.prototype.toString.call(files[0]) == "[object String]") {
              files.map((item) => {
                let obj = { url: item, name: item };
                defaultFileList.push(obj);
              });
            } else {
              defaultFileList = files;
            }
          } catch (e) {
            defaultFileList = [{ url: fileList, name: fileList }];
          }
        }
        this.defaultFileList = defaultFileList;
        this.$nextTick(() => {
          this.uploadList = this.$refs.upload.fileList;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.headers) {
      var headers = {};
      let token = this.$core.getTokenInfo();
      if (token.accessToken != null) {
        headers.Authorization = "Bearer " + token.accessToken;
      }
      let branch = this.$core.getBranchInfo();
      if (branch.id) {
        headers.branchId = branch.id;
      }
      this.headers = headers;
    }
    if (!this.action) this.action = this.$getProxy("/uploader") + "/upload";
    if (!this.accept && this.type == "image") this.accept = "image/*";
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.$("#upButton").length==0){
        this.listShow=false;
      }
    })
  },
  components: {
    FNCModal,
  },
  methods: {
    async beforeUpload(file) {
      var m = await hashFile(file);
      this.uploadParam = { md5file: m.md5 };
    },
    handleSuccess(res, file) {
      if (file.status == "finished" && res.code == "200" && res.data) {
        file.url = res.data.remotename;
        file.ext = res.data.ext;
      }
      if (!file.url) {
        const fileList = this.$refs.upload.fileList;
        this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        this.$Message["error"]({
          background: true,
          content: "图片上传失败！",
        });
      }
      //上传列表全部上传完成则返回
      var isUping = this.$refs.upload.fileList.some((item) => !item.url);
      if (!isUping) {
        this.callback();
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "上传失败",
        desc:
          "文件" +
          file.name +
          "格式错误，请上传" +
          this.accept +
          "格式的" +
          (this.type == "image" ? "图片" : "文件"),
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "上传失败",
        desc:
          "文件  " +
          file.name +
          " 大小超出限制，请上传" +
          this.maxSize / 1024 +
          "M以内的" +
          (this.type == "image" ? "图片" : "文件"),
      });
    },
    handleView(url) {
      this.visibleUrl = url;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
      //返回
      this.callback();
    },
    callback() {
      this.uploadList = this.$refs.upload.fileList;
      var res = {};
      if (this.multiple==false) {
        if (this.uploadList && this.uploadList.length > 0) {
          res = this.uploadList[this.uploadList.length-1];
          this.uploadList=[res]
        }
      } else {
        res = this.uploadList;
      }
      this.$emit("success", res);
      // if(this.type!="image"){
      //   this.$refs.upload.fileList=[];
      //   this.uploadList = [];
      // }
    },
  },
};
</script>

<style scoped lang="less">
.demo-upload-list1 {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-bottom:5px;
  overflow: hidden;
  position: relative;
  margin-right: 4px;
}
.demo-upload-list {
  display: inline-block;
  width: 84px;
  height: 84px;
  text-align: center;
  line-height: 84px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
