<template>
  <FNCContent>
    <template v-slot:title> 绑定微信公众号 </template>
    <template v-slot:toolsbarLeft>

    </template>
    <template v-slot:toolsbarRight>
    </template>
    <template v-slot:contentArea>
      <template v-if="oaOpenId">
        <div class="desc">您已绑定了微信公众号：{{ oaOpenId }}</div>
        <div class="desc"><Button size="large" type="success" class="binding" @click="() => { binding() }">
            <Icon type="md-infinite" size="25" /> 重新绑定
          </Button></div>
      </template>
      <template v-else>
        <div class="desc">注意：必须关注公众号才能收到设备告警推送信息，请先扫码关注公众号，再扫码授权用户绑定公众号。</div>
        <div class="desc">
          <div><img :src="bindInfo.qrcode || './images/custompage_bg.jpg'" class="img"></div>
          <div class="arrow">
            <div class="fnc-icon fnc-icon-direction-right"></div>
          </div>
          <div>
            <div class="img" id="qrcode"></div>
          </div>
        </div>
        <div class="desc">
          <div class="img">扫码关注公众号</div>
          <div class="arrow"> </div>
          <div class="img">扫码授权绑定公众号</div>
        </div>
      </template>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/bindingweixin
import FNCContent from "@/components/FNCContent";
import QRCode from "qrcodejs2"
export default {
  components: {
    FNCContent, QRCode,
  },

  data() {
    return {
      saveLoading: false,
      oaOpenId: null,
      bindInfo: {},
    };
  },
  mounted() {
    this.oaOpenId = this.$core.getUserInfo("oaOpenId");
    if (!this.oaOpenId) {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.$post({
        url: "/gateway/api/weixinservice/initOaBindingInfo",
        success: (res) => {
          if (res.code == "200") {
            this.bindInfo = res.data
            var url = (location.href + "#").split("#")[0]+"page.html#/weixinauth?token="+this.$core.getTokenInfo("accessToken")+"&bindingKey="+this.bindInfo.key+"&weixinAppId="+this.bindInfo.appId
            new QRCode("qrcode", {
              text: url,
              width: 280,
              height: 280,
            })
            this.checkBinding();
          }
        }
      })
    },
    binding() {
      this.oaOpenId = null;
      this.loadData();
    },
    checkBinding() {
      this.$get({
        url: "/gateway/api/weixinservice/checkOaBinding",
        data: { key: this.bindInfo.key },
        success: (res) => {
          if (res.code == "200") {
            //刷新token
            var oemInfo = this.$core.getPortalConfig();
            var data = {
              client_id: oemInfo.client_id,
              client_secret: oemInfo.password,
              scope: oemInfo.scope,
              grant_type: "refresh_token",
              refresh_token: this.$core.getTokenInfo("refreshToken"),
            }
            this.$post({ url: "/gateway/oauth/login", data: data, header: { other: "noSend" } }).then((res) => {
              if (res.code == "200") {
                top.tokenInfo = null
                window.sessionStorage.setItem("userCode", this.$core.encrypt(JSON.stringify(res.data)))
                this.oaOpenId = this.$core.getUserInfo("oaOpenId");
                this.$Message.success({
                  background: true,
                  content: "已成功绑定微信公众号",
                  duration: 3,
                })
              } else {
                this.$Message.error({
                  background: true,
                  content: "无法重置帐号信息，请退出重新登录。",
                  duration: 3,
                })
              }
            })
          } else {
            setTimeout(this.checkBinding, 3000);
          }
        }
      })
    },
    submitData() {

    }
  }
};
</script>
    
<style scoped lang='less'>
.desc {
  width: 100%;
  padding: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;

  .arrow {
    width: 300px;
    text-align: center;

    div {
      font-size: 80px;
      color: #888;
    }
  }

  .img {
    width: 300px;
    text-align: center;
  }

  .binding {
    width: 300px;
  }
}
</style>