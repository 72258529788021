<template>
  <FNCContent>
    <template v-slot:title>多端页面定制</template>
    <template v-slot:toolsbarRight>
      <div style="width: calc(100vw - 400px); margin-top: 0px">
        <Breadcrumb separator=">" style="float: left; height: 20px; background-color: #fafafa">
          <BreadcrumbItem v-for="(item, index) in bcTitle" :key="index" style="margin-top: 0px; height: 20px">{{ item }}
          </BreadcrumbItem>
        </Breadcrumb>

        <Button type="error" style="float: right; margin-top: 0px; width: 100px" icon="ios-share-outline"
          :loading="saveLoading" :disabled="saveLoading" @click="submit">保存</Button>
        <APIreadme style="float: right; margin-top: -5px" :tips="false" />
        <Tooltip content="在PC浏览器访问时需在调试状态下切换仿真设备" max-width="200" placement="left" style="float: right; margin-top: 0px">
          <Button @click="openView" v-if="currentFormData &&
            currentFormData.sysModuleId &&
            currentFormData.action &&
            currentFormData.action.type == 'local' &&
            currentFormData.action.url
            " type="success">在新窗口预览</Button>
        </Tooltip>
      </div>
    </template>
    <template v-slot:contentArea>
      <div class="leftMenu">
        <Tabs v-model="tabs1" type="card">
          <TabPane label="菜单" name="menuSet" class="leftMenuSet">
            <Tree :data="menuList" :load-data="getInitData" :render="renderContent" @on-select-change="select"
              class="demo-tree-render">
            </Tree>
          </TabPane>
          <TabPane label="组件" name="compSet" class="leftMenuSet">
            <Draggable id="componentsGroup" :group="{ name: 'form', pull: 'clone', put: false }" :sort="false"
              :animation="200" style="width: 90%; margin: 0 5%" @sort="(e) => {
                  handSort(e, 'componentsGroup');
                }
                ">
              <!-- 组件库 -->
              <Banner ref="Banner" class="banner-swiper" type="Banner" title="Banner组件" />
              <Menu ref="Menu" class="banner-swiper" type="Menu" title="宫格菜单组件" />
              <SeparationLine ref="SeparationLine" class="banner-swiper" type="SeparationLine" title="分隔线组件" />
              <RichText ref="RichText" class="banner-swiper" type="RichText" title="富文本组件" />
              <Search ref="Search" class="banner-swiper" type="Search" title="搜索栏组件" />
              <LFTabs ref="LFTabs" class="banner-swiper" type="LFTabs" title="标签页组件" />
              <Information ref="Information" class="banner-swiper" type="Information" title="资讯组件" />
              <InfoView ref="InfoView" class="banner-swiper" type="InfoView" title="信息详情组件" />
              <PictureGroup ref="PictureGroup" class="banner-swiper" type="PictureGroup" title="图片集组件" />
              <ListComp ref="List" class="banner-swiper" type="List" title="列表组件" />
              <FormsComp ref="Forms" class="banner-swiper" type="Forms" title="表单组件" />
              <Msg ref="Msg" class="banner-swiper" type="Msg" title="消息组件" />
              <GoodsList ref="GoodsList" class="banner-swiper" type="GoodsList" title="商品组件" />
            </Draggable>
          </TabPane>
        </Tabs>
      </div>

      <div class="centerEdit">
        <Tabs v-model="tabs2" type="card">
          <TabPane label="界面定制" name="vEditSet" class="centerEditSet">
            <div :class="centerEditSetZoom" id="centerEditSetZoom"
              :style="'background-color:' + currentFormData.backgroundColor">
              <!-- 头部：主页面风格 -->
              <div type="header" title="头部主页面风格" class="FNC-header-index component" :style="'background-image:url(\'' +
                currentFormData.header.bgImage +
                '\');background-size:100% auto;background-color:' +
                currentFormData.barBackgroundColor
                " v-if="currentFormData.header && currentFormData.header.display">
                <div class="header-index-content">
                  <div class="title" :style="'color:' +
                    barTextStyle +
                    ';text-align: ' +
                    (currentFormData.header.textAlign || 'left') +
                    ';'
                    " v-html="currentFormData.title" v-if="currentFormData.header.showTitle"></div>
                  <Draggable id="headerCompGroup" :group="{ name: 'form', pull: true }" :sort="true" :animation="200"
                    style="width: 100%; height: 100%" @sort="(e) => {
                        handSort(e, 'headerCompGroup');
                      }
                      ">
                  </Draggable>
                  <div
                    :class="currentFormData.header.radiusImmersion ? 'FNC-header-index-radius-immersion' : 'FNC-header-index-radius'"
                    v-if="currentFormData.header.radius"></div>
                </div>
              </div>
              <!-- 头部：导航风格 -->
              <div type="navbar" title="头部导航风格" class="FNC-header-index component"
                :style="'background:' + currentFormData.barBackgroundColor"
                v-if="currentFormData.navbar && currentFormData.navbar.display">
                <div class="header-index-content" :style="'display: flex;align-items: center;width:100%;color:' +
                  barTextStyle +
                  ';'
                  ">
                  <i :class="'- fnc-icon ' +
                    (currentFormData.navbar.leftIconType || 'fnc-icon-direction-left')
                    " style="font-size: 25px; margin-left: 10px"></i>
                  <div class="title" :style="'width:calc(100% - 100px);overflow:hidden;text-align:center;color:' +
                    barTextStyle
                    " v-html="currentFormData.title"></div>
                  <i :class="'- fnc-icon ' + currentFormData.navbar.rightSecondIconType"
                    style="font-size: 25px; margin-right: 5px" v-if="currentFormData.navbar.rightSecondIconType"></i>
                  <i :class="'- fnc-icon ' + currentFormData.navbar.rightFirstIconType"
                    style="font-size: 25px; margin-right: 10px" v-if="currentFormData.navbar.rightFirstIconType"></i>
                </div>
              </div>
              <!-- 内容区域 -->
              <div class="FNC-header-index" v-if="currentFormData.content && currentFormData.content.display">
                <Draggable id="contentCompGroup" :group="{ name: 'form', pull: true }" :sort="true" :animation="200"
                  style="width: 96%; margin: 0 2%; min-height: 200px; height: 100%" @sort="(e) => {
                      handSort(e, 'contentCompGroup');
                    }
                    ">
                </Draggable>
              </div>
              <!-- 底部导航 -->
              <div type="Tabbar" title="底部导航" class="FNC-tabbar-index component"
                v-if="currentFormData.tabbar && currentFormData.tabbar.display"></div>
              <div style="height: 55px" v-if="currentFormData.tabbar && currentFormData.tabbar.display"></div>
            </div>
          </TabPane>
          <TabPane label="预览" name="reviewSet" class="centerEditSet">
            <div :class="centerEditSetZoom" id="centerEditSetZoomView" style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              ">
              <iframe v-if="tabs2 == 'reviewSet'" :src="currentFormData && currentFormData.action
                  ? domain +
                  '/#' +
                  currentFormData.action.url +
                  (currentFormData.action.url.indexOf('?') == -1 ? '?' : '&') +
                  'menuCode=' +
                  currentFormData.code
                  : ''
                " style="width: 100%; height: 100%" frameborder="0"></iframe>
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div class="rightEdit">
        <Tabs v-model="tabs3" type="card">
          <TabPane label="全局设置" name="globalSet" class="rightMenuSet">
            <div v-if="JSON.stringify(currentFormData) !== '{}'" style="position: relative; width: 99%">
              <Form :model="currentFormData" :label-width="120" :rules="validateForm" ref="form" id="form">
                <FormItem label="栏目ID" v-if="currentFormData.sysModuleId">
                  <Input disabled v-model.trim="currentFormData.sysModuleId"></Input>
                </FormItem>
                <FormItem label="栏目代码" v-if="currentFormData.code">
                  <Input disabled v-model.trim="currentFormData.code"></Input>
                </FormItem>
                <FormItem label="栏目名称" prop="title">
                  <Input v-model.trim="currentFormData.title" placeholder="栏目名称不能为空"></Input>
                </FormItem>
                <FormItem label="设置为引导页">
                  <RadioGroup v-model="currentFormData.isGuide" @on-change="setGuideConfig">
                    <Radio label="1">是</Radio>
                    <Radio label="0">否</Radio>
                  </RadioGroup>
                </FormItem>
                <template v-if="currentFormData.isGuide == '1'">
                  <FormItem label="自动跳转首页">
                    <RadioGroup v-model="currentFormData.isHomeService">
                      <Radio label="1">是</Radio>
                      <Radio label="0">否（进入登录页）</Radio>
                    </RadioGroup>
                  </FormItem>

                  <FormItem label="关联商户">
                    <Select v-model="currentFormData.branchId" filterable clearable :remote-method="getBranchInfo"
                      :loading="branchLoading" placeholder="输入商户名/商户ID查找">
                      <Option v-for="(option, index) in branchInfo" :value="option.id" :key="index">{{ option.branchname
                      }}
                      </Option>
                    </Select>
                  </FormItem>

                  <FormItem label="首页跳转地址" style="display: flex; flex-direction: column">
                    <div style="display: flex; margin: 2px; margin-left: -100px">
                      <Select v-model="currentFormData.action.type" style="width: 130px; margin-right: 2px"
                        placeholder="跳转类型" @on-change="changeActionType">
                        <Option value="local">本地地址</Option>
                      </Select>
                      <Input v-model.trim="currentFormData.action.url">
                      <div slot="prepend">
                        <Select placeholder="模板" style="width: 70px" v-model="modelValue" @on-change="changeActionModel">
                          <Option value="/pages/custom/customindex">通用自定义页</Option>
                          <Option value="/pages/custom/tab1index">通用TAB页一</Option>
                          <Option value="/pages/custom/tab2index">通用TAB页二</Option>
                          <Option value="/pages/custom/tab3index">通用TAB页三</Option>
                          <Option value="/pages/custom/tab4index">通用TAB页四</Option>
                          <Option value="/pages/custom/tab5index">通用TAB页五</Option>
                        </Select>
                      </div>
                      </Input>
                    </div>
                  </FormItem>
                  <div style="display: flex">
                    <FormItem label="页面背景">
                      <ColorPicker v-model="currentFormData.backgroundColor" recommend @on-active-change="(e) => {
                          colorChange(e, 'backgroundColor');
                        }
                        " />
                    </FormItem>
                    <FormItem label="应用LOGO">
                      <FNCUpload @success="(res) => {
                          uploadSuccess('menuIcon', res);
                        }
                        " v-if="showFNCUpload" type="image" accept=".jpg,.png,.gif,.jpeg"
                        :defaultList="currentFormData.menuIcon" :showView="true"></FNCUpload>
                    </FormItem>
                  </div>

                  <div style="display: flex">
                    <FormItem label="按钮颜色">
                      <ColorPicker v-model="currentFormData.barBackgroundColor" recommend @on-active-change="(e) => {
                          colorChange(e, 'barBackgroundColor');
                        }
                        " />
                    </FormItem>

                    <FormItem label="引导图片">
                      <FNCUpload @success="(res) => {
                          uploadSuccess('headerBg', res);
                        }
                        " v-if="showFNCUpload" type="image" accept=".jpg,.png,.gif,.jpeg"
                        :defaultList="currentFormData.header.bgImage" :showView="true"></FNCUpload>
                    </FormItem>
                  </div>
                  <FormItem label="版权信息">
                    <Input type="textarea" v-model="currentFormData.copyRight" rows="5" placeholder="换行多行显示" />
                  </FormItem>
                  <FormItem label="登录方式">
                    <CheckboxGroup v-model="currentFormData.checkInType">
                      <Checkbox label="password">密码</Checkbox>
                      <Checkbox label="weixin">微信</Checkbox>
                      <Checkbox label="captcha">验证码</Checkbox>
                    </CheckboxGroup>
                  </FormItem>
                  <FormItem label="是否游客浏览">
                    <RadioGroup v-model="currentFormData.isGuest">
                      <Radio :label="1">是</Radio>
                      <Radio :label="0">否</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="是否可注册">
                    <RadioGroup v-model="currentFormData.isUserReg">
                      <Radio :label="1">是</Radio>
                      <Radio :label="0">否</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="是否可取密">
                    <RadioGroup v-model="currentFormData.isRepass">
                      <Radio :label="1">是</Radio>
                      <Radio :label="0">否</Radio>
                    </RadioGroup>
                  </FormItem>
                </template>
                <template v-else>
                  <FormItem label="是否菜单">
                    <RadioGroup v-model="currentFormData.isMenu">
                      <Radio :label="1">是</Radio>
                      <Radio :label="0">否</Radio>
                    </RadioGroup>
                  </FormItem>
                  <div style="display: flex">
                    <FormItem label="菜单图标">
                      <FNCUpload @success="(res) => {
                          uploadSuccess('menuIcon', res);
                        }
                        " v-if="showFNCUpload" type="image" accept=".jpg,.png,.gif,.jpeg"
                        :defaultList="currentFormData.menuIcon" :showView="true">
                      </FNCUpload>
                    </FormItem>
                    <FormItem label="菜单选中图标">
                      <FNCUpload @success="(res) => {
                          uploadSuccess('menuIconSlt', res);
                        }
                        " v-if="showFNCUpload" type="image" accept=".jpg,.png,.gif,.jpeg"
                        :defaultList="currentFormData.menuIconSlt" :showView="true">
                      </FNCUpload>
                    </FormItem>

                  </div>

                  <FormItem label="绑定后台功能">
                    <div style="display: flex">
                      <Button @click="showSltPFModalFn" :title="currentFormData.columnName || '请选择...'">
                        <div style="
                            max-width: 300px;
                            overflow: hidden;
                            float: left;
                            margin-top: 5px;
                          ">
                          {{ currentFormData.columnName || "请选择..." }}
                        </div>
                        <Icon style="margin: 8px 0 0 10px; float: left" type="ios-arrow-down" />
                      </Button>
                      <Icon style="
                          margin: 3px 0 0 10px;
                          float: left;
                          font-size: 25px;
                          cursor: pointer;
                        " title="清除所有绑定" type="md-trash" @click="clearColumn" />
                    </div>
                  </FormItem>

                  <FormItem label="关联商户">
                    <Select v-model="currentFormData.branchId" filterable clearable :remote-method="getBranchInfo"
                      :loading="branchLoading" placeholder="输入商户名/商户ID查找">
                      <Option v-for="(option, index) in branchInfo" :value="option.id" :key="index">{{ option.branchname
                      }}
                      </Option>
                    </Select>
                  </FormItem>

                  <FormItem label="页面跳转地址" style="display: flex; flex-direction: column">
                    <div style="display: flex; margin: 2px; margin-left: -100px">
                      <Select v-model="currentFormData.action.type" style="width: 130px; margin-right: 2px"
                        placeholder="跳转类型" @on-change="changeActionType">
                        <Option value="local">本地地址</Option>
                        <Option value="app">跳转APP</Option>
                        <Option value="weapp">跳转小程序</Option>
                        <Option value="web">跳转网页</Option>
                      </Select>
                      <Input v-model.trim="currentFormData.action.url">
                      <div slot="prepend">
                        <Select placeholder="模板" style="width: 70px" v-model="modelValue" @on-change="changeActionModel"
                          v-if="currentFormData.action.type == 'local'">
                          <Option value="/pages/custom/customindex">通用自定义页</Option>
                          <Option value="/pages/custom/tab1index">通用TAB页一</Option>
                          <Option value="/pages/custom/tab2index">通用TAB页二</Option>
                          <Option value="/pages/custom/tab3index">通用TAB页三</Option>
                          <Option value="/pages/custom/tab4index">通用TAB页四</Option>
                          <Option value="/pages/custom/tab5index">通用TAB页五</Option>
                        </Select>
                        <div v-else style="color: #999">页面地址</div>
                      </div>
                      </Input>
                    </div>
                    <div label="" style="display: flex; flex-direction: column">
                      <div style="display: flex; margin: 2px; margin-left: 5px"
                        v-if="currentFormData.action.type == 'app'">
                        <Input v-model.trim="currentFormData.action.package">
                        <div slot="prepend" style="color: #999">APP主包名</div>
                        </Input>
                      </div>
                      <div style="display: flex; margin: 2px; margin-left: 5px"
                        v-if="currentFormData.action.type == 'weapp'">
                        <Input v-model.trim="currentFormData.action.appid">
                        <div slot="prepend" style="color: #999">原始APPID</div>
                        </Input>
                      </div>
                    </div>
                  </FormItem>
                  <div style="padding: 0 20px 20px 30px; color: #cc000099">
                    注：仅“页面跳转地址”为“本地地址”时，界面定制才生效。
                  </div>
                  <FormItem label="终端类型">
                    <RadioGroup v-model="currentFormData.terminal" @on-change="changeTerminal">
                      <Radio label="vertical">竖屏（手机）界面（750*Npx）</Radio>
                      <Radio label="horizontal">横屏（TV）界面（1920*1080px）</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="顶部区域">
                    <RadioGroup @on-change="changeHeaderRadio" :value="!currentFormData.header.display && !currentFormData.navbar.display
                        ? '1'
                        : currentFormData.header.display
                          ? '2'
                          : currentFormData.navbar.display
                            ? '3'
                            : ''
                      ">
                      <Radio label="1">无</Radio>
                      <Radio label="2">主页面风格</Radio>
                      <Radio label="3">带返回导航风格</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="内容区域">
                    <RadioGroup v-model="currentFormData.content.display">
                      <Radio :label="false">无</Radio>
                      <Radio :label="true">有</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="底部导航">
                    <RadioGroup v-model="currentFormData.tabbar.display" @on-change="() => {
                        changeTerminal(currentFormData.terminal);
                      }
                      ">
                      <Radio :label="false">无</Radio>
                      <Radio :label="true">有</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="顶部拉取刷新">
                    <RadioGroup v-model="currentFormData.refresherTrigger">
                      <Radio :label="true">启用</Radio>
                      <Radio :label="false">禁用</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="切入前台时">
                    <RadioGroup v-model="currentFormData.refresh">
                      <Radio :label="false">不刷新</Radio>
                      <Radio :label="true">自动刷新</Radio>
                    </RadioGroup>
                  </FormItem>
                  <div style="display: flex">
                    <FormItem label="状态栏主色">
                      <ColorPicker v-model="currentFormData.barBackgroundColor" recommend @on-active-change="(e) => {
                          colorChange(e, 'barBackgroundColor');
                        }
                        " />
                    </FormItem>

                    <FormItem label="顶部背景图">
                      <FNCUpload @success="(res) => {
                          uploadSuccess('headerBg', res);
                        }
                        " v-if="showFNCUpload" type="image" accept=".jpg,.png,.gif,.jpeg"
                        :defaultList="currentFormData.header.bgImage" :showView="true"></FNCUpload>
                    </FormItem>
                  </div>
                  <FormItem label="页面背景">
                    <ColorPicker v-model="currentFormData.backgroundColor" recommend @on-active-change="(e) => {
                        colorChange(e, 'backgroundColor');
                      }
                      " />
                  </FormItem>
                </template>

                <FormItem label="排序">
                  <InputNumber :min="1" v-model.trim="currentFormData.seq"></InputNumber>
                </FormItem>
                <FormItem label="启禁用">
                  <RadioGroup v-model="currentFormData.enableStatus">
                    <Radio :label="1">启用</Radio>
                    <Radio :label="2">禁用</Radio>
                  </RadioGroup>
                </FormItem>
              </Form>
            </div>
          </TabPane>
          <TabPane label="组件设置" name="compoSet" class="rightMenuSet">
            <div style="width: 99%; display: flex; items-align: center; margin: 5px 0" v-if="selectedComponent">
              <div style="
                  width: calc(100% - 80px);
                  font-size: 16px;
                  color: #515a6e;
                  font-weight: bold;
                ">
                {{ selectedComponent.getAttribute("title") }}
              </div>
              <Button style="width: 80px" type="warning" @click="removeComponent">移除组件</Button>
            </div>
            <div style="width: 99%; display: flex; flex-direction: column" v-if="selectedComponent">
              <!-- 组件参数设置 -->
              <Tabbar :target="selectedComponent" :data="selectedData"
                v-if="selectedComponent.getAttribute('type') == 'Tabbar'" />
              <Header :target="selectedComponent" :data="selectedData"
                v-if="selectedComponent.getAttribute('type') == 'header'" />
              <Navbar :target="selectedComponent" :data="selectedData"
                v-if="selectedComponent.getAttribute('type') == 'navbar'" />
              <Banner class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Banner'" />
              <Menu class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Menu'" />
              <Information class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Information'" />
              <InfoView class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'InfoView'" />
              <PictureGroup class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'PictureGroup'" />
              <ListComp class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'List'" />
              <FormsComp class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Forms'" />
              <Msg class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Msg'" />
              <GoodsList class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'GoodsList'" />
              <LFTabs class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'LFTabs'" />
              <Search class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'Search'" />
              <SeparationLine class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'SeparationLine'" />
              <RichText class="banner-swiper" :target="selectedComponent" :data="selectedData" :isParamComp="true"
                v-if="selectedComponent.getAttribute('type') == 'RichText'" />
            </div>
          </TabPane>
        </Tabs>
      </div>

      <FNCModal title="移动/复制" width="50%" height="calc(100vh - 200px)" ref="moveMenu">
        <template #contentarea>
          <Tree :data="moveMenuList" :load-data="getInitMoveData" @on-select-change="moveSelectChange"></Tree>
        </template>
        <template #toolsbar>
          <Button @click="moveMenuStatusFn(false)" style="margin-right: 10px">取消</Button>
          <Button type="success" @click="saveMove" style="margin-right: 10px">移动到此节点中</Button>
          <Button type="info" @click="saveCopy" style="margin-right: 10px">复制到此节点中</Button>
        </template>
      </FNCModal>
      <FNCModal ref="chooseModule" title="选择后台功能" width="50%" height="calc(100vh - 200px)">
        <template #contentarea>
          <Tree :data="pfMenuList" :load-data="getPFData" @on-check-change="pfSelectChange" show-checkbox check-strictly>
          </Tree>
        </template>
      </FNCModal>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/custompage
import FNCContent from "@/components/FNCContent";
import FNCModal from "@/components/FNCModal";
import FNCUpload from "@/components/FNCUpload";
import Draggable from "vuedraggable";
import "/public/fontlibs/fnciconfont.css";
import Banner from "./components/Banner";
import Tabbar from "./components/Tabbar";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import Information from "./components/Information";
import InfoView from "./components/InfoView";
import PictureGroup from "./components/PictureGroup";
import ListComp from "./components/List";
import Msg from "./components/Msg";
import GoodsList from "./components/GoodsList";
import LFTabs from "./components/LFTabs";
import Search from "./components/Search";
import SeparationLine from "./components/SeparationLine";
import RichText from "./components/RichText";
import FormsComp from "./components/Forms";
import APIreadme from "./components/APIreadme";
export default {
  components: {
    FNCContent,
    FNCModal,
    FNCUpload,
    Draggable,
    Banner,
    Tabbar,
    Header,
    Navbar,
    Menu,
    Information,
    InfoView,
    PictureGroup,
    ListComp,
    Msg,
    GoodsList,
    LFTabs,
    Search,
    SeparationLine,
    RichText,
    FormsComp,
    APIreadme,
  },
  data() {
    return {
      tabs1: "menuSet",
      tabs2: "vEditSet",
      tabs3: "globalSet",
      centerEditSetZoom: "centerEditSet-zoom",
      modelValue: "none",
      compCount: 0,
      modify: false,
      menuList: [
        {
          title: "主菜单",
          sysModuleId: "0",
          code: "0",
          expand: true,
          render: (h, { root, node, data }) => {
            return h(
              "span",
              {
                style: {
                  display: "inline-block",
                  width: "100%",
                },
              },
              [
                h("span", data.title),
                h(
                  "span",
                  {
                    style: {
                      display: "inline-block",
                      float: "right",
                      marginRight: "12px",
                    },
                  },
                  [
                    h("Button", {
                      props: Object.assign({}, this.buttonProps, {
                        icon: "ios-add",
                        type: "primary",
                      }),
                      style: {
                        width: "90px",
                        fontSize: "16px",
                      },
                      on: {
                        click: (e) => {
                          e.stopPropagation();
                          this.append(data);
                        },
                      },
                    }),
                  ]
                ),
              ]
            );
          },
          code: "0",
          children: [],
        },
      ],
      buttonProps: {
        type: "default",
        size: "small",
      },
      currentFormData: {},
      initData: {
        //json配置,对照表中的configJson
        sysModuleId: "", //sysModuleId
        parentId: "", //parentId
        branchId: "",
        code: "", //code
        title: "", //name
        columnCode: "", //columnCode
        columnName: "",
        isGuide: "0",
        refresherTrigger: false,
        isMenu: 1, //type
        action: { type: "local", url: "", appid: "", package: "" }, //jumpJson
        terminal: "vertical",
        menuIcon: "", //icon
        menuIconSlt: "", //icon selected
        barBackgroundColor: "#BE1C2C",
        backgroundColor: "",
        seq: 99, //seq
        checkInType: ["password"],
        isGuest: 1,
        isUserReg: 1,
        isRepass: 1,
        enableStatus: 1,
        header: {
          display: true,
          bgImage: "",
          compFixed: false,
          radius: true,
          radiusImmersion: false,
          full: false,
          showTitle: true,
          textAlign: "left",
          components: [],
        },
        navbar: {
          display: false,
          leftIconType: "fnc-icon-direction-left",
          leftText: "",
          rightFirstIconType: "",
          rightSecondIconType: "",
          changeLeftClick: false,
          onClickRgIconStUrl: "",
          onClickRgIconNdUrl: "",
          onClickLeftIconUrl: "",
        },
        tabbar: {
          display: false,
          data: "",
        },
        content: {
          display: true,
          data: [],
        },
      },
      barTextStyle: "#ffffff",
      showFNCUpload: false,
      validateForm: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      selectedComponent: null, //当前选中的控件
      selectedData: {}, //当前选中的控件的数据
      bcTitle: [],
      // 移动
      moveMenuList: [],
      moveObj: {},
      moveParentCode: "",
      pfMenuList: [
        {
          title: "主目录",
          typeid: "0",
          expand: true,
          children: [],
        },
      ],
      saveLoading: false,
      domain: "",
      branchLoading: false,
      branchInfo:[],
    };
  },
  // watch:{
  //   "selectedData": {
  //     handler(newData, oldData) {
  //        console.log('obj.a changed',this.selectedComponent);
  //     },
  //     immediate: false
  //   }
  // },
  methods: {
    openView() {
      var url =
        this.$getProxy("/custom") +
        "/#" +
        this.currentFormData.action.url +
        "?menuCode=" +
        this.currentFormData.code;
      window.open(url, "custompage");
    },
    handSort(e, from) {
      //处理重复事件
      if (from != e.from.id) return;
      // console.log("eeeeeeeeee",e);
      //从组件库拖拉
      if (e.from.id == "componentsGroup") {
        e.item.id = "Comp_" + this.compCount++;
        e.item.className = e.item.className + " component";
        var comp = this.$refs[e.item.getAttribute("type")].initData;
        comp = JSON.parse(JSON.stringify(comp));
        //头部组件
        if (e.to.id == "headerCompGroup") {
          this.currentFormData.header.components.splice(e.newIndex, 0, comp);
          //内容组件
        } else if (e.to.id == "contentCompGroup") {
          this.currentFormData.content.data.splice(e.newIndex, 0, comp);
        }
        //  从头部组件拖拉
      } else if (e.from.id == "headerCompGroup") {
        //头部组件
        if (e.to.id == "headerCompGroup") {
          this.currentFormData.header.components[
            e.oldIndex
          ] = this.currentFormData.header.components.splice(
            e.newIndex,
            1,
            this.currentFormData.header.components[e.oldIndex]
          )[0];
          //内容组件
        } else if (e.to.id == "contentCompGroup") {
          this.currentFormData.content.data.splice(
            e.newIndex,
            0,
            JSON.parse(JSON.stringify(this.currentFormData.header.components[e.oldIndex]))
          );
          this.currentFormData.header.components.splice(e.oldIndex, 1);
        }
        // 从内容组件拖拉
      } else if (e.from.id == "contentCompGroup") {
        //头部组件
        if (e.to.id == "headerCompGroup") {
          this.currentFormData.header.components.splice(
            e.newIndex,
            0,
            JSON.parse(JSON.stringify(this.currentFormData.content.data[e.oldIndex]))
          );
          this.currentFormData.content.data.splice(e.oldIndex, 1);
          //内容组件
        } else if (e.to.id == "contentCompGroup") {
          this.currentFormData.content.data[
            e.oldIndex
          ] = this.currentFormData.content.data.splice(
            e.newIndex,
            1,
            this.currentFormData.content.data[e.oldIndex]
          )[0];
        }
      }
      // console.log("this.currentFormData",this.currentFormData);
    },

    colorChange(color, target) {
      this.currentFormData[target] = color;
      if (target == "barBackgroundColor") {
        if (color) {
          this.barTextStyle = this.$core.ColorIsLight(color) ? "#333333" : "#ffffff";
        } else {
          this.barTextStyle = "#333333";
        }
      }
    },
    changeActionType(e) {
      this.currentFormData.action.url = "";
      this.currentFormData.action.appid = "";
      this.currentFormData.action.package = "";
    },
    changeActionModel(e) {
      if (e != undefined) {
        this.currentFormData.action.url = e;
        this.modelValue = "none";
      }
    },
    changeTerminal(e) {
      if (e == "horizontal") {
        this.centerEditSetZoom = "centerEditSet-h-zoom";
        this.$nextTick((e) => {
          setTimeout(() => {
            var w = this.$("#centerEditSetZoom").width();
            var h = (w * 1080) / 1920;
            this.$("#centerEditSetZoom").height(h);
            this.$("#centerEditSetZoomView").height(h);
            this.$(".FNC-tabbar-index").css("left", "calc((100% - " + w + "px)/2)");
            this.$(".FNC-tabbar-index").css("right", "calc((100% - " + w + "px)/2)");
            this.$(".FNC-tabbar-index").css("bottom", "calc((100% - " + h + "px)/2)");
          }, 350);
        });
      } else {
        this.centerEditSetZoom = "centerEditSet-zoom";
        this.$nextTick((e) => {
          setTimeout(() => {
            this.$("#centerEditSetZoom").height("calc(100% - 40px)");
            this.$("#centerEditSetZoomView").height("calc(100% - 40px)");
            this.$(".FNC-tabbar-index").css("left", "calc((100% - 400px)/2)");
            this.$(".FNC-tabbar-index").css("right", "calc((100% - 400px)/2)");
            this.$(".FNC-tabbar-index").css("bottom", "20px");
          }, 350);
        });
      }
    },
    changeHeaderRadio(e) {
      switch (e) {
        case "2":
          this.currentFormData.header.display = true;
          this.currentFormData.navbar.display = false;
          break;
        case "3":
          this.currentFormData.header.display = false;
          this.currentFormData.navbar.display = true;
          break;
        default:
          this.currentFormData.header.display = false;
          this.currentFormData.navbar.display = false;
          break;
      }
    },
    getCompData() {
      //获取组件数据
      this.selectedData = {};
      if (this.selectedComponent.getAttribute("type") == "header") {
        this.selectedData = this.currentFormData.header;
      } else if (this.selectedComponent.getAttribute("type") == "navbar") {
        this.selectedData = this.currentFormData.navbar;
      } else if (this.selectedComponent.getAttribute("type") == "Tabbar") {
        this.selectedData = this.currentFormData.tabbar;
      } else {
        var parents = this.selectedComponent.parentElement;
        var id = parents.id;
        if (parents.children && parents.children.length > 0) {
          for (var i = 0; i < parents.children.length; i++) {
            if (parents.children[i].id == this.selectedComponent.id) {
              switch (id) {
                case "headerCompGroup":
                  this.selectedData = this.currentFormData.header.components[i];
                  break;
                case "contentCompGroup":
                  this.selectedData = this.currentFormData.content.data[i];
                  break;
              }
              break;
            }
          }
        }
      }
      // console.log("this.selectedData", this.selectedData);
    },
    deleteCompData() {
      //删除组件数据
      var parents = this.selectedComponent.parentElement;
      var id = parents.id;
      if (parents.children && parents.children.length > 0) {
        for (var i = 0; i < parents.children.length; i++) {
          if (parents.children[i].id == this.selectedComponent.id) {
            if (id == "headerCompGroup") {
              this.currentFormData.header.components.splice(i, 1);
            } else if (id == "contentCompGroup") {
              this.currentFormData.content.data.splice(i, 1);
            }
            // console.log(this.currentFormData);
            break;
          }
        }
      }
    },
    // setCompData(data){//设置组件数据
    //   var parents=this.selectedComponent.parentElement;
    //   var id=parents.id;
    //   this.selectedData=data;
    //   if(parents.children&&parents.children.length>0){
    //     for(var i=0;i<parents.children.length;i++){
    //       if(parents.children[i].id==this.selectedComponent.id){
    //         if(id=="headerCompGroup"){
    //           this.currentFormData.header.components[i]=data;
    //         }else if(id=="contentCompGroup"){
    //           this.currentFormData.content.data[i]=data;
    //         }
    //         console.log(i,this.currentFormData.header.components[i]);
    //       break;
    //       }
    //     }
    //   }
    // },
    uploadSuccess(target, file) {
      if (target == "menuIcon") {
        this.currentFormData.menuIcon = file.url;
      } else if (target == "menuIconSlt") {
        this.currentFormData.menuIconSlt = file.url;
      } else if (target == "headerBg") {
        this.currentFormData.header.bgImage = file.url;
      }
    },
    removeComponent() {
      if (this.selectedComponent) {
        this.$Modal.confirm({
          title: "移除组件",
          content: "移除组件后，设置的数据将一并删除，是否移除？",
          onOk: () => {
            switch (this.selectedComponent.getAttribute("type")) {
              case "header":
                this.currentFormData.header.display = false;
                break;
              case "navbar":
                this.currentFormData.navbar.display = false;
                break;
              case "Tabbar":
                this.currentFormData.tabbar.display = false;
                break;
              default:
                //移除数据
                this.deleteCompData();
                this.selectedData = null;
                this.$(this.selectedComponent).remove();
                break;
            }
            this.selectedComponent = null;
            this.tabs3 = "globalSet";
          },
        });
      }
    },
    saveMove() {
      this.$Modal.confirm({
        title: "移动层级",
        content: "移除层级及其所有子层级菜单，是否继续？",
        onOk: () => {
          this.$post({
            url: "/gateway/api/manage/custompage/moveSystemModule",
            data: {
              oldModuleCode: this.moveObj.code,
              newModuleCode: this.moveParentCode,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "已成功移动至指定节点。",
                });
                setTimeout(() => {
                  this.moveMenuStatusFn(false);
                  this.getInitData({ code: "0" });
                }, 500);
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              this.$Message.error({
                background: true,
                content: "移动失败，请重试",
              });
            });
        },
      });
    },
    saveCopy() {
      this.$post({
        url: "/gateway/api/manage/custompage/copySystemModule",
        data: {
          oldModuleCode: this.moveObj.code,
          newModuleCode: this.moveParentCode,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "已成功复制至指定节点。",
            });
            setTimeout(() => {
              this.moveMenuStatusFn(false);
              this.getInitData({ code: "0" });
            }, 500);
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$Message.error({
            background: true,
            content: "复制失败，请重试",
          });
        });
    },
    moveSelectChange(arr, cur) {
      this.moveParentCode = cur.code;
    },
    moveMenuStatusFn(status) {
      if (status == true) {
        this.$refs.moveMenu.showModal();
      } else {
        this.$refs.moveMenu.hideModal();
      }
    },

    // 过滤
    delMoveObj(id, list) {
      list.map((item, index, arr) => {
        if (id == item.sysModuleId) {
          arr.splice(index, 1);
        } else if (item.children && item.children.length > 0) {
          this.delMoveObj(id, item.children);
        }
      });
    },
    move(data) {
      this.moveObj = JSON.parse(JSON.stringify(data));
      this.moveMenuStatusFn(true);
      this.moveMenuList = JSON.parse(JSON.stringify(this.menuList));
      this.delMoveObj(data.sysModuleId, this.moveMenuList);
    },

    // 更新列表
    updateData(data, parent, parentId) {
      if (parentId == undefined) parentId = data[0].parentId;
      if (parent == undefined) parent = this.menuList;
      //写入数据
      for (let k in parent) {
        if (parent[k].code == parentId) {
          parent[k].expand = true;
          parent[k].children = data;
          return;
        } else if (parent[k].children && parent[k].children.length > 0) {
          this.updateData(data, parent[k].children, parentId);
        }
      }
    },

    submit() {
      this.$refs.form.validate((status) => {
        if (status) {
          if (!this.currentFormData.parentId) {
            this.$Message.error({
              background: true,
              content: "数据不完整，请刷新页面重新编辑。",
            });
            return;
          }

          this.saveLoading = true;
          this.$Message.loading({
            content: "正在提交数据...",
            duration: 0,
          });

          this.$post({
            url: "/gateway/api/manage/custompage/save",
            data: {
              configJson: JSON.stringify(this.currentFormData),
              enableStatus: this.currentFormData.enableStatus,
              icon: this.currentFormData.menuIcon,
              jumpJson: JSON.stringify(this.currentFormData.action),
              name: this.currentFormData.title,
              parentId: this.currentFormData.parentId,
              seq: this.currentFormData.seq,
              sysModuleId: this.currentFormData.sysModuleId,
              code: this.currentFormData.code,
              isMenu: this.currentFormData.isMenu,
              url: "",
              columnCode: this.currentFormData.columnCode,
              columnName: this.currentFormData.columnName,
              branchId: this.currentFormData.branchId,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Notice.config({ top: 20 });
                this.$Notice.success({
                  title: "保存成功，正在更新菜单列表",
                  duration: 3,
                });
                //更新列表
                this.getInitData(
                  { code: this.currentFormData.parentId },
                  this.updateData
                );

                if (!this.currentFormData.sysModuleId) {
                  this.currentFormData = {};
                  this.tabs1 = "menuSet";
                }
              } else {
                this.$Message.destroy();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
              this.$Message.error({
                background: true,
                content: "保存失败，请联系管理员处理",
              });
            });
        }
      });
    },
    checkModify(callback) {
      if (this.currentFormData && this.currentFormData.header) {
        this.$Modal.confirm({
          title: "重置编辑区提醒",
          content: "你有内容正在编辑中，是否放弃？",
          onOk: callback,
        });
        return;
      } else {
        callback();
      }
    },
    //增加节点
    append(data) {
      //append(root, node, data) {
      this.checkModify(() => {
        var idata = JSON.parse(JSON.stringify(this.initData));
        idata.parentId = data.code;
        this.edit(idata);
      });
    },
    edit(data) {
      this.showFNCUpload = false;
      this.currentFormData = data;
      if (data.barBackgroundColor) {
        this.barTextStyle = this.$core.ColorIsLight(data.barBackgroundColor)
          ? "#333333"
          : "#ffffff";
      }
      this.$nextTick(() => {
        this.showFNCUpload = true;
        this.bcTitle = [];
        this.getBCTitle(data.parentId, this.menuList[0]);
        this.tabs1 = "compSet";
        this.tabs2 = "vEditSet";
        this.tabs3 = "globalSet";
        this.$("#headerCompGroup").empty();
        this.$("#contentCompGroup").empty();
        this.selectedComponent = null;
        // sysModuleId 为本身的sysModuleId，copySysModuleId为所复制的sysModuleId  ==》加载被复制模块的组件
        if (data && (data.sysModuleId || data.copySysModuleId)) this.initComponent(data);
      });
    },
    getBCTitle(code, obj) {
      if (obj.code == code) {
        this.bcTitle.unshift(obj.title);
        this.getBCTitle(obj.parentId, this.menuList[0]);
      } else if (obj.children && obj.children.length > 0) {
        for (let k in obj.children) {
          this.getBCTitle(code, obj.children[k]);
        }
      }
    },
    select(arr, data) {
      this.checkModify(() => {
        if (data.sysModuleId && data.sysModuleId != "0") {
          this.$Message.loading({
            content: "正在加载数据...",
            duration: 0,
          });
          this.branchInfo=[];
          this.$get({
            url: "/gateway/api/manage/custompage/view",
            data: {
              id: data.sysModuleId,
            },
          })
            .then((res) => {
              this.$Message.destroy();
              if (res.code == 200 && res.data && res.data.sysModuleId) {
                this.bcTitle = [];
                this.getBCTitle(data.code, this.menuList[0]);
                this.bcTitle.pop();
                var idata = null;
                try {
                  idata = JSON.parse(res.data.configJson);
                } catch { }
                if (idata == null || idata.sysModuleId == null) {
                  idata = JSON.parse(JSON.stringify(this.initData));
                }
                idata.parentId = res.data.parentId;
                idata.sysModuleId = res.data.sysModuleId;
                idata.code = res.data.code;
                idata.title = res.data.name;
                idata.columnCode = res.data.columnCode;
                if (!idata.backgroundColor) idata.backgroundColor = "";
                if (!idata.barBackgroundColor) idata.barBackgroundColor = "";
                try {
                  jump = JSON.parse(res.data.jumpJson);
                  if (typeof jump == "object") {
                    idata.action = jump;
                  }
                } catch { }
                idata.seq = res.data.seq;
                idata.menuIcon = res.data.icon;
                idata.enableStatus = res.data.enableStatus;
                idata.isMenu = res.data.isMenu;
                if (!idata.isGuide) idata.isGuide = "0";
                idata.branchId = res.data.branchId;
                // console.log('idata',idata);
                this.edit(idata);
              } else {
                this.$Message.destroy();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              this.$Message.error({
                background: true,
                content: "获取数据失败，请联系管理员处理",
              });
            });
        }
      });
    },
    initComponent(data) {
      var dataList = [];
      if (
        data &&
        data.header &&
        data.header.display == true &&
        data.header.components &&
        data.header.components.length > 0
      ) {
        data.header.components.map((item) => {
          dataList.push({
            target: "#headerCompGroup",
            data: JSON.parse(JSON.stringify(item)),
          });
        });
      }
      if (
        data &&
        data.content &&
        data.content.display == true &&
        data.content.data &&
        data.content.data.length > 0
      ) {
        data.content.data.map((item) => {
          //  console.log("===",item.type);
          dataList.push({
            target: "#contentCompGroup",
            data: JSON.parse(JSON.stringify(item)),
          });
        });
      }
      this.setComponentData(dataList, 0);
    },
    setComponentData(data, i) {
      if (i < data.length) {
        var item = data[i].data;
        // console.log('item',item);
        // console.log(this.$refs[item.type]);
        var tar = this.$refs[item.type].$el.cloneNode(true);
        tar.id = item.type + "_Comp_" + this.compCount++;
        tar.className = tar.className + " component";
        this.$(data[i].target).append(tar);
        this.selectedData = item;
        this.selectedComponent = tar;
        this.$nextTick(() => {
          this.selectedComponent = null;
          this.selectedData = null;
          this.$nextTick(() => {
            this.setComponentData(data, i + 1);
          });
        });
      }
    },
    remove(root, node, data) {
      // console.log(node, data);
      this.$Modal.confirm({
        title: "温馨提示",
        loading: true,
        content: `<p>您正在删除<span style="color: red"> ${data.title} </span> ${data.children && data.children.length > 0
          ? "，其下所有的子页面将会被同时删除"
          : ""
          }，此操作不可逆，是否继续</p>`,
        onOk: () => {
          this.$post({
            url: "/gateway/api/manage/custompage/delete",
            data: {
              id: data.sysModuleId,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功",
                });
                this.$Modal.remove();
                //删除节点
                const parentKey = root.find((el) => el === node).parent;
                const parent = root.find((el) => el.nodeKey === parentKey).node;
                const index = parent.children.indexOf(data);
                parent.children.splice(index, 1);
                this.currentFormData = {};
              } else {
                this.$Modal.remove();
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              this.$Modal.remove();
              this.$Message.error({
                background: true,
                content: "删除失败，请联系管理员处理",
              });
            });
        },
      });
    },
    renderContent(h, { root, node, data }) {
      return h(
        "span",
        {
          style: {
            display: "inline-block",
            width: "100%",
          },
        },
        [
          h("span", data.title),
          h(
            "span",
            {
              style: {
                display: "inline-block",
                float: "right",
                marginRight: "0px",
              },
            },
            [
              h("Button", {
                props: Object.assign({}, this.buttonProps, {
                  icon: "ios-add",
                }),
                style: {
                  marginRight: "5px",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    this.append(data);
                  },
                },
              }),
              h("Button", {
                props: Object.assign({}, this.buttonProps, {
                  icon: "ios-remove",
                }),
                style: {
                  marginRight: "5px",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    this.remove(root, node, data);
                  },
                },
              }),
              h("Button", {
                props: Object.assign({}, this.buttonProps, {
                  icon: "ios-move",
                }),
                style: {
                  marginRight: "5px",
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    this.move(data);
                  },
                },
              }),
            ]
          ),
        ]
      );
    },
    getInitMoveData(item) {
      var that = this;
      this.getInitData(item, (list) => {
        item.loading = false;
        that.updateData(list, that.moveMenuList, item.code);
      });
    },
    getInitData(item, callback) {
      this.$get({
        url: "/gateway/api/manage/custompage/list",
        data: {
          parentId: item.code,
        },
      })
        .then((res) => {
          // console.log("getInitData=",res);
          if (res.code == 200) {
            this.$Message.destroy();
            if (!res.dataList) return;
            var list = res.dataList;
            list.map((item1) => {
              item1.title = item1.name;
              if (item1.hasChild > 0) {
                item1.expand = false;
                item1.loading = false;
                item1.children = [];
              }
            });
            if (item.code == "0") {
              this.menuList[0].children = list;
            } else {
              if (callback) callback(list);
            }
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          // console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    getPFData(item, callback) {
      console.log(item);
      this.$get({
        url: "/gateway/api/manage/type/list",
        data: {
          fatherid: item.typeid,
          functionId: this.$core.getUrlParam("functionid"),
        },
      })
        .then((res) => {
          // console.log("getInitData=",res);
          if (res) {
            this.$Message.destroy();
            var list = res;
            list.map((item1) => {
              item1.title = item1.typename + " [" + item1.typeid + "]";
              if (item1.hasChild > 0) {
                item1.expand = false;
                item1.loading = false;
                item1.children = [];
              }
              if (this.currentFormData && this.currentFormData.columnCode) {
                var column = this.currentFormData.columnCode.split(",");
                if (column.some((cm) => cm == item1.typeid)) {
                  item1.checked = true;
                } else {
                  item1.checked = false;
                }
              }
            });
            if (item.typeid == "0") {
              this.pfMenuList[0].children = list;
            } else {
              if (callback) callback(list);
            }
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          // console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
    pfSelectChange(arr, cur) {
      console.log(cur);
      if (cur && cur.typeid && cur.typeid != "0") {
        var columnCode = this.currentFormData.columnCode
          ? this.currentFormData.columnCode.split(",")
          : [];
        var columnName = this.currentFormData.columnName
          ? this.currentFormData.columnName.split("，")
          : [];
        var index = columnCode.findIndex((it) => it == cur.typeid);
        if (cur.checked == true) {
          if (index == -1) {
            columnCode.push(cur.typeid);
            columnName.push(cur.typename);
          }
        } else {
          if (index > -1) {
            columnCode.splice(index, 1);
            columnName.splice(index, 1);
          }
        }
        this.currentFormData.columnCode = columnCode.join(",");
        this.currentFormData.columnName = columnName.join("，");
      }
    },
    showSltPFModalFn() {
      this.pfMenuList[0].children = [];
      this.getPFData({ typeid: "0" });
      this.$refs.chooseModule.showModal();
    },
    setGuideConfig(e) {
      if (e == "0") {
        this.currentFormData.header.display = true;
        this.currentFormData.content.display = true;
      } else {
        this.currentFormData.action.type = "local";
        this.currentFormData.header.display = false;
        this.currentFormData.navbar.display = false;
        this.currentFormData.content.display = false;
        this.currentFormData.tabbar.display = false;
      }
    },
    clearColumn() {
      this.$Modal.confirm({
        title: "删除确认",
        content: "清除所有选中的后台功能，是否执行？",
        onOk: () => {
          this.currentFormData.columnName = "";
          this.currentFormData.columnCode = "";
        },
      });
    },
    getBranchInfo(query) {
        this.branchLoading = true;
        this.$get({
          url: "/gateway/api/manage/branch/list",
          data: {
            keyword: query,
            functionId: this.$core.getUrlParam("functionid"),
            limit:100,
          },
          success: res => {
            this.branchInfo = res.rows || [];
          },
          complete: () => {
            this.branchLoading = false;
          }
        })
    },
  },
  created() {
    this.domain = this.$getProxy("/custom");
    this.$Message.loading({
      content: "正在加载数据，请稍等...",
      duration: 0,
    });
    this.getInitData({ code: "0" });
  },
  mounted() {
    var that = this;
    this.$(document).on("click", ".component", function (event) {
      that.selectedComponent = null;
      that.selectedData = null;
      if (that.$(this).hasClass("focus")) {
        that.$(this).removeClass("focus");
        that.tabs3 = "globalSet";
      } else {
        that.$(".component").each((index, item) => {
          that.$(item).removeClass("focus");
        });
        that.$nextTick(() => {
          that.$(this).addClass("focus");
          that.tabs3 = "compoSet";
          that.selectedComponent = this;
          that.getCompData();
        })
      }
      event.stopPropagation();
    });
  },
};
</script>

<style lang="less" scoped>
.leftMenu {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 350px;
  bottom: 0;
  background: #fff;
  overflow: hidden;

  .leftMenuSet {
    height: calc(100vh - 130px);
    overflow: auto;
  }
}

.centerEdit {
  position: fixed;
  top: 60px;
  left: 370px;
  overflow: hidden;
  right: 550px;

  .centerEditSet {
    height: calc(100vh - 150px);
    overflow: hidden;
    background: url("/images/custompage_bg.jpg");
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .centerEditSet-zoom {
      background: #ffffff;
      width: 400px;
      height: calc(100% - 40px);
      overflow: auto;
      border-radius: 10px;
      transition: all 0.3s;
      box-shadow: 0px 0px 10px #999;
    }

    .centerEditSet-h-zoom {
      background: #ffffff;
      width: calc(100% - 40px);
      height: calc(100% * 0.65 - 40px);
      overflow: auto;
      border-radius: 10px;
      transition: all 0.3s;
      box-shadow: 0px 0px 10px #999;
    }

    ::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    ::-webkit-scrollbar-button:vertical {
      display: none;
    }

    ::-webkit-scrollbar-corner,
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-thumb:vertical:hover {
      background-color: rgba(0, 0, 0, 0.35);
    }

    ::-webkit-scrollbar-thumb:vertical:active {
      background-color: rgba(0, 0, 0, 0.38);
    }
  }
}

.rightEdit {
  position: fixed;
  top: 60px;
  width: 530px;
  right: 0;
  bottom: 0;
  overflow: auto;

  .rightMenuSet {
    height: calc(100vh - 130px);
    overflow: auto;
  }
}

/deep/.demo-tree-render .ivu-tree-title {
  min-width: 250px;
  width: calc(100% - 30px);
  padding-top: 10px;

  &>span {
    &>span:first-of-type {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 110px);
      display: inline-block;
    }
  }
}

/deep/.demo-tree-render .ivu-tree-arrow {
  padding: 10px;
}

/deep/.ivu-breadcrumb {
  background-color: #fff;
  line-height: 36px;
  padding: 0 20px;
  margin-bottom: 10px;
  position: relative;
}

.form {
  padding-right: 30px;
  height: calc(100vh - 106px);
  overflow: auto;
}

.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

.focus {
  border: 2px dotted #ff9900;
  box-shadow: 0px 0px 10px 2px #ff9900;
}

.FNC-header-index {
  overflow: hidden;

  &:hover {
    border: 2px dotted #ff9900;
  }

  .header-index-content {
    position: relative;
    width: 100%;
    z-index: 2;

    .title {
      font-size: 17px;
      // display: flex;
      width: calc(100% - 20px);
      padding: 0px 10px;
      line-height: 40px;
      height: 40px;
      color: #fff;
      white-space: nowrap;

      .space {
        margin-left: 6px;
      }
    }
  }

  .header-bgimg {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: auto;
  }

  .FNC-header-index-radius {
    margin-top: 5px;
    background: #fff;
    height: 12px;
    border-radius: 10px 10px 0 0;
  }

  .FNC-header-index-radius-immersion {
    position: relative;
    margin-top: -10px;
    background: #fff;
    height: 12px;
    border-radius: 10px 10px 0 0;
  }

  #headerCompGroup {
    .banner-swiper {
      width: 96%;
      margin-left: 2%;
      margin-bottom: 10px;
    }
  }
}

#componentsGroup {
  div:hover {
    box-shadow: 0px 0px 10px #808695;
  }

  .banner-swiper {
    height: 120px !important;
    border-radius: 5px;
    border: 2px solid #eee;
  }
}

.FNC-tabbar-index {
  position: fixed;
  bottom: 20px;
  left: calc((100% - 400px) / 2);
  right: calc((100% - 400px) / 2);
  height: 55px;
  background: url("/images/custompage-tabbar.jpg");
  background-size: 100% 100%;
  border-radius: 0 0 10px 10px;
  transition: all 0.2s;
  z-index: 999;

  &:hover {
    border: 2px dotted #ff9900;
  }
}</style>
