<template>
  <Layout class="invite-edit">
    <Sider hide-trigger width="400" class="invite-edit-sider">
      <div class="sider-header">
        <div>自定义页面</div><Button type="info" @click="edit()">创建页面</Button>
      </div>
      <List size="large" class="sider-list">

        <ListItem>
          <ListItemMeta title="基础信息" />
          <template #action>
            <li>
              <a href="#" @click="baseEdit">修改</a>
            </li>
          </template>
        </ListItem>
        <ListItem v-for="(item, index) in pageList" :key="'p' + index">
          <ListItemMeta :title="item.title" />
          <template #action>
            <li>
              <a href="#" @click="edit(item.id)">修改</a>
            </li>
            <li>
              <a href="#" @click="deletePage(item)">删除</a>
            </li>
          </template>
        </ListItem>

      </List>
    </Sider>
    <Content class="invite-edit-content">
      <Form ref="form" :model="formItems" :rules="ruleItems" :label-width="150">
        <div v-if="isBase">
          <FormItem label="主标题" prop="title">
            <Input type="text" v-model="formItems.title" placeholder="200字以内"></Input>
          </FormItem>
          <FormItem label="副标题" prop="subtitle">
            <Input type="text" v-model="formItems.subtitle" placeholder="200字以内"></Input>
          </FormItem>
          <FormItem label="联系人" prop="linkman">
            <Input type="text" v-model="formItems.linkman"></Input>
          </FormItem>
          <FormItem label="联系电话" prop="mobile">
            <Input type="text" v-model="formItems.mobile"></Input>
          </FormItem>
          <FormItem label="参与人数" prop="persons">
            <Input type="text" v-model="formItems.persons"></Input>
          </FormItem>
          <FormItem label="开始时间" prop="startTime">
            <DatePicker :value='formItems.startTime' @on-change="formItems.startTime = $event" type="datetime"
              format="yyyy-MM-dd HH:mm" placeholder="活动开始时间" style="width: 200px" />
          </FormItem>

          <FormItem label="结束时间" prop="endTime">
            <DatePicker :value='formItems.endTime' @on-change="formItems.endTime = $event" type="datetime"
              format="yyyy-MM-dd HH:mm" placeholder="活动结束时间" style="width: 200px" />
          </FormItem>
          <FormItem label="地址" prop="address">
            <Input type="text" v-model="formItems.address"></Input>
          </FormItem>
          <FormItem label="经纬度" prop="lng">
            <Input v-model="formItems.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
            <Input v-model="formItems.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
            <Button @click="mapFn">地图选点</Button>
          </FormItem>
          <FormItem label="图片封面" prop="poster">
            <FNCUpload type="image" style="float:left" :showView="true" :defaultList="formItems.poster" :showDel="true"
              @success="upLogoImage">
            </FNCUpload>
          </FormItem>
          <FormItem label="启禁用" prop="idisplay">
            <RadioGroup v-model="formItems.idisplay">
              <Radio :label="1">启用</Radio>
              <Radio :label="0">禁用</Radio>
            </RadioGroup>
          </FormItem>
        </div>
        <div v-else>
          <FormItem label="页面名称" prop="title">
            <Input type="text" v-model="formItems.title" placeholder="200字以内"></Input>
          </FormItem>
          <FormItem label="页面排序" prop="sort">
            <Input type="Number" style="width:100px;" v-model="formItems.sort" placeholder="从小到大"></Input>
            <span style="margin-left: 10px;">根据需要调整页面先后顺序</span>
          </FormItem>
          <FormItem label="页面类型" prop="idisplay">
            <RadioGroup v-model="formItems.type">
              <Radio :label="1">信息类</Radio>
              <Radio :label="2">图片类</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="图片列表" v-if="formItems.type == 2">
            <div style="text-align: right;">
              <Page :total="pictures.piclist.length" show-total :page-size="pictures.size" @on-change="handlerPage" />
            </div>
            <div class="invite-picture-list">
              <FNCUpload :multiple="true" :maxSize="5120" accept="image/*" type="file" @success="upPageImage" class="img" style="background-color: transparent;">
                <div style="width:100%;height: 100px;padding:20px 10px;display: flex;flex-direction: column;align-items: center;justify-content: center;background-color: #f9f9f9;">
                  <Icon type="md-add" size="60"/>
                  <p>点击上传</p>
              </div>
              </FNCUpload>
              <div class="img"
                v-for="item, index in pictures.piclist.slice((pictures.page - 1) * pictures.size, pictures.page * pictures.size)"
                :key="'P' + index">
                <Tooltip content="双击删除" placement="top">
                  <img :src="item" @dblclick="delectPic(index)">
                </Tooltip>
              </div>
            </div>
          </FormItem>
        </div>
        <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=内容" class="invite-edit-iframe"
          @load="setContent" v-if="isBase || formItems.type == 1"></iframe>
      </Form>
      <FNCModal ref="mapModal" class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度">
        <template v-slot:contentarea>
          <div
            style="width:400px;position: absolute;margin:10px;z-index:100;display:flex;background:#ffffff;padding:10px;border-radius:10px;">
            <Input v-model="keyword" style="width:100%;margin-right:5px;" placeholder="请输入地名"></Input><Button
              type="primary" @click="setPlace(keyword)">查询</Button>
          </div>
          <baidu-map :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter" :zoom="zoom"
            @ready="handler" :ak="$store.state.baiduAk" :clicking="true" @click="clickMap" :scroll-wheel-zoom="true">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
          </baidu-map>
        </template>
        <template #toolsbar>
          <Button type="info" style="margin-right: 10px" @click="classModal">取消</Button>
          <Button type="primary" @click="selectMap">确定</Button>
        </template>
      </FNCModal>
    </Content>
  </Layout>
</template>

<script>
import FNCUpload from '@/components/FNCUpload';
import FNCModal from "@/components/FNCModal"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"

export default {
  components: {
    FNCUpload, FNCModal, BaiduMap,
    BmMarker,
    BmView,
    BmNavigation,
  },
  props: {

  },

  data() {
    return {
      pictureList: [],
      formItemsDefault: {
        id: 0,
        poster: "",
        title: "",
        subtitle: "",
        info: '',
        idisplay: 1,
        linkman: "",
        mobile: "",
        persons: "",
        startTime: "",
        endTime: "",
        lng: "",
        lat: "",
        address: "",
      },
      formPageDefault: {
        id: 0,
        baseId: 0,
        type: 1,
        title: "",
        info: '',
        sort:99,
      },
      formItems: {},
      ruleItems: {

        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        info: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        lng: [
          // { required: true, message: '经纬度不能为空', trigger: 'blur' }
        ],
      },
      selectedLocation: {},
      pageCenter: {},
      keyword: "",
      zoom: 16,
      isBase: true,
      baseId: 0,
      pageList: [],
      titleName: "欢迎词",
      pictures: {
        page: 1,
        size: 23,
        piclist: []
      }
    };
  },
  mounted() {
  },
  methods: {
    loadData(id) {
      this.isBase = true;
      this.baseId = id;
      this.pageList = [];
      this.formItems = JSON.parse(JSON.stringify(this.formItemsDefault));
      if (id) {
        this.$get({
          url: "/yunyaoyue/api/custom/view",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              this.setContent()
              this.loadPageList(id);
            } else {
              this.$Message.error(res.desc || "读取数据出错");
              this.$emit("closeModal");
            }
          }
        });
      }
    },
    loadPageList(baseId) {
      this.pageList = [];
      if (baseId) {
        this.$get({
          url: "/yunyaoyue/api/custom/pagelist",
          data: { baseId: baseId },
          success: (res) => {
            if (res.code == "200" && res.dataList) {
              this.pageList = res.dataList;
            } else {
              this.$Message.error(res.desc || "读取数据出错");
            }
          }
        });
      }
    },
    loadPageData(id) {
      this.isBase = false;
      this.pictures.piclist=[];
      this.formItems = JSON.parse(JSON.stringify(this.formPageDefault));
      this.setContent()
      if (id) {
        this.$get({
          url: "/yunyaoyue/api/custom/pageview",
          data: { id: id },
          success: (res) => {
            if (res.code == "200" && res.data) {
              this.formItems = res.data;
              if(this.formItems.type==2&&this.formItems.info){
                try{
                this.pictures.piclist=JSON.parse(this.formItems.info)||[];
                this.formItems.info="";
                }catch(e){}
              }
            } else {
              this.$Message.error(res.desc || "读取数据出错");
            }
          },
          complete: () => {
            this.setContent()
          }
        });
      }
    },
    submitData(callback) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(this.isBase||(!this.isBase&&this.formItems.type==1)){
          var v_Obj = document.getElementById("ueditor").contentWindow;//获取iframe对象
          this.formItems.info = v_Obj.getContent();
          }
          //基础信息保存
          if (this.isBase) {
            if (!this.formItems.lng || !this.formItems.lat) {
              this.$Message.error({ background: true, content: "经纬度必填" })
              return;
            }

            callback(true)
            this.$post({
              url: "/yunyaoyue/api/custom/save",
              data: { ...this.formItems }
              , success: (res) => {
                if (res.code == "200") {
                  if (res.data) {
                    this.formItems.id = res.data
                    this.baseId = this.formItems.id;
                  }
                  callback(false, true);
                  this.$Message.success({ background: true, content: "保存成功" })
                } else {
                  this.$Message.error({ background: true, content: res.desc || "保存出错" })
                }
              }, complete: () => {
                callback(false)
              }
            });
          } else {
            if (!this.baseId) {
              this.$Message.error({ background: true, content: "基础信息不存在" })
              return;
            }
            this.formItems.baseId = this.baseId;
            if(this.formItems.type==2){
                  this.formItems.info = JSON.stringify(this.pictures.piclist);
              }
            
            //自定义页面保存
            callback(true)
            this.$post({
              url: "/yunyaoyue/api/custom/pagesave",
              data: { ...this.formItems }
              , success: (res) => {
                if (res.code == "200") {
                  if (res.data) {
                    this.formItems.id = res.data
                  }
                  this.$Message.success({ background: true, content: "保存成功" })
                  this.loadPageList(this.baseId);
                } else {
                  this.$Message.error({ background: true, content: res.desc || "保存出错" })
                }
              }, complete: () => {
                callback(false)
              }
            });
          }
        } else {
          this.$Message.error({ background: true, content: "部分必填项没填写" })
        }
      })
    },
    upLogoImage(file) {
      this.formItems.poster = file.url;
    },
    upPageImage(files){
      files.map(item=>{
        this.pictures.piclist.splice(0, 0,item.url);
      })
      
    },
    // 点击了地图
    clickMap(e) {
      this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
    },
    handler({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    makerCenter(point) {
      if (this.map) {
        this.selectedLocation = point;
        this.map.clearOverlays()
        this.map.addOverlay(new this.BMap.Marker(point))
      }
    },
    mapFn() {
      this.$refs.mapModal.showModal();
      if (this.formItems.lng) {
        this.keyword = "";
        this.pageCenter = { lng: this.formItems.lng, lat: this.formItems.lat }
        this.makerCenter(this.pageCenter)
      } else if (this.formItems.address) {
        this.keyword = this.formItems.address
        this.setPlace(this.formItems.address)
      }
    },
    classModal() {
      this.$refs.mapModal.hideModal();
    },
    selectMap() {
      this.formItems.lng = this.selectedLocation.lng
      this.formItems.lat = this.selectedLocation.lat
      this.$refs.mapModal.hideModal();
    },
    setPlace(myValue) {
      if (!myValue) return;
      var that = this
      var local = new this.BMap.LocalSearch(this.map, {
        onSearchComplete: () => {
          try {
            var pp = local.getResults().getPoi(0).point
            that.pageCenter = { lng: pp.lng, lat: pp.lat }
            that.makerCenter(pp)
          } catch (e) { }
        },
      })
      local.search(myValue)
    },
    edit(id) {
      if (!this.baseId) {
        this.$Message.error({ background: true, content: "基础信息未设置，请先设置基础信息" })
        return;
      }
      this.isBase = true;
      this.$nextTick(() => {
        this.titleName = "内容";
        this.isBase = false;
        this.loadPageData(id);
      })
    },
    baseEdit() {
      this.isBase = true;
      this.titleName = "欢迎词";
      this.loadData(this.baseId);
    },
    deletePage(row) {
      this.$Modal.confirm({
        title: '删除确认',
        content: row ? '您确认要删除“<span style="color:red"> ' + row.title + '</span>”吗？' : "确认要删除选中吗？",
        onOk: () => {
          this.$post({
            url: "/yunyaoyue/api/custom/pagedelete", data: { id: row.id },
            success: (res) => {
              if (res.code == "200") {
                this.$Message.success({ background: true, content: "删除成功" })
                this.loadPageList(this.baseId);
              } else {
                this.$Message.error({ background: true, content: res.desc })
              }
            }
          });
        }
      });
    },

    setContent() {
      var v_Obj = document.getElementById("ueditor").contentWindow;//获取iframe对象
      try {
        v_Obj.setContent(this.formItems.info);//写入编辑器富文本内容
        v_Obj.setTitleName(this.titleName);
      } catch (e) { }
    },
    handlerPage(e) {
      this.pictures.page = e;
    },
    delectPic(index) {
      this.pictures.piclist.splice(index, 1);
    },
  }
};
</script>
    
<style scoped lang='less'>
.invite-edit {
  margin: -10px;
  height: calc(100vh - 103px);

  .invite-edit-sider {
    background-color: #f5f5f5;
    overflow: hidden;

    .sider-header {
      width: 100%;
      height: 60px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      background-color: #f9f9f9;
      padding: 0 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .sider-list {
      padding: 0 10px;
      overflow: auto;
      height: calc(100% - 60px);
    }
  }

  .invite-edit-content {
    padding: 10px;
    background-color: #fff;

    .invite-edit-iframe {
      width: 100%;
      min-height: 500px;
      height: calc(100vh - 190px);
      border: 0px;
      border-radius: 10px;
    }

    .invite-picture-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .img {
        width: calc(12.5% - 16px);
        margin: 10px 8px;
        border-radius: 4px;
        max-height: 150px;
        overflow: hidden;
        background-color: #f9f9f9;
        img {
          width: calc(100% - 10px);
          margin:5px;
          height: auto;
          border-radius: 1px;
        }
      }
    }
  }
}</style>