<template>
  <FNCContent>
    <template v-slot:title> {{ title }} </template>
    <template v-slot:toolsbarLeft>
    </template>
    <template v-slot:toolsbarRight>
      <Button type="info" class="addDiv" @click="submitData" :loading="saveLoading">
        <Icon type="md-checkmark-circle" size="18" />保存
      </Button>
    
    </template>
    <template v-slot:contentArea>
          <Edit ref="editContent" :infoTypeList="infoTypeList" :infoType="infoType"></Edit>
    </template>
  </FNCContent>
</template>

<script>
//路由定义：@ROUTER=/infoview
import FNCContent from "@/components/FNCContent";
import Edit from "./childrens/Edit";
export default {
  components: {
    FNCContent,
    Edit,
  },

  data() {
    return {
      title: "信息详情",
      right: {},
      infoTypeList: [],
      id:0,
      saveLoading:false,
      infoType:"",
    };
  },
  created() {
    this.infoType=this.$core.getUrlParam("infoType");
  },
  mounted() {
    this.loadModuleInfo();
  },
  methods: {
    async loadModuleInfo() {
      //读取栏目信息
      await this.$get({
        url: "/gateway/api/manage/type/moduleinfo",
        data: {
          functionId: this.$core.getFunctionId(),
        },
        success: (res) => {
          if (res.code == "200" && res.data) {
            this.title = res.data.typename;
          }
        }
      });
      await this.$get({
        url: "/gateway/api/manage/appcenter/styleparam/list",
        data: { styleen: "infoType" },
        success: res => {
          this.infoTypeList = res;
        }
      });

      await this.$get({
        url: "/gateway/api/information/loadManageByFunctionId",
        success: res => {
          if(res.code=="200"&&res.data)
          {
            this.id=res.data.id || 0
          }
        }
      });

      this.$refs.editContent.loadData(this.id);

      this.$core.checkControlButtonRight([
        { btn: this.$(".addDiv"), right: "A" },
        ]
      ).then(res => {
        this.right = res;
      });
    },
    submitData() {
      this.$refs.editContent.submitData((b, reload) => {
        this.saveLoading = b;
        if (reload == true) {
          this.loadModuleInfo();
        }
      });
    }
  }
};
</script>
    
<style scoped lang='less'></style>