<template>
  <div class="fnc-website" v-if="width > 800">
    <div class="fnc-website-index" v-if="isMaim == 1">
      <div class="fnc-website-index-top">
        <img class="index-top-logo" :src="config.indexLogo">
      </div>
      <div class="fnc-website-index-link" v-if="menuList && menuList.length > 0">
        <block v-for="(item, index) in menuList" :key="'menu' + index">
          <span v-if="index > 0">|</span><span><a @click="goto(item.frontlink)">{{ item.typename }}</a></span>
        </block>
      </div>
      <div class="fnc-website-index-content">
        <Carousel :autoplay="true" :autoplay-speed="8000" dots="outside" :radius-dot="true" arrow="hover"
          style="height:100%;">
          <CarouselItem class="index-content-items" v-for="i in Math.ceil(labelList.length / 2)" :key="'items-' + i">
            <div class="index-content-item" v-for="(item, index) in labelList.slice((i - 1) * 2, i * 2)"
              :key="'items-' + i + '-' + index" @click="viewById(item.typeId, item.id)">
              <img :src="item.infoPicture || config.nopic">
              <div class="index-content-item-title">
                <div class="index-content-item-title-title">{{ item.infoTitle }}</div>
                <div class="index-content-item-title-content" style="padding:4px;">{{ item.publishtime || item.addtime }}
                </div>
                <div class="index-content-item-title-content">{{ convertHtml(item.content) }} </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
      <div class="fnc-website-index-bottom">
        {{ config.branchaddress }}&nbsp; &nbsp;电话：{{ config.branchleadermobile }}
        &nbsp; &nbsp;<a href="https://beian.miit.gov.cn" target="_blank">CopyRight©{{ $core.formatDate(new Date(), 'yyyy')
        }} {{ config.icp }} </a>
      </div>
    </div>
    <div class="fnc-website-page" v-else-if="isMaim == 2">
      <div class="fnc-website-page-top">
        <img class="page-top-logo" :src="config.pageLogo" v-if="config.pageLogo">
        <div class="page-top-logo-name" v-else>{{ config.branchname }}</div>
        <img class="page-top-purpose" :src="config.pagePurpose" v-if="config.pagePurpose">
      </div>
      <div class="fnc-website-page-link">
        <span><a @click="goto('/')">首页 </a></span>
        <block v-for="(item, index) in menuList" :key="'menu2' + index">
          <span>|</span><span><a @click="goto(item.frontlink)" :class="currId == item.typeid ? 'page-link-currId' : ''">{{
            item.typename }}</a></span>
        </block>
      </div>
      <div class="fnc-website-page-content">
        <div class="page-content-left-menu" v-if="subMenuList && subMenuList.length > 0">
          <div @click="goto(item.frontlink)"
            :class="'page-content-left-menu-item' + (functionId == item.typeid ? ' page-content-left-menu-item-active' : '')"
            v-for="(item, index) in subMenuList" :key="'sub-' + index">{{ item.typename }}</div>
        </div>
        <div class="page-content-content"><router-view :key="$route.fullPath" v-if="loaded" /></div>
      </div>
      <div class="fnc-website-page-bottom">
        {{ config.companyname }}&nbsp; &nbsp; {{ config.branchaddress }}&nbsp; &nbsp;电话：{{ config.branchleadermobile }}
        &nbsp; &nbsp;<a href="https://beian.miit.gov.cn" target="_blank">CopyRight©{{ $core.formatDate(new Date(), 'yyyy')
        }} {{ config.icp }} </a>
      </div>
    </div>
    <div class="fnc-website-loadding" v-else>
      正在加载中...
    </div>
  </div>
  <div class="fnc-website-m" v-else>
    <Icon type="md-apps" class="fnc-website-m-menu" @click="drawerValue = true" />
    <Drawer :closable="false" width="50" :scrollable="true" v-model="drawerValue" :transfer="false">
      <div class="fnc-website-m-link">
        <div :class="'m-link-row' + (currId.indexOf('00') == 0 ? ' page-link-currId' : '')" @click="goto('/')">首页 </div>
        <div v-for="(item, index) in menuList" :key="'menu' + index"
          :class="'m-link-row' + (currId == item.typeid ? ' page-link-currId' : '')" @click="goto(item.frontlink)">{{
            item.typename }}</div>
      </div>
    </Drawer>

    <div class="fnc-website-index" v-if="isMaim == 1">
      <div class="fnc-website-index-top">
        <img class="index-top-logo" :src="config.indexLogo">
      </div>
      <div class="fnc-website-index-content">
        <Carousel :autoplay="true" :autoplay-speed="8000" dots="outside" :radius-dot="true" arrow="always"
          style="height:100%;">
          <CarouselItem class="index-content-items" v-for="(item, index) in  labelList" :key="'items-m-' + index">
            <div class="index-content-item" @click="viewById(item.typeId, item.id)">
              <div class="pictitle"> <img :src="item.infoPicture || config.nopic"></div>
              <div class="index-content-item-title">
                <div class="index-content-item-title-title">{{ item.infoTitle }}</div>
                <div class="index-content-item-title-content" style="padding:4px;">{{ item.publishtime || item.addtime }}
                </div>
                <div class="index-content-item-title-content">{{ convertHtml(item.content) }} </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
      <div class="fnc-website-index-bottom">
        <p>{{ config.branchaddress }}</p>
        <p>电话：{{ config.branchleadermobile }}
        </p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">CopyRight©{{ $core.formatDate(new Date(), 'yyyy')
        }} {{ config.icp }} </a></p>
      </div>
    </div>
    <div class="fnc-website-page" v-else-if="isMaim == 2">
      <div class="fnc-website-page-top">
        <img class="page-top-logo" :src="config.pageLogo" v-if="config.pageLogo">
        <div class="page-top-logo-name" v-else>{{ config.branchname }}</div>
      </div>
      <div class="fnc-website-page-top-sp"></div>
      <div class="fnc-website-page-shadow"></div>
      <div class="fnc-website-page-link" v-if="subMenuList && subMenuList.length > 0">
        <block v-for="(item, index) in subMenuList" :key="'subMenuList' + index">
          <span v-if="index > 0">|</span><span :class="functionId == item.typeid ? 'page-link-currId' : ''" @click="goto(item.frontlink)">{{ item.typename }}</span>
        </block>
      </div>
      <div class="fnc-website-page-link-sp" v-if="subMenuList && subMenuList.length > 0"></div>
      <div class="fnc-website-page-content">
        <div class="page-content-content"><router-view :key="$route.fullPath" v-if="loaded" /></div>
      </div>
      <div class="fnc-website-page-bottom">
        <p>{{ config.companyname }} </p>
        <p>{{ config.branchaddress }}</p>
        <p>电话：{{ config.branchleadermobile }}
        </p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">CopyRight©{{ $core.formatDate(new Date(), 'yyyy')
        }} {{ config.icp }} </a></p>
      </div>
    </div>
    <div class="fnc-website-loadding" v-else>
      正在加载中...
    </div>
  </div>
</template>
<script>
import "./index.less"
export default {
  data() {
    return {
      width: 0,
      isMaim: 0,
      config: {},
      menuList: [],
      subMenuList: [],
      labelList: [],
      functionId: "",
      currId: "",
      loaded: false,
      drawerValue: false,
    };
  },
  async created() {
    this.width = $(window).width();
    $(window).resize(() => {
      this.width = $(window).width();
    })
    // await this.loadOEMInfo();
    await this.loadWebsiteConfig();
    await this.loadMenuList();
    await this.loadLabelInfo();
    this.checkPath(this.$route);
    this.loaded = true;
  },
  mounted() {

  },
  watch: {
    $route: {
      handler: function (val) {
        this.checkPath(val);
      },
      deep: true
    }

  },
  methods: {
    checkPath(val) {
      this.functionId = this.$core.getUrlParam("functionId") || "";
      if (!val.path || val.path == "/") {
        this.isMaim = 1;
        this.loadLabelInfo();
      } else {
        this.isMaim = 2;
      }
      if (this.config.menufatherid) {
        this.currId = (this.functionId + "0000000000000000000000").substring(0, this.config.menufatherid.length + 2);
        this.subMenuList = (this.menuList.find(item => item.typeid == this.currId) || {}).subMenus || [];
      }
    },
    goto(url) {
      if (url) {
        this.$router.push(url)
      }
      this.drawerValue = false;
    },

    async loadWebsiteConfig() {
      await this.$get({
        url: "/gateway/oauth/loadWebsiteConfig",
        success: (res) => {
          if (res.code == "200") {
            this.config = res.data
            this.config.id = this.config.branchId;
            window.sessionStorage.setItem("currentSystem", JSON.stringify(this.config))
            this.$("title").text(this.config.branchname);
            this.$el.style.setProperty("--mainColor", this.config.mainColor || "#950101");
            this.$el.style.setProperty("--secondColor", this.config.secondColor || "#ae0026")
          }
        }
      })
    },
    async loadMenuList() {
      if (this.config && this.config.mainMenuId) {
        await this.$get({
          url: "/gateway/api/manage/appcenter/loadfrontmenu/" + this.config.mainMenuId,
          data: { branchId: this.config.branchId },
          success: (res) => {
            if (res.code == '200' && res.dataList)
              this.menuList = res.dataList;
          }
        })
      }
    },
    async loadLabelInfo() {
      if (this.config && this.config.branchId) {
        await this.$get({
          url: "/gateway/api/information/loadListByLabel",
          data: { lable: "热点" },
          success: (res) => {
            if (res.code == '200' && res.dataList)
              this.labelList = res.dataList;
          }
        })
      }
    },
    convertHtml(html) {
      html = html.replace(/<[^>]+>/g,"");
      return this.$("<div>" + html + "</div>").text();
    },
    viewById(functionId, id) {
      if (id) {
        var url = "/information/view?functionId=" + functionId + "&id=" + id
        this.goto(url)
      }
    },
    async loadOEMInfo() {
      //获取OEM的配置信息接口
      await this.$get({
        url: "/gateway/oauth/loadPortalConfig", success:
          res => {
            if (res && res.code == 200 && res.data) {
              window.localStorage.setItem("portalCode", res.data)
              this.oemInfo = this.$core.getPortalConfig()
              // console.log(this.oemInfo);
              if (this.oemInfo && this.oemInfo.id == 0) {
                this.$Message.error({
                  background: true,
                  content: "没有读取到门户信息，请重试。",
                  duration: 3,
                })
                return;
              }
              this.$("title").html(this.oemInfo.systemName)
            } else {
              this.$Message.error({
                background: true,
                content: "没有读取到门户信息，请重试。",
                duration: 3,
              })
            }
          }, fail: err => {
            window.localStorage.removeItem("portalCode")
            this.$Message.error({
              background: true,
              content: "读取门户信息时出错。",
              duration: 3,
            })
          }
      })
    },
  },
};
</script>